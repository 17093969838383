Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.btnExampleTitle = "CLICK ME";
exports.exampleAPiPUTMethod = "PUT";
exports.btnTitle = "CLICK THE LINK";
exports.languageListForecasting = {
  English: {
    Settings: "Settings",
    Currency: "Currency",
    EstatePlanningName:'Estate Planning Preferances',
    SelectedLanguage: "Selected Language:",
    SelectedCurrency: "Selected Currency:",
    Language: "Language",
    bankLogins: "Bank & Wallet Logins",
    BankName: "Bank Name",
    PublicAddress: "Public Address",
    AccountNumber: "Account Number:",
    Connectedon: "Connected on:",
    LastSynced: "Last Synced:",
    successfully: "Bank refreshed successfully!",
    Something: "Something went wrong!",
    AddNewBank: "Add New Bank",
    Remove: "Remove",
    successfullyDeleted: "Bank deleted successfully!",
    walletSuccessfullyDeleted:"Wallet deleted successfully!",
    Resync: "Resync",
    AccountsLinked: "Total Accounts Linked:",
    English: "English",
    Arabic: "الإنجليزية",
    AccountDetails: "Account Details",
    FullName: "Full Name:",
    FullNameplaceholder: "Full Name",
    Email: "Email:",
    Mobile: "Mobile:",
    Contact: "Contact",
    DateofBirth: "Date of Birth:",
    DateofBirthplaceholder: "Date of Birth",
    Address: "Address:",
    Addressplaceholder: "Address:",
    ResetPassword: "Reset Password",
    DeleteAccount: "Delete Account",
    changesMesseg: "You have unsaved changes!",
    Discard: "Discard",
    Save: "Save",
    Editdetails: "Edit details",
    Confirmdetails: "Confirm details",
    toastsuccessfully: "Profile updated successfully!",
    Profiledeleted: "Profile deleted!",
    WarningP: "**Warning this will delete all your data regarding your profile and all your personal and financial assets.**",
    irreversible: "I understand that this action is permanent and irreversible.",
    ConfirmDeletion: "Confirm Deletion",
    ForgotPassword: "Forgot Password",
    ConfirmPassword: "Confirm Password",
    CurrentPasswordplaceholder: "Current Password",
    NewPasswordplaceholder: "New Password",
    ConfirmNewPasswordplaceholder: "Confirm New Password",
    Passwordsuccessfully: "Password has been successfully changed.",
    Quote:'Edit questionnaire',
    EditAllow:'Allow Myne to share networth data to',
    EditQuot:'Edit questionnaire',
    EditAns:'Edit estate planning questionnaire answers',
    EditDescription:'Allow data sharing to wealth planners you have currently worked with and working with. This allow the platform to share information about your networth and asset distribution to your matched wealth planners for your estate planning services.',
    },
  Arabic: {
    Passwordsuccessfully: "كلمة المرور هانز: لم يتم تغييرها بنجاح.",
    ConfirmNewPasswordplaceholder: "تأكيد كلمة المرور الجديدة",
    NewPasswordplaceholder: "كلمة المرور الجديدة",
    CurrentPasswordplaceholder: "كلمة السر الحالية",
    EditAns:'تحرير إجابات استبيان التخطيط العقاري',
    EditQuot:'تحرير الاستبيان',
    ConfirmPassword: "تأكيد كلمة المرور",
    Quote:'تحرير الاستبيان',
    ForgotPassword: "نسيت كلمة المرور ؟",
    EstatePlanningName:'تفضيلات التخطيط العقاري',
    ConfirmDeletion: "تأكيد الحذف",
    EditAllow:'اسمح لـ Myne بمشاركة بيانات networth مع',
    EditDescription:'السماح بمشاركة البيانات مع مخططي الثروة الذين عملت معهم حاليًا. يتيح ذلك للمنصة مشاركة المعلومات حول صافي ثروتك وتوزيع الأصول إلى مخططي الثروات المطابقين لخدمات التخطيط العقاري الخاصة بك.',
    irreversible: "أدرك أن هذا الإجراء دائم ونهائي",
    WarningP: "**تحذير: يؤدي هذا إلى حذف جميع بياناتك المتعلقة بملفك الشخصي وجميع أصولك الشخصية والمالية.**",
    Profiledeleted: "تم حذف الملف الشخصي!",
    toastsuccessfully: "تم تحديث الملف الشخصي بشكل جيد!",
    Confirmdetails: "تأكيد التفاصيل",
    Editdetails: "تعديل التفاصيل",
    Save: "إلغاء",
    Discard: "تسجيل الخروج",
    changesMesseg: "لديك تغييرات غير محفوظة!",
    DeleteAccount: "حذف الحساب",
    ResetPassword: "إعادة تعيين كلمة المرور",
    Addressplaceholder: "العنوان",
    Address: "العنوان:",
    DateofBirthplaceholder: "تاريخ الميلاد",
    DateofBirth: "تاريخ الميلاد:",
    Contact: "اتصال",
    Mobile: "رقم التليفون:",
    Email: "البريد الإلكتروني:",
    FullNameplaceholder: "بالاسم الكامل",
    FullName: "بالاسم الكامل:",
    AccountDetails: "تفاصيل الحساب",
    Arabic: "الإنجليزية",
    English: "English",
    AccountsLinked: "إجمالي الحسابات لينكدإن:",
    Resync:"إعادة المزامنة",
    successfullyDeleted: "تم حذف البنك بنجاح!",
    walletSuccessfullyDeleted:"تم حذف المحفظة بنجاح!",
    Remove: "إزالة",
    AddNewBank: "إضافة بنك جديد",
    Something: "هناك خطأ ما!",
    successfully: "تم تحديث البنك سوسسسسفول!",
    LastSynced:"آخر مزامنة",
    Connectedon: "متصل على:",
    AccountNumber: "رقم حساب:",
    PublicAddress: "العنوان العام",
    BankName: "باسم البنك",
    SelectedLanguage: ":اللغة المختارة",
    bankLogins: "تسجيلات الدخول للبنك والمحفظة",
    Language: "اللغة",
    SelectedCurrency: ":العملة المختارة",
    Currency: "العملة",
    Settings: "الإعدادات"
  }
}
// Customizable Area End 