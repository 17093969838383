import React, { Fragment } from "react";

// Customizable Area Start
import { NavLink } from "react-router-dom";
import { subarrowicon, settingicon } from "../../dashboard/src/assets";
import "../../dashboard/src/style.css";
import "./style.css";
import { backIcon } from "../../forgot-password/src/assets";
import { image_BackIcon } from "./assets";

// Customizable Area End

import VisualAnalyticsController, {
    Props,
} from "./VisualAnalyticsController";

export default class VisualAnalytics extends VisualAnalyticsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.allTransactions();
        // this.categories();
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div className="pagedashboard-wrapper" dir={this.state.logoImg1AddCash}>
                <div className="header-wrapper" style={{marginTop:'30px'}}>
                    <div className="page-title">
                        <h4 style={{display:'flex', alignItems:'center'}}><div onClick={()=>this.gotoTransactions()}><img src={this.state.logoImg1AddCash === "rtl" ? image_BackIcon : backIcon} alt="back-icon" /></div> &nbsp; {this.getStringVisualAnalytics("Filter")}</h4>
                    </div>
                    <div className="page-info" data-test-id="page-info-filter">
                        <ul className="drop-menu" id="drop-menu-filter">
                            <li className="currency-dropdown">
                                <div
                                    data-test-id="currencyDropdoen"
                                    onClick={() => this.handleDropdown()}
                                    className={this.state.dropdownVisiblity} >
                                    <p id="fakedatatestid">
                                        {this.state.selectedCurrency} <img src={subarrowicon} alt="subarrow" />
                                    </p>
                                    <ul>
                                        {this.state.currencyList && this.state.currencyList.list.map((value: any, index: number) => {
                                            return <li  style={{textAlign:this.state.logoImg1AddCash ==="rtl" ? "right": "left"}}data-test-id={"currency-" + index} onClick={() => this.selectCurrency(value)} key={index}>{value.symbol + value.currency_type}</li>
                                        })}

                                    </ul>
                                </div>
                            </li>
                            <li className="nav-link-setting" id="filter-nav">
                                <NavLink className="fake-class" data-test-id="filter-account-redirect" to="/Account-Detail">
                                    <img src={settingicon} alt="setting" id="setting-img" />
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="content-wrapper" id="filter-main-content">
                    <div className="screen-wrapper-transaction">
                        <div className="screen-flex-transaction">
                            <h4 className="filter-title">{this.getStringVisualAnalytics("Type")}</h4>
                            {this.state.typesList && this.state.typesList.map((value: any, index: number) => {
                                return (
                                    <Fragment key={index}>
                                        <label className="checkbox">
                                            <input disabled={!!this.state.start_date} type="checkbox" name="color" data-test-id={`type-` + index} onClick={() => this.filterCheck("type", value)}
                                                defaultChecked={this.state.TypeFilter?.includes(value)} />
                                            <span className={`${!!this.state.start_date && 'disabled-checkmark'} checkmark`} ></span>
                                        </label>  <span className={`${!!this.state.start_date && 'disable-data'} filter-data`}>{value}</span><br />
                                    </Fragment>
                                )
                            })}
                            <hr className="new6" />

                            <h4 className="filter-title">{this.getStringVisualAnalytics("Date")}</h4>
                            {this.state.datesList && this.state.datesList.map((value: any, index: number) => {
                                return (
                                    <>
                                        <label className="checkbox">
                                            <input type="checkbox" data-test-id={`date-` + index} name="color" onClick={() => this.filterCheck("date", value)}
                                                defaultChecked={this.state.DateFilter?.includes(value)} />
                                            <span className="checkmark" ></span>
                                        </label>  <span className="filter-data">{value}</span><br />
                                    </>
                                )
                            })}
                          
                            <hr className="new6" />

                            <h4 className="filter-title">{this.getStringVisualAnalytics("Category")}</h4>
                            {this.state.categoriesList && this.state.categoriesList.map((value: any, index: number) => {
                                return (
                                    <>
                                        <label className="checkbox">
                                            <input disabled={!!this.state.start_date} type="checkbox" name="color" data-test-id={`category-` + index} onClick={() => this.filterCheck("category", value.attributes.id)}
                                                defaultChecked={this.state.CategoryFilter?.includes(value.attributes.id)} />
                                            <span className={`${!!this.state.start_date && 'disabled-checkmark'} checkmark`} ></span>
                                        </label>  <span className={`${!!this.state.start_date && 'disable-data'} filter-data`}>{value.attributes.name}</span><br />
                                    </>
                                )
                            })}


                        </div>
                    </div>

                </div><br /><br />
                <div className="filter-btns">
                    <div data-test-id="go-to-transactions" onClick={()=>this.gotoTransactions()}><span className="cancel-btn-filter-data">{this.getStringVisualAnalytics("Cancel")}</span></div>

                    <span className="save-btn-filter-data" data-test-id="save-btn-filter" onClick={() => this.saveFilter()}>{this.getStringVisualAnalytics("Save")}</span>
                </div>
              
            </div>
            
            // Customizable Area End
        );
    }
}

// Customizable Area Start

// Customizable Area End
