// Customizable Area Start

import React, { CSSProperties } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Container,
  Box,
  Button,
  InputLabel,
  Typography,
  IconButton,
  Grid,
  Modal,
  MenuItem,
  Menu,
  FormControl,
  Select,
  TextField
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { addFolder, willDocuments, document_file, threeDotss } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 600,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import DocumentationController, {
  Props,
  GalleryMedias,
  documentMedias,
  FolderInterface,
  FolderItemsData
} from "./DocumentationController";

export default class Documentation extends DocumentationController {
  constructor(props: Props) {
    super(props);

  }

  renameFileModal = () => {
    return (
      <Modal
        open={!!this.state.renameModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        data-test-id="renameModalDTIIO"
      >

        <>
          <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw" }}>

            <Box style={{
              height: "52%",
              padding: "35px",
              justifyContent: "center",
              color: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              alignItems: 'center',
              width: "35%",
              background: "#3D3751",
              borderRadius: "12px"

            }}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                {this.getStringDocument('RenameFolder')}
              </Typography>
              <TextField
                data-test-id="rename-field"
                value={this.state.renameValue}
                onChange={this.handleRename}
                variant="outlined"
                style={{ width: "70%", borderRadius: "5px", borderColor: 'red' }}
                id="outlined-basic"
                label={this.getStringDocument('GiveTitle')}
              />
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: '88%',
                  marginTop: '1.9rem'
                }}
              >
                <Button
                  data-test-id="rename-cancel-button"
                  onClick={() => this.handleRenameModal(null)}
                  style={{
                    borderRadius: "8px",
                    color: "red",
                    border: "1px solid red",
                    width: "30%",
                    margin: "5px",
                    fontWeight: 600,
                    textTransform: 'unset'
                  }}
                >{this.getStringDocument('Cancel')}</Button>
                <Button
                  data-test-id="rename-button"
                  disabled={this.state.renameValue ? false : true}
                  onClick={() => this.renameFileAndFolder(this.state.fileMenu)}
                  style={{
                    color: "C9F9F6",
                    border: "1px solid #C9F9F6",
                    borderRadius: "8px",
                    width: "30%",
                    margin: "5px",
                    fontWeight: 600,
                    textTransform: 'unset'
                  }}
                >{this.getStringDocument('Save')}</Button>

              </Box>
            </Box>
          </Box>
        </>

      </Modal>
    )
  }

  deleteModal = () => {
    return (
      <Modal
        open={!!this.state.deleteModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        data-test-id="DeleteModalDTI"
      >

        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw" }}>

          <Box style={{
            height: "57%",
            width: "38%",
            color: "#FFFFFF",
            background: "#3D3751",
            padding: "35px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: 'center',
            borderRadius: "12px"

          }}>
            {this.state.fileMenu?.file === 'folder'
              ?
              <Typography variant="h6" style={{ width: '80%' }}>
                {this.getStringDocument('FolderDeleteWarning')}
              </Typography>
              :
              <Typography variant="h6" style={{ width: '80%', textAlign: 'center' }}>
                {this.getStringDocument('FileDeleteWarning')}
              </Typography>
            }

            <Box
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: '92%',
                marginTop: '1.9rem'
              }}
            >
              <Button
                data-test-id="menu-delete-button"
                onClick={() => this.deleteFolderAndFiles(this.state.fileMenu)}
                style={{
                  color: "red",
                  borderRadius: "8px",
                  border: "1px solid red",
                  width: "38%",
                  margin: "5px",
                  fontWeight: 600,
                  textTransform: 'unset'
                }}
              >{this.getStringDocument('Delete')}</Button>
              <Button
                data-test-id="close-delete-modal-button"
                onClick={() => this.handleDeleteModal(null)}
                style={{
                  color: "C9F9F6",
                  borderRadius: "8px",
                  border: "1px solid #C9F9F6",
                  width: "38%",
                  margin: "5px",
                  fontWeight: 600,
                  textTransform: 'unset'
                }}
              >{this.getStringDocument('Cancel')}</Button>


            </Box>
          </Box>
        </Box>

      </Modal >
    )
  }

  addFolderModal = () => {
    return (
      <Modal
        open={this.state.addFolderModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <>

          <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw" }}>

            <Box style={{
              height: "58%",
              width: "32%",
              color: "#FFFFFF",
              background: "#3D3751",
              padding: "35px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: 'center',
              borderRadius: "12px"
            }}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                {this.getStringDocument('GiveFolderName')}
              </Typography>

              <TextField
                data-test-id="folder-name-field"
                onChange={this.handleFolderName}
                value={this.state.folderName}
                variant="outlined"
                style={{ width: "90%", borderRadius: "5px" }}
                id="outlined-basic"
                label={this.getStringDocument('GiveTitle')}
              />

              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: '100%',
                  marginTop: '1.9rem'
                }}
              >
                <Button
                  data-test-id="close-add-folder-modal-button"
                  onClick={() => this.handleAddFolderModal(false)}
                  style={{
                    borderRadius: "8px",
                    border: "1px solid red",
                    width: "38%",
                    margin: "5px",
                    fontWeight: 600,
                    color: "red",
                    textTransform: 'unset'
                  }}
                >{this.getStringDocument('Cancel')}</Button>
                <Button
                  data-test-id="add-folder-modal-button"
                  disabled={this.state.folderName ? false : true}
                  onClick={this.createFolderOpen}
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #C9F9F6",
                    width: "38%",
                    margin: "5px",
                    fontWeight: 600,
                    color: "C9F9F6",
                    textTransform: 'unset'
                  }}
                >{this.getStringDocument('Save')}</Button>

              </Box>
            </Box>
          </Box>
        </>
      </Modal>
    )
  }

  selectFolderModal = () => {
    return (
      <Modal
        open={this.state.selectFolderModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        data-test-id="selectFolderDTI"
      >
        <>

          <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw" }}>

            <Box style={{
              height: "37%",
              width: "32%",
              color: "#FFFFFF",
              background: "#3D3751",
              padding: "35px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: 'center',
              borderRadius: "12px"

            }}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                {this.getStringDocument('AddToFolder')}
              </Typography>

              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">{this.getStringDocument('Folders')}</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  data-test-id="demo-simple-select-outlined"
                  value={this.state.selectFolderName}
                  onChange={(event) => this.handleSelectFolder(event)}
                  label={this.getStringDocument('Folders')}
                  MenuProps={{
                    style:{
                      marginTop:'50px'
                    }
                  }}
                >
                  {this.state.allFolders && this.state.allFolders.length > 0 && this.state.allFolders.map((allData: FolderItemsData) => {
                    return (
                      <MenuItem key={allData.id} value={allData.id}>{allData.name}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: '100%',
                  marginTop: '1.9rem'
                }}
              >
                <Button
                  data-test-id="close-select-folder-modal-button"
                  onClick={() => this.handleSelectFolderModal(false)}
                  style={{
                    borderRadius: "8px",
                    width: "38%",
                    margin: "5px",
                    height: '80%',
                    fontWeight: 600,
                    color: "red",
                    border: "1px solid red",
                    textTransform: 'unset'
                  }}
                >{this.getStringDocument('Cancel')}</Button>
                <Button
                  data-test-id="folder-button"
                  onClick={() => this.moveFile()}
                  disabled={this.state.selectFolderName ? false : true}
                  style={{
                    color: "C9F9F6",
                    border: "1px solid #C9F9F6",
                    borderRadius: "8px",
                    width: "36%",
                    height: '79%',
                    margin: "5px",
                    fontWeight: 600,
                    textTransform: 'unset'
                  }}
                >{this.getStringDocument('AddToFolder')}</Button>
              </Box>
            </Box>
          </Box>
        </>
      </Modal>
    )
  }



  renderLoaderWeb() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress style={{ color: "#0000FF" }} />
      </div>
    );
  }

  render() {

    return (
      <ThemeProvider theme={theme}>
        <style>
          {`
        .MuiSelect-select.MuiSelect-select {
          width: 26rem !important;
          display: flex;
          align-items: center;
          outlined: white;
          color: black;
        }
        .MuiOutlinedInput-input {
          padding: 25.5px 14px !important
        }
        input[type="text"] {
          border-bottom: none
        }
        .MuiPaper-root {
          width: 155px;
          border-radius: 10px;
        }
        .MuiList-root {
          display: flex;
          flex-direction: column;
          align-items: end;
        }
        .MuiMenuItem-root {
          color: #3c3c51;
          width: 100%
        }
        .MuiButton-root.Mui-disabled {
          opacity: 0.5;
        }
        // .MuiFormControl-root {
        //   width: 90
        // }
        .MuiOutlinedInput-notchedOutline{
          border-color: #999aa5 !important;
        }
        .MuiOutlinedInput-notchedOutline:hover {
          border-color: #999aa5 !important;
        }
        .MuiInputBase-root:hover{
          border-color: #999aa5 !important;
        }
      `}
        </style>
        <div dir={this.state.direction}>
          <Container style={{
            paddingTop: '2rem',
            height: '100vh',
            overflowY: 'auto',
            width: '100%'
          }} maxWidth={"xl"}>
            <Typography variant="h6" style={{
              color: '#fff',
              opacity: '0.5',
              padding: '0 17px'
            }}>{this.getStringDocument('SelectFolderAddDocument')}</Typography>
            <div style={{ display: 'flex', width: '75vw', flexWrap: 'wrap'}}>
              <div style={{ display: 'flex', flexDirection: 'column',cursor:'pointer' }}>
                <img data-test-id='add-folder-button' onClick={() => this.handleAddFolderModal(true)} style={{ width: '200px', height: '200px' }} src={addFolder} alt="ad" />
                <label style={{
                  color: '#FFF',
                  fontWeight: 600,
                  width: '100%',
                  textAlign: 'center'
                }}>{this.getStringDocument('CreateFolder')}</label>
              </div>
              {this.state.allFolders && this.state.allFolders.length > 0 && this.state.allFolders.map((newData: FolderItemsData) => {
                return (
                  <div style={{ position: 'relative', display: 'flex', flexDirection: 'column' }} >
                    <img data-test-id="ImgNavigation" onClick={() => newData.id && this.handleNavigation(newData.id)} style={{ width: '200px', height: '200px' ,cursor:'pointer'}} src={willDocuments} alt="will_documents" />
                    <IconButton
                      data-test-id="menu-options-button-1"
                      onClick={(event) => this.handleMenuModal({
                        folder_id: newData.id,
                        file: 'folder',
                      }, event)}
                      aria-haspopup="true"
                      type="button"
                      style={{ position: 'absolute', left: '9.4rem', top: '10.8px' }}
                    >
                      <img src={threeDotss} alt="threeDotsMenu" height={"25px"} />
                    </IconButton>
                    {this.state.fileMenu?.folder_id === newData.id &&
                      <Menu
                        id="menu-modal"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleDocumentMenuModalClose}
                        style={{
                          left: '1.3rem',
                          top: '2rem',
                        }}
                      >
                        <MenuItem data-test-id="rename-folder" onClick={() => this.handleRenameModal(this.state.fileMenu)}> {this.getStringDocument('Rename')} </MenuItem>
                        <MenuItem data-test-id="delete-folder" onClick={() => this.handleDeleteModal(this.state.fileMenu)}>{this.getStringDocument('Delete')}</MenuItem>
                      </Menu>
                    }

                    <label
                      data-test-id="add-folder-modal-label"
                      onClick={() => this.handleAddFolderModal(true)}
                      color="secondary"
                      style={{
                        color: '#FFF',
                        fontWeight: 600,
                        width: '100%',
                        textAlign: 'center'
                      }}
                    >
                      {newData.name && this.shortText(newData.name)}
                    </label>
                  </div>
                )
              })}

            </div>
            <Grid container spacing={6} style={{ marginTop: "30px", height: '36vh' }}>
              <Typography variant="h6" style={{
                color: 'FFF',
                opacity: '50%',
                padding: '0 45px'
              }}>{this.getStringDocument('AllDocuments')}</Typography>
              {this.state.loader && 
                <div style={webStyle.loaderCss}>
                  {this.renderLoaderWeb()}
                </div>
              }
              {
                this.state.allDocuments &&
                  this.state.allDocuments.length > 0 ?
                  this.state.allDocuments.map((element: documentMedias, index: number) => {
                    return (
                      <Grid container spacing={3} style={{ margin: "17px 23px", padding: "10px" }}>
                        <Grid item xs={1}><img style={{ width: '80px', height: "80px" }} src={document_file} alt='doc_file' /></Grid>
                        <Grid item xs={8} >
                          <div>
                            <Typography variant="h6" component="h2" style={{ color: "#FFFFFF", width: 200, wordBreak: "break-all" }}>
                              {element.file_name}
                            </Typography>
                            <Typography variant="h6" component="h2" style={{ color: '#FFFFFF', paddingTop: '10px', width: 200, wordBreak: "break-all", opacity: '50%' }}>
                              {element.updated_by}
                            </Typography>
                            <Typography variant="h6" component="h2" style={{ color: '#FFFFFF', paddingTop: '10px', width: 200, wordBreak: "break-all", opacity: '50%' }}>
                              {element.date}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={3} style={{ position: "relative" }} >
                          <IconButton
                            data-test-id="menu-options-button"
                            onClick={(event) => this.handleMenuModal({
                              file_id: element.id,
                              file: 'file'
                            }, event)}
                            aria-haspopup="true"
                            type="button"
                          >
                            <img src={threeDotss} alt="threeDotsMenu" height={"30px"} />
                          </IconButton>
                          {this.state.fileMenu?.file_id === element.id &&
                            <Menu
                              id="simple-menu"
                              anchorEl={this.state.anchorEl2}
                              keepMounted
                              open={Boolean(this.state.anchorEl2)}
                              onClose={this.handleDocumentMenuModalClose}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                            >
                              <MenuItem data-test-id="view" onClick={() => this.handleDocumentView(element.url)}>{this.getStringDocument('View')}</MenuItem>
                              <MenuItem data-test-id="removeFolder" onClick={() => this.handleDocumentDownload(element.url)}>{this.getStringDocument('Download')}</MenuItem>
                              <MenuItem data-test-id="deleteFolder" onClick={() => this.handleSelectFolderModal(true)}>{this.getStringDocument('AddToFolder')}</MenuItem>
                              <MenuItem data-test-id="downloadFolder" onClick={() => {this.handleDeleteModal(this.state.fileMenu)}} >{this.getStringDocument('Delete')}</MenuItem>
                            </Menu>
                          }

                        </Grid>

                      </Grid>
                    )
                  })
                  :
                  <Typography variant="h6" style={{
                    color: 'FFF',
                    opacity: '50%',
                    transform: 'translate(-5.5rem, 6rem)'
                  }}>{this.getStringDocument('NoDocuments')}</Typography>
              }
            </Grid>
          </Container>
        </div>
        {this.deleteModal()}
        {this.addFolderModal()}
        {this.renameFileModal()}
        {this.selectFolderModal()}
      </ThemeProvider >
    );
  }
}

const webStyle = {
  focused: {
    '& .MuiOutlinedInput-root': {
      color: 'green',
    },
  },
  folderImage: {
    width: '100%'
  },
  inputRoot: {
    '& .MuiPaper-root': {
      marginLeft: '1rem'
    }
  },
  addFolder: {
    background: '#fff',
    maxWidth: '400px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    position: 'absolute',
    borderRadius: '15px',
    textAlign: "center",
    paddingBottom: "40px",
    paddingTop: "20px"
  },
  loaderCss: {
    transform: 'translateY(-270px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    width: '100%'
  }
};


// Customizable Area End
