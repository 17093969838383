Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start

exports.btnExampleTitle = "CLICK ME";
exports.test = "test";

exports.languageListLogin = {
  English: {
    Mortgage: {
      title: "Add Mortgage",
      name: "Mortgage name",
      placeholderName: "Enter your mortgage name",
      amount: "Mortgage amount",
      placeholderAmount: "Enter the amount",
      term: "Mortgage term",
      months: "Months",
      placeholderTerm: "Enter years",
      placeholderMonths: "Enter months",
      annualInterestRate: "Annual interest rate (%)",
      placeholderAnnualInterestRate: "Enter annual interest rate (%)",
      monthlyPayments: "Mortgage monthly payments",
      placeholderMonthlyPayments: "Enter mortgage monthly payments",
      startDate: "Mortgage start date",
      firstPayment: "First mortgage payment date",
      placeholderFirstPayment: "Enter First mortgage payment",
      associatedInvestment: "Associated investment",
      amountError: "Mortgage amount is required",
      nameError: "Mortgage name is required",
      termError: "Mortgage term is required",
      termDecimalError: "Mortgage term must be an integer",
      monthsMinError: "Mortgage months must be greater than or equal to 0",
      monthsDecimalError: "Mortgage months must be an integer",
      monthsMaxError: "Mortgage months must be less than or equal to 12",
      monthsError: "Months is required",
      annualInterestRateError: "Annual interest rate is required",
      monthlyPaymentsError: "Monthly Payments is required",
      startDateError: "Start date is required",
      firstPaymentError: "First payment date is required",
      associatedInvestmentError: "Associated Investment is required",
      moreAmountError:"First loan payment can't be more than actual amount",
      save: "Save",
      currencyTypeError: "Currency is required",
      currency_type: "Currency",
      select: "Select",
      LiabilitySuccessMessage: "Your form is added successfully",
    }, 
    PersonalLoan: { 
      title: "Add Personal Loans",
      name: "Loan name",
      placeholderName: "Enter your loan name",
      amount: "Loan Amount",
      placeholderAmount: "Enter the amount",
      term: "Loan term",
      placeholderTerm: "Enter years",
      months: "Months",
      placeholderMonths: "Enter months",
      annualInterestRate: "Annual interest rate (%)",
      placeholderAnnualInterestRate: "Enter annual interest rate (%)",
      monthlyPayments: "Loan monthly payments",
      placeholderMonthlyPayments: "Enter loan monthly payments",
      firstPayment: "First loan payment date",
      startDate: "Loan start date",
      placeholderFirstPayment: "Enter First loan payment",
      associatedInvestment: "Associated investment",
      nameError: "Loan name is required",
      amountError: "Loan amount is required",
      termError: "Loan term is required",
      termDecimalError: "Loan term must be an integer",
      monthsDecimalError: "Loan months must be an integer",
      monthsMinError: "Loan months must be greater than or equal to 0",
      monthsMaxError: "Loan months must be less than or equal to 12",
      monthsError: "Months is required",
      monthlyPaymentsError: "Monthly Payments is required",
      annualInterestRateError: "Annual interest rate is required",
      startDateError: "Start date is required",
      firstPaymentError: "First payment date is required",
      associatedInvestmentError: "Associated Investment is required",
      moreAmountError:"First loan payment can't be more than actual amount",
      save: "Save",
      currencyTypeError: "Currency is required",
      currency_type: "Currency",
      select: "Select",
      LiabilitySuccessMessage: "Your form is added successfully",
    },
    Other: {
      name: "Loan name",
      title: "Add Other Loans",
      amount: "Loan Amount",
      placeholderName: "Enter your loan name",
      term: "Loan term",
      placeholderAmount: "Enter the amount",
      months: "Months",
      placeholderTerm: "Enter years",
      annualInterestRate: "Annual interest rate (%)",
      placeholderMonths: "Enter months",
      monthlyPayments: "Loan monthly payments",
      placeholderAnnualInterestRate: "Enter annual interest rate (%)",
      startDate: "Loan start date",
      placeholderMonthlyPayments: "Enter loan monthly payments",
      placeholderFirstPayment: "Enter First loan payment",
      firstPayment: "First loan payment date",
      nameError: "Loan name is required",
      associatedInvestment: "Associated investment",
      termError: "Loan term is required",
      amountError: "Loan amount is required",
      monthsDecimalError: "Loan months must be an integer",
      termDecimalError: "Loan term must be an integer",
      monthsMaxError: "Loan months must be less than or equal to 12",
      monthsMinError: "Loan months must be greater than or equal to 0",
      annualInterestRateError: "Annual interest rate is required",
      monthsError: "Months is required",
      startDateError: "Start date is required",
      monthlyPaymentsError: "Monthly Payments is required",
      associatedInvestmentError: "Associated Investment is required",
      firstPaymentError: "First payment date is required",
      moreAmountError:"First loan payment can't be more than actual amount",
      currencyTypeError: "Currency is required",
      currency_type: "Currency",
      save: "Save",
      select: "Select",
      LiabilitySuccessMessage: "Your form is added successfully",
    },
  },
  Arabic: {
    Mortgage: {
      title: "إضافة الرهن العقاري",
      name: "اسم الرهن العقاري",
      placeholderName: "أدخل اسم الرهن العقاري الخاص بك",
      amount: "مبلغ الرهن العقاري",
      term: "مدة الرهن العقاري",
      placeholderAmount: "أدخل المبلغ",
      placeholderTerm: "أدخل عدد السنوات",
      months: "الشهور",
      placeholderMonths: "أدخل عدد الشهور",
      annualInterestRate: "معدل الفائدة السنوي (%)",
      placeholderAnnualInterestRate: "أدخل معدل الفائدة السنوي (%)",
      placeholderMonthlyPayments: "أدخل الأقساط الشهرية للرهن العقاري",
      monthlyPayments: "الأقساط الشهرية للرهن العقاري",
      startDate: "تاريخ بدء الرهن العقاري",
      firstPayment: "تاريخ سداد الرهن العقاري الأول",
      placeholderFirstPayment: "أدخل الدفعة الأولى للرهن العقاري",
      associatedInvestment: "الاستثمار المرتبط",
      amountError: "مبلغ الرهن العقاري مطلوب",
      nameError: "اسم الرهن العقاري مطلوب",
      termError: "مدة الرهن العقاري مطلوبة",
      termDecimalError: "يجب أن يكون مصطلح الرهن العقاري عددا صحيحا",
      monthsDecimalError: "يجب أن تكون أشهر الرهن العقاري عددا صحيحا",
      monthsMinError: "يجب أن تكون الأشهر أكبر من أو تساوي 0",
      monthsError: "الشهور مطلوبة",
      monthsMaxError: "يجب أن تكون الأشهر أقل من أو تساوي 12",
      annualInterestRateError: "معدل الفائدة السنوي مطلوب",
      monthlyPaymentsError: "الأقساط الشهرية مطلوبة",
      startDateError: "تاريخ البدء مطلوب",
      firstPaymentError: "مطلوب تاريخ الدفع الأول",
      currencyTypeError: "العملة مطلوبة",
      associatedInvestmentError: "الاستثمار المرتبط مطلوب",
      moreAmountError: "لا يمكن أن تكون دفعة القرض الأولى أكثر من المبلغ الفعلي",
      save: "حفظ",
      currency_type: "عملة",
      select: "يختار",
      LiabilitySuccessMessage: "تمت إضافة النموذج الخاص بك بنجاح",
    }, 
    PersonalLoan: {
      title: "إضافة القروض الشخصية",
      name: "اسم القرض",
      placeholderName: "أدخل اسم القرض الخاص بك",
      amount: "مبلغ القرض",
      placeholderAmount: "أدخل المبلغ",
      term: "مدة القرض",
      placeholderTerm: "أدخل عدد السنوات",
      months: "الشهور",
      placeholderMonths: "أدخل عدد الشهور",
      placeholderAnnualInterestRate: "أدخل معدل الفائدة السنوي (%)",
      annualInterestRate: "معدل الفائدة السنوي (%)",
      monthlyPayments: "الأقساط الشهرية للقرض",
      placeholderMonthlyPayments: "أدخل الأقساط الشهرية للقرض",
      firstPayment: "تاريخ سداد القرض الأول",
      startDate: "تاريخ بدء القرض",
      placeholderFirstPayment: "أدخل الدفعة الأولى للقرض",
      associatedInvestment: "الاستثمار المرتبط",
      nameError: "اسم القرض مطلوب",
      amountError: "مبلغ القرض مطلوب",
      termError: "مدة القرض مطلوبة",
      monthsDecimalError: "يجب أن تكون أشهر القرض عددًا صحيحًا",
      termDecimalError: "يجب أن تكون مدة القرض رقمًا",
      monthsMinError: "يجب أن تكون أشهر القرض أكبر من أو تساوي 0",
      monthsMaxError: "يجب أن تكون أشهر القرض أقل من أو تساوي 12",
      monthsError: "الشهور مطلوبة",
      monthlyPaymentsError: "الأقساط الشهرية مطلوبة",
      annualInterestRateError: "معدل الفائدة السنوي مطلوب",
      startDateError: "تاريخ البدء مطلوب",
      firstPaymentError: "مطلوب تاريخ الدفع الأول",
      associatedInvestmentError: "الاستثمار المرتبط مطلوب",
      moreAmountError: "لا يمكن أن تكون دفعة القرض الأولى أكثر من المبلغ الفعلي",
      save: "حفظ",
      currencyTypeError: "العملة مطلوبة",
      currency_type: "عملة",
      select: "يختار",
      LiabilitySuccessMessage: "تمت إضافة النموذج الخاص بك بنجاح",
    },
    Other: {
      title: "إضافة القروض الأخرى",
      name: "اسم القرض",
      placeholderName: "أدخل اسم القرض الخاص بك",
      placeholderAmount: "أدخل المبلغ",
      amount: "مبلغ القرض",
      placeholderMonths: "أدخل عدد الشهور",
      placeholderTerm: "أدخل عدد السنوات",
      term: "مدة القرض",
      annualInterestRate: "معدل الفائدة السنوي (%)",
      months: "الشهور",
      placeholderAnnualInterestRate: "أدخل معدل الفائدة السنوي (%)",
      firstPayment: "تاريخ سداد القرض الأول",
      placeholderMonthlyPayments: "أدخل الأقساط الشهرية للقرض",
      monthlyPayments: "الأقساط الشهرية للقرض",
      placeholderFirstPayment: "أدخل الدفعة الأولى للقرض",
      startDate: "تاريخ بدء القرض",
      associatedInvestment: "الاستثمار المرتبط",
      nameError: "اسم القرض مطلوب",
      amountError: "مبلغ القرض مطلوب",
      termError: "مدة القرض مطلوبة",
      monthsMaxError: "يجب أن تكون أشهر القرض أقل من أو تساوي 12",
      monthsDecimalError: "يجب أن تكون أشهر القرض عددًا صحيحًا",
      termDecimalError: "يجب أن تكون مدة القرض رقمًا",
      annualInterestRateError: "معدل الفائدة السنوي مطلوب",
      monthsMinError: "يجب أن تكون أشهر القرض أكبر من أو تساوي 0",
      monthsError: "الشهور مطلوبة",
      firstPaymentError: "مطلوب تاريخ الدفع الأول",
      monthlyPaymentsError: "الأقساط الشهرية مطلوبة",
      startDateError: "تاريخ البدء مطلوب",
      save: "حفظ",
      associatedInvestmentError: "الاستثمار المرتبط مطلوب",
      currencyTypeError: "العملة مطلوبة",
      moreAmountError: "لا يمكن أن تكون دفعة القرض الأولى أكثر من المبلغ الفعلي",
      currency_type: "عملة",
      select: "يختار",
      LiabilitySuccessMessage: "تمت إضافة النموذج الخاص بك بنجاح",
    }
  }
}

exports.validationApiContentType = "application/json";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.validationApiMethodType = "GET";
exports.exampleApiContentType = "application/json";
exports.labelTitleText = "cradditionalformsformschangerequests";
exports.exampleAPiMethod = "POST";
exports.textInputPlaceHolder = "Enter Text";
exports.labelDataText = "cradditionalformsformschangerequests Data";
exports.exampleAPiPOSTMethod = "POST";
exports.exampleAPiDELETEMethod = "DELETE";
exports.labelBodyText = "cradditionalformsformschangerequests Body";
exports.exampleAPiPUTMethod = "PUT";

exports.languageListCommodity = {
  English: {
    Commodity: "Commodity",
    CommodityType: "Commodity Type",
    PreciousMetals: "Precious Metals",
    Others: "Others",
    NameOfmetal: "Name Of metal",
    NameOfacommodity: "Name Of a commodity",
    Quantity: "Quantity",
    Entermetalname: "Enter metal name",
    Entercommodityquantity: "Enter commodity quantity",
    PurchasePrice: "Purchase Price",
    Enterpurchaseprice: "Enter purchase price",
    Currency: "Currency",
    SelectCurrency: "Select Currency",
    TransactionDate: "Transaction Date",
    Save: "Save",
    addAssetSuccess: "Your asset is added successfully",
    nameValidation: "Name is required",
    quantityNumber: "Quantity must be a number",
    quantityGreaterZero: 'Quantity must be greater than zero',
    quantityInteger: "Quantity must be an integer",
    quantityValidation: "Quantity is required",
    priceNumber: 'Purchase Price must be a number',
    priceGreaterZero: 'Purchase Price must be greater than zero',
    priceValidation:"Purchase Price is required",
    transactionDate:'Transaction Date must be a valid date',
    transactionValidation: "Transaction Date is required",
  },
  Arabic: {
    transactionValidation: "تاريخ المعاملة مطلوب",
    transactionDate:'يجب أن يكون تاريخ المعاملة تاريخًا صالحًا',
    priceValidation:"سعر الشراء مطلوب",
    priceGreaterZero: 'يجب أن يكون سعر الشراء أكبر من الصفر',
    priceNumber: 'يجب أن يكون سعر الشراء رقمًا',
    quantityValidation: "الكمية مطلوبة",
    quantityGreaterZero: 'يجب أن تكون الكمية أكبر من الصفر',
    quantityInteger: "يجب أن تكون الكمية عددًا صحيحًا",
    quantityNumber: "يجب أن تكون الكمية رقمًا",
    nameValidation: "الاسم مطلوب",
    Commodity: "السلعة",
    CommodityType: "نوع السلعة",
    PreciousMetals: "المعادن الثمينة",
    Others: "أخرى",
    NameOfmetal: "اسم المعدن",
    NameOfacommodity: "اسم السلعة",
    Quantity: "الكمية",
    Entermetalname: "أدخل اسم المعدن",
    Entercommodityquantity: "أدخل كمية السلعة",
    PurchasePrice: "سعر الشراء",
    Enterpurchaseprice: "أدخل سعر الشراء",
    Currency: "العملة",
    SelectCurrency: "اختر العملة",
    TransactionDate: "تاريخ المعاملة",
    Save: "حفظ",
    addAssetSuccess: "تمت إضافة الأصل بنجاح"
  }
  
}

exports.languageListLiability = {
  English: {
    Addliability: "Add liability",
    Mortgages: "Mortgages",
    CreditCards: "Credit Cards",
    PersonalLoans: "Personal Loans",
    Other: "Other",
    Next: "Next",
  },
  Arabic: {
    Addliability: "إضافة التزام",
    Mortgages: "الرهون العقارية",
    CreditCards: "بطاقات الائتمان",
    PersonalLoans: "القروض الشخصية",
    Other: "أخرى",
    Next: "التالي",
  }
  
  
}

exports.languageListBankOverview = {
  English: {
    BankAccounts: "Bank Accounts",
    AccNo: "Acc.No",
    Price: "Price",
    Date: "Date",
    Edit: "Edit",
    Delete: "Delete",
    deleteConfirm: "Are you sure you want to delete?",
    Cancel: "Cancel",
  },
  Arabic: {
    BankAccounts: "الحسابات المصرفية",
    AccNo: "رقم الحساب",
    Price: "السعر",
    Date: "التاريخ",
    Edit: "تعديل",
    Delete: "حذف",
    deleteConfirm: "هل أنت متأكد أنك تريد الحذف؟",
    Cancel: "إلغاء",
  }
}

exports.languageListCommodityOverview = {
  English: {
    Commodity: "Commodity",
    Date: "Date",
    Quantity: "Quantity",
    PurchasePrice: "Purchase Price",
    Currency: "Currency",
    Nodataavailable: "No data available",
    Edit: "Edit",
    Delete: "Delete",
    deleteConfirm: "Are you sure you want to delete?",
    Cancel: "Cancel",
  },
  Arabic: {
    Commodity: "السلعة",
    Date: "التاريخ",
    Quantity: "الكمية",
    PurchasePrice: "سعر الشراء",
    Currency: "العملة",
    Nodataavailable: "لا توجد بيانات متاحة",
    Edit: "تعديل",
    Delete: "حذف",
    deleteConfirm: "هل أنت متأكد أنك تريد الحذف؟",
    Cancel: "إلغاء",
  }
}

exports.languageListCRaddForms = {
  English: {
    AddBankAccounts: "Add Bank Accounts",
    EnterManually: "Enter Manually",
    AutoConnect: "Auto Connect",
    Next: "Next",
  },
  Arabic: {
    AddBankAccounts: "إضافة حسابات مصرفية",
    EnterManually: "أدخل يدويًا",
    AutoConnect: "اتصال تلقائي",
    Next: "التالي",
  }
  
}

exports.languageListLiabilityAssets = {
  English: {
    deleteConfirm: "Are you sure you want to delete ?",
    Cancel: "Cancel",
    Delete: "Delete",
    CreditCard: "Credit Card",
    CreditCards: "Credit Cards",
    Balance: "Balance",
    Edit: "Edit",
    Loanamount: "Loan amount",
    Terms: "Terms",
    AIR: "AIR",
    Installmentamount: "Installment amount",
    Monthsremaining: "Months remaining",
    Amountremaining: "Amount remaining",
    months: "months",
    Months: "Months",
    Liability: "Liability",
    Mortgages: "Mortgages",
    Creditcards: "Credit cards",
    PersonalLoans: "Personal Loans",
    Otherloans: "Other loans",
  },
  Arabic: {
    deleteConfirm: "هل أنت متأكد أنك تريد الحذف؟",
    Cancel: "إلغاء",
    Delete: "حذف",
    CreditCard: "بطاقة ائتمان",
    CreditCards: "بطاقات الائتمان",
    Balance: "الرصيد",
    Edit: "تعديل",
    Loanamount: "مبلغ القرض",
    Terms: "الشروط",
    AIR: "معدل الفائدة السنوي",
    Installmentamount: "مبلغ القسط",
    Monthsremaining: "الأشهر المتبقية",
    Amountremaining: "المبلغ المتبقي",
    months: "شهور",
    Months: "الشهور",
    Liability: "التزام",
    Mortgages: "الرهون العقارية",
    Creditcards: "بطاقات الائتمان",
    PersonalLoans: "القروض الشخصية",
    Otherloans: "قروض أخرى",
  }
  
  
}

exports.languageListManualForms = {
  English: {
    EditBankAccount: "Edit Bank Account",
    AddBankAccount: "Add Bank Account",
    Name: "Name",
    placeholderName: "Give a name of your account name",
    Amount: "Amount",
    placeholderAmount: "Enter the amount",
    Currency: "Currency",
    placeholderCurrency: "Select Currency",
    Date: "Date",
    Note: "Note",
    placeholderNote: "Enter your note",
    Save: "Save",
    addAccountSuccess: "Your account is added successfully",
    nameValidation: "Name is required",
    amountValidation: "Amount is required",
    digitValidation: "Amount should be exactly 11 digits or less",
    noteValidation: "Note is required",
    currencyValidation:"Currency is required",
    dateValidation: "Date is required",
    dateFormatValidation: "Date must be a valid date",
  },
  Arabic: {
    dateFormatValidation: "يجب أن يكون التاريخ تاريخًا صالحًا",
    dateValidation: "التاريخ مطلوب",
    currencyValidation: "العملة مطلوبة",
    noteValidation: "الملاحظة مطلوبة",
    digitValidation: "يجب أن يتكون المبلغ بالضبط من 11 رقمًا أو أقل",
    amountValidation: "المبلغ مطلوب",
    nameValidation: "الاسم مطلوب",
    EditBankAccount: "تعديل الحساب المصرفي",
    AddBankAccount: "إضافة حساب مصرفي",
    Name: "الاسم",
    placeholderName: "أدخل اسم حسابك",
    Amount: "المبلغ",
    placeholderAmount: "أدخل المبلغ",
    Currency: "العملة",
    placeholderCurrency: "اختر العملة",
    Date: "التاريخ",
    Note: "ملاحظة",
    placeholderNote: "أدخل ملاحظتك",
    Save: "حفظ",
    addAccountSuccess: "تمت إضافة حسابك بنجاح",
  }  
}
// Customizable Area End