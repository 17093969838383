// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.send_requests_Post_Data = "POST";
exports.users_slots="/bx_block_appointment_management/appointments/user_slot";
exports.send_requests_Get = "GET";
exports.send_requestsPost = "/bx_block_formapprovalworkflow/send_requests/create_engagement_request?wealth_planner_id";
exports.send_requests_Post = "POST";
exports.send_allow_access = '/account_block/accounts/allow_access_permission';
exports.getCalenderDateEndPoint='bx_block_appointment_management/appointment_slots/available_slots';
exports.rescheduleBookingEndPoint = 'bx_block_appointment_management/appointments/reschedule_booking';
exports.cancelSlot = 'bx_block_appointment_management/appointments/cancel_slot'
exports.send_allow_access_post = "POST";
exports.getCalenderDateEndPoint='bx_block_appointment_management/appointment_slots/available_slots'
exports.getBookSlotEndPoint='bx_block_appointment_management/appointments/user_slot'
exports.postSetPlanEndPoint='bx_block_appointment_management/service_planners/set_plan'
exports.getPlanEndPoint='bx_block_appointment_management/service_planners/get_plan'
exports.postApiMethod= 'POST'
exports.getApiMethod= "GET"
exports.getConversationApi= "twilio_chat/create_conversation?type=user&receiver_id=";
exports.getUserList = "twilio_chat/get_conversation_list";
exports.generateTwilioTokenApiEndPoint="twilio_chat/get_twilio_token";
exports.createConversationEndPoint="twilio_chat/create_conversation";

exports.exampleAPiMethodPostType = "POST";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelBodyText = "FormApprovalWorkflow Body";
exports.labelTitleText = "FormApprovalWorkflow";
exports.CreateIdApi='bx_block_formapprovalworkflow/formapprovalworkflows/create_customer_trip'
exports.btnExampleTitle = "CLICK ME";
exports.cancelAPI = "bx_block_formapprovalworkflow/formapprovalworkflows/cancel_trip";
exports.FinancialPlannerApiMethodType = "GET";
exports.FinancialPlannerGet = "/bx_block_formapprovalworkflow/send_requests/wealth_planner_index";
exports.FinancialPlannerPost = "/bx_block_formapprovalworkflow/send_requests/create_engagement_request";
exports.FinancialPlannerApiMethodTypePost = "POST";
exports.send_requests = "/bx_block_formapprovalworkflow/send_requests/get_status";
exports.service_status = "/bx_block_proposal_generation/user_service_selections/services_status";
exports.new_added_service = "/bx_block_proposal_generation/user_service_selections/newly_added_services";
exports.index_payment_method = "/bx_block_stripe_integration/payment_methods";
exports.sub_total = "/bx_block_proposal_generation/user_service_selections/subtotal";
exports.set_service = "/bx_block_proposal_generation/user_service_selections";

// Role IDs
exports.user_type_id = 'planner_id' 


exports.languageListFinancialPlanner = {
  English: {
    successMessage: "Your request is sent successfully.",
    Next: "Next",
    notifyMessage:"You'll be notified once its accepted!",
    selectPlannerDesc: "List of wealth planners and advisors recommended for you. Select one to continue.",
    EstatePlanning: "Estate Planning",
    NameofCorporation: "Name of Corporation",
    Location: "Location",
    JurisdictionAdvise: "Jurisdiction advise",
    Yrs: "Yrs",
    Experience: "Experience",
    Yr: "Yr",
    Focus: "Focus",
    Email: "Email",
    Qualifications: "Qualifications",
    Certifications: "Certifications",
    Firms: "Firms",
    EmployementHistory: "Employment History",
    Disclosure: "Disclosure",
    AllReviews: "All Reviews",
    RatingsandReviews: "Ratings and reviews",
    SendEngagementRequest: "Send Engagement Request",
    basedoutof: "based out of",
    reviews: "reviews",
    Less: "Less",
    More: "More",
    noReviews: "No reviews yet to show!",
  },
  Arabic: {
    Next: "التالي",
    successMessage: "تم إرسال طلبك بنجاح.",
    notifyMessage: "ستتم إشعارك بمجرد قبوله!",
    EstatePlanning: "التخطيط العقاري",
    selectPlannerDesc: "قائمة بمخططي ومستشاري الثروات الموصى بهم لك. اختر واحدًا للمتابعة.",
    NameofCorporation: "اسم الشركة",
    Location: "الموقع",
    JurisdictionAdvise: "استشارة الاختصاص",
    Experience: "الخبرة",
    Yrs: "سنوات",
    Yr: "سنة",
    Focus: "الاختصا",
    Email: "البريد الإلكتروني",
    Certifications: "الشهادات",
    Qualifications: "المؤهلات",
    Firms: "الشركات",
    EmployementHistory: "تاريخ التوظيف",
    Disclosure: "الإفصاح",
    RatingsandReviews: "التقييمات والمراجعات",
    AllReviews: "جميع المراجعات",
    SendEngagementRequest: "إرسال طلب الاشتراك",
    basedoutof: "مقره في",
    reviews: "المراجعات",
    More: "المزيد",
    Less: "أقل",
    noReviews: "لا توجد مراجعات لعرضها حتى الآن!"
  }
}

exports.languageListEngagementRequest = {
  English: {
    EstatePlanning: "Estate Planning",
    Location: "Location",
    NameofCorporation: "Name of Corporation",
    NameofCorporationn:'hello',
    loc:'location',
    JurisdictionAdvise: "Jurisdiction advise",
    Experience: "Experience",
    Yrs: "Yrs",
    Focus: "Focus",
    Yr: "Yr",
    Email: "Email",
    Certifications: "Certifications",
    Qualifications: "Qualifications",
    Firms: "Firms",
    EmployementHistory: "Employment History",
    Disclosure: "Disclosure",
    AllReviews: "All Reviews",
    RatingsandReviews: "Ratings and reviews",
    basedoutof: "based out of",
    reviews: "reviews",
    Less: "Less",
    More: "More",
    noReviews: "No reviews yet to show!",
    allowAccessDesc: "Your request has been accepted! Please allow Myne to share your access to your net worth data and asset distribution to your financial planner in order to continue with Estate Planning services. This can be updated from phone settings.",
    rejectRequestDesc: "Your request has been rejected!",
    addServiceSuccess: "Your service has been added!",
    requestSentDesc: "Your request has been sent. You will be notified shortly!",
    ViewServices: "View Services",
    notificationMessage: "You have an upcoming meeting!",
    Chat: "Chat",
    ModifyBooking: "Modify Booking",
    ScheduleCall: "Schedule Call",
    callScheduleAt: "Your Call is Scheduled at",
    RequestSent: "Request Sent",
    chooseDifferentPlanner: "Choose a different wealth planner",
    myneSuccessDesc2: "This allows the platform to share information about you to wealth planners for your estate planning services.",
    myneSuccessDesc1: '"Myne" wants to access your net worth data and asset distribution',
    Allow: "Allow",
    dontAllow: "Don't Allow",
  },
  Arabic: {
    EstatePlanning: "التخطيط العقاري",
    NameofCorporation: "اسم الشركة",
    Location: "الموقع",
    JurisdictionAdvise: "استشارة الاختصاص",
    Experience: "الخبرة",
    Yrs: "سنوات",
    Yr: "سنة",
    Focus: "التركيز",
    Email: "البريد الإلكتروني",
    Certifications: "الشهادات",
    Qualifications: "المؤهلات",
    Firms: "الشركات",
    EmployementHistory: "تاريخ التوظيف",
    Disclosure: "الإفصاح",
    loc:'لك',
    RatingsandReviews: "التقييمات والمراجعات",
    AllReviews: "جميع المراجعات",
    basedoutof: "مقره في",
    reviews: "المراجعات",
    More: "المزيد",
    Less: "أقل",
    noReviews: "لا توجد مراجعات لعرضها حتى الآن!",
    allowAccessDesc: "تم قبول طلبك! يرجى السماح لـ Myne بمشاركة الوصول إلى بيانات صافي الثروة وتوزيع الأصول الخاصة بك مع مخططك المالي من أجل الاستمرار في خدمات التخطيط العقاري. يمكن تحديث ذلك من إعدادات الهاتف.",
    rejectRequestDesc: "تم رفض طلبك!",
    addServiceSuccess: "تمت إضافة خدمتك!",
    ViewServices: "عرض الخدمات",
    requestSentDesc: "تم إرسال طلبك. سيتم إعلامك قريباً!",
    notificationMessage: "لديك اجتماع قادم!",
    Chat: "محادثة",
    ModifyBooking: "تعديل الحجز",
    callScheduleAt: "تم جدولة مكالمتك في",
    ScheduleCall: "جدولة مكالمة",
    RequestSent: "تم إرسال الطلب",
    chooseDifferentPlanner: "اختر مخطط ثروة مختلف",
    myneSuccessDesc1: "يريد 'Myne' الوصول إلى بيانات صافي الثروة الخاصة بك وتوزيع الأصول",
    myneSuccessDesc2: "يسمح هذا للنظام بمشاركة معلومات عنك مع مخططي الثروة لخدمات التخطيط العقاري.",
    dontAllow: "عدم السماح",
    Allow: "السماح",
  }
}
exports.languageListAppointment = {
  English:{
    ScheduleCall: "Schedule Call",
    EstatePlanning: "Estate Planning",
    StartNewService:"Start new service",
    Requested:'Requested',
    FinancialPlanner:'Financial Planner',
    reviews: "reviews",
    NoNewRequestedService:'No new requested services to show yet!',
    NoOngoingService:'No ongoing services to show!',
    NoCompletedService:'No completed services to show!',
    YourServiceAdded:'Your service have been added!',
    Ongoing:'Ongoing',
    Completed:'Completed',
    ViewServices: "View Services",
    Accepted:"Accepted",
    Location: "Location:",
    JurisdictionAdvice: 'Jurisdiction advice:',
    Qualifications:'Qualifications:',
    Experience:'Experience:',
    Chat: "Chat",
    JoinCall:'Join Call',
    VideoCall:"Video Call",
    YourCallScheduled:"Your call is scheduled for",
    Pending:'Pending',
    AppointEnd:'Appointment Ended',
    Delete: "Delete",
    WriteReview:'Write a Review',
    MaxWords:'Max. 250 words',
    DeletePopupMsg:"Are you sure you want to delete?",
    PlannerDeleteSuccess:"Financial Planner Successfully deleted!",
    Rejected:'Rejected',
    Cancel:'Cancel',
    NoReviews: "No reviews yet to show!",
    ProceedPayment:'Proceed to payment',
    Details:'Details:',
    ConsultationDate:"Consultation Date :",
    ConsultationTime:"Consultation Time :",
    SelectedPlan: "Selected Plan",
    BenifitsOfPlan:'Benefits of Plan',
    Summary:'Summary',
    YourPlan:'Your Plan',
    Payment:'Payment',
    TotalCharge:'Total Charge',
    BillingAddress:'Billing Address',
    AddNewAddress:'Add new address',
    EditDetails:"Edit details",
    PaymentMethod:"Payment Method",
    Primary:'Primary',
    ExpiresOn:'expires on',
    CreditDebitCard:'Credit/Debit Card',
    rev:'reviews',
    CompletePurchase: "Complete Purchase",
    AuthWithCVV:"Authenticate With CVV",
    enterAddress:'Enter Address',
    addAddress: 'Add Address',
    successMsg:'Your call has rescheduled successfully !',
    editAddress: 'Edit Address',
    address:'Address:',
    name:'Name:',
    city:'City:',
    enterCity:'Enter City',
    enterName:'Enter Name',
    zipCode:'Zip Code: (Optional)',
    selectCountry: 'Select Country:',
    EnterZipCode:"Enter Zip Code",
    confirmDetails:'Confirm details',
    AddressUpdateSuccess:"Billing Address updated successfully!",
    addressValidation:"Some of your fields are not properly filled",
    PaymentfailedMsg:'Payment Failed, try a different payment method or try again later',
    NameValidation:"This is not right name",
    CityValidation:"City name should only contain letters and whitespace",
    planError:'Please select a slot',
    selectPlanError:'please select any plan',
    ZipLengthValidation:"Zip code should only contain digits and have a maximum length of 9 characters",
    ZipCodeMandatory:"The zip code for the United States is required. Please enter the zip code first",
    EnterValidCvv:'Enter valid CVV',
    CardNotSaved:'Card is not saved',
    CardNameNotValid:"Card name is not valid",
    CardSaveSuccess:'Card saved successfully',
    CardAddSuccess:'Card added successfully',
    sessionName:'Session Packages',
    sessionTitle:'Select a plan to continue',
    bookConstBtn:'Book Consultation',
    Email:'Email',
    noPlan:'No plan available',
    Certification:'Certifications',
    ScheduleConstCall:'Schedule a consultation call',
    Congratulations:"Congratulations",
    CallIsBooked:'Your consultation call is booked!',
    continueBtn:'Continue',
    ScheduleTime:'Schedule Time',
    modifyTitle:'Modify Booking',
    GOBackEstate:'Go back to estate planning',
    nextSlot:'Next availability is on',
    Quali:'Qualifications',
    noSlots:'No slots available',
    nameCorp:'Name of Corporation LTD.',
    Focus:'Focus',
    Firm:'Firms',
    JurisdictionAd:'Jurisdiction advise',
    EmpHist:'Employment History',
    DisclosureTitle:'Disclosure',
    RatRew:'Ratings and reviews',
    Exp:'Experience',
    AllRev:'All Reviews',
    DiffPlanner:'Choose a different wealth planner',
    ScheduleAppoint:'Scheduled Appointment',
    yourPack:'Your Package',
    cancelCall:'Are you sure you want to cancel your call ?',
    canc:'Cancel',
    AllowReq:'Your request has been accepted! Please allow Myne to share your access to your net worth data and asset distribution to your financial planner in order to continue with Estate Planning services. This can be updated from phone settings.',
    reSchedule:'Reschedule',
    reScheduleCall:"Are you sure you want to reschedule your call ?",
    Yes:'Yes',
    Less:'Less',
    basedOut:'based out of ',
    joinCall:'Join Call',
    videoCallBtn:'Video Call',
    RejReq:'Your request has been rejected!',
    ReqSent:'Request Sent',
    EstateBtn:' Go back to Estate Planning ',
    YourExp:'How was your experience   with',
    submitBtn:' submitted!',
    SubmitReview:'Submit Review',
    reviewSuccess:'Your review is successfully ',
    ExpPlace:'Tell people about your experience',
    More:'More',
    CancelSlotSuccess:"Appointment cancelled successfully!",
  },
  Arabic:{
    CancelSlotSuccess:"تم إلغاء الموعد بنجاح!",
    noPlan:'لا توجد خطة متاحة',
    More:'أكثر',
    Less:'أقل',
    cancelCall:'هل أنت متأكد أنك تريد إلغاء مكالمتك؟',
    rev:'المراجعات',
    Yes:'نعم',
    ReqSent:'تم إرسال الطلب',
    reviewSuccess:'تمت مراجعتك بنجاح',
    EstateBtn:'ارجع إلى التخطيط العقاري',
    ZipCodeMandatory:'الرمز البريدي للولايات المتحدة مطلوب. الرجاء إدخال الرمز البريدي أولا',
    FinancialPlanner:'مخطط مالي',
    SubmitReview:'إرسال المراجعة',
    YourExp:'كيف كانت تجربتك مع',
    ExpPlace:'أخبر الناس عن تجربتك',
    submitBtn:'مُقَدَّم!',
    reScheduleCall:'هل أنت متأكد أنك تريد إعادة جدولة مكالمتك؟',
    AllowReq:'لقد تم قبول طلبك! يرجى السماح لـ Myne بمشاركة وصولك إلى بيانات صافي ثروتك وتوزيع الأصول مع المخطط المالي الخاص بك من أجل الاستمرار في خدمات التخطيط العقاري. يمكن تحديث هذا من إعدادات الهاتف.',
    RejReq:'لقد تم رفض طلبك!',
    MaxWords:'الأعلى. 250 كلمة',
    RatRew:'التقييمات والمراجعات',
    basedOut:'على أساس من',
    ScheduleAppoint:'الموعد المقرر',
    yourPack:'الحزمة الخاصة بك',
    reSchedule:'إعادة جدولة',
    successMsg:'تمت إعادة جدولة مكالمتك بنجاح!',
    canc:'يلغي',
    Firm:'الشركات',
    Email:'بريد إلكتروني',
    DiffPlanner:'اختر مخطط ثروة مختلفًا',
    DisclosureTitle:'المفصح',
    AllRev:'جميع التقييمات',
    AppointEnd:'انتهى الموعد',
    EmpHist:'تاريخ التوظيف',
    GOBackEstate:'ارجع إلى التخطيط العقاري',
    CallIsBooked:'تم حجز مكالمتك الاستشارية!',
    Congratulations:'تهانينا',
    Certification:'الشهادات',
    bookConstBtn:'استشارة حول كتاب',
    selectPlanError:'الرجاء تحديد أي خطة',
    CardAddSuccess:'تمت إضافة البطاقة بنجاح',
    joinCall:'الانضمام إلى المكالمة',
    videoCallBtn:'مكالمة فيديو',
    planError:'الرجاء تحديد فتحة',
    JurisdictionAd:'نصيحة الاختصاص',
    nextSlot:'التوفر التالي قيد التشغيل',
    Quali:'المؤهلات',
    Exp:'خبرة',
    Focus:'الاختصاص',
    modifyTitle:'تعديل الحجز',
    nameCorp:'اسم الشركة المحدودة.',
    CardSaveSuccess:'تم حفظ البطاقة بنجاح',
    CardNameNotValid:"اسم البطاقة غير صالح",
    noSlots:'لا توجد فتحات متاحة',
    CardNotSaved:'لم يتم حفظ البطاقة',
    ZipLengthValidation:"يجب أن يحتوي الرمز البريدي على أرقام فقط وأن يبلغ الحد الأقصى لطوله 9 أحرف",
    EnterValidCvv:'أدخل رمز CVV صالحًا',
    NameValidation:"هذا ليس الاسم الصحيح",
    ScheduleTime:'جدولة الوقت',
    ScheduleConstCall:'جدولة مكالمة التشاور',
    CityValidation:"يجب أن يحتوي اسم المدينة على أحرف ومسافات بيضاء فقط",
    addressValidation:"بعض الحقول الخاصة بك لم يتم ملؤها بشكل صحيح",
    PaymentfailedMsg:'فشل الدفع، حاول طريقة دفع مختلفة أو حاول مرة أخرى لاحقًا',
    AddressUpdateSuccess:"تم تحديث عنوان إرسال الفواتير بنجاح!",
    confirmDetails:'تأكيد التفاصيل',
    editAddress: 'تحرير العنوان',
    sessionName:'حزم الجلسة',
    continueBtn:'متابعة',
    sessionTitle:'اختر خطة للمتابعة',
    selectCountry: 'اختر الدولة:',
    EnterZipCode:"أدخل الرمز البريدي",    
    city:'مدينة:',
    DeletePopupMsg:"هل أنت متأكد أنك تريد الحذف؟",
    ScheduleCall: "جدولة المكالمة",
    WriteReview:'اكتب مراجعة',
    Delete: "يمسح",
    NoReviews: "لا توجد تعليقات حتى الآن لتظهر!",
    Rejected:'مرفوض',
    YourCallScheduled:"تمت جدولة مكالمتك",
    Location: "موقع:",
    VideoCall:"مكالمة فيديو",
    JoinCall:'الانضمام إلى المكالمة',
    Chat: "محادثة",
    JurisdictionAdvice: 'نصيحة قضائية:',
    Qualifications:'المؤهلات:',
    Experience:'خبرة:',
    ViewServices: "عرض الخدمات",
    YourServiceAdded:'تمت إضافة خدمتك!',
    NoCompletedService:'لا توجد خدمات مكتملة للعرض!',
    Completed:'مكتمل',
    Accepted:"مقبول",
    NoOngoingService:'لا يوجد مسلسل مستمر لعرضه!',
    Ongoing:'مستمر',
    NoNewRequestedService:'لا توجد خدمات جديدة مطلوبة لعرضها بعد!',
    StartNewService:"ابدأ خدمة جديدة",
    EstatePlanning: "التخطيط العقاري",
    Cancel:'يلغي',
    PlannerDeleteSuccess:"تم حذف المخطط المالي بنجاح!",
    Requested:"مطلوب",
    Pending:"قيد الانتظار",
    reviews: "المراجعات",
    zipCode:'الرمز البريدي: (اختياري)',
    enterCity:'أدخل المدينة',
    address:'عنوان:',
    name:'اسم:',
    enterName:'أدخل الاسم',
    addAddress: 'إضافة عنوان',
    enterAddress:'أدخل العنوان',
    AuthWithCVV:"المصادقة مع CVV",
    CompletePurchase: "شراء كامل",
    CreditDebitCard:'بطاقة الائتمان/الخصم',
    ExpiresOn:'تنتهي في',
    Primary:'أساسي',
    AddNewAddress:'أضف عنوانًا جديدًا',
    PaymentMethod:"طريقة الدفع",
    EditDetails:"تحرير التفاصيل",
    BillingAddress:'عنوان الفواتير',
    TotalCharge:'إجمالي الرسوم',
    Payment:'قسط',
    YourPlan:'خطتك',
    Summary:'ملخص',
    BenifitsOfPlan:'فوائد الخطة',
    SelectedPlan: "الخطة المختارة",
    ConsultationTime:"وقت الاستشارة :",
    ConsultationDate:"تاريخ الاستشارة :",
    Details:'تفاصيل:',
    ProceedPayment:'انتقل إلى الدفع',
  }
}

exports.engagementServiceProposal = {
  English:{
    confirmOrder: "Confirm Order",
    serviceProposal: "Service Proposal",
    selectedServices: "Selected Services.",
    selectServices: "Select your desired services and make the payment to continue.",
    planInclusions: "Plan inclusions",
    continue: "Continue",
    remove: "Remove",
    pleaseSelect: "Please select at least one service",
  },
  Arabic:{
    confirmOrder: "تأكيد الطلب",
    serviceProposal: "اقتراح الخدمة",
    selectedServices: "الخدمات المختارة.",
    selectServices: "حدد الخدمات التي تريدها وقم بالدفع للمتابعة.",
    planInclusions: "شموليات الخطة",
    continue: "متابعة",
    remove: "إزالة",
    pleaseSelect: "يرجى اختيار خدمة واحدة على الأقل",
  }
}

exports.estatePlanningDashboardTranslations = {
  English: {
    ongoing: "Ongoing",
    completed: "Completed",
    noCompletedServices: "No completed services to show!",
    noOngoingServices: "No ongoing services to show!",
    serviceEnded: "Your service has ended!",
    keepAccess: "Would you like to keep access to planner for your assets or close it?",
    keepAccessBtn: "Keep Access",
    closeAccessBtn: "Close Access",
    reviewRecorded: "Your response has been recorded.",
    giveReview: "Would you like to give a review?",
    writeReviewBtn: "Write a review",
    maybeLaterBtn: "Maybe Later",
  },
  Arabic: {
    ongoing: "جارٍ التنفيذ",
    completed: "منجز",
    noCompletedServices: "لا توجد خدمات منجزة لعرضها!",
    noOngoingServices: "لا توجد خدمات جارية لعرضها!",
    serviceEnded: "لقد انتهت خدمتك!",
    keepAccess: "هل ترغب في الاحتفاظ بإمكانية الوصول إلى المخطط لأصولك أم إغلاقه؟",
    keepAccessBtn: "الاحتفاظ بالوصول",
    closeAccessBtn: "إغلاق الوصول",
    reviewRecorded: "تم تسجيل استجابتك.",
    giveReview: "هل ترغب في ترك تعليق؟",
    writeReviewBtn: "كتابة مراجعة",
    maybeLaterBtn: "ربما لاحقًا",
  },
};



exports.languageListService = {
  English: {
    ProceedPayment:"Proceed to payment",
    Summary:'Summary',
    YourService:'Your Service',
    Payment:'Payment',
    TotalCharge:'Total Charge',
    BillingAddress:'Billing Address',
    Service:'Service',
    Services:'Services',
    EditDetails:"Edit details",
    AddNewAddress:'Add new address',
    PaymentMethod:"Payment Method",
    ZipMandatory:"The zip code for the United States is required. Please enter the zip code first",
    CreditDebitCard:'Credit/Debit Card',
    AuthWithCVV:"Authenticate With CVV",
    ExpiresOn:'expires on',
    AddAddress: 'Add Address',
    Name:'Name:',
    EditAddress: 'Edit Address',
    address:'Address:',
    enterAddress:'Enter Address',
    city:'City:',
    enterCity:'Enter City',
    zipCode:'Zip Code: (Optional)',
    EnterZipCode:"Enter Zip Code",
    selectCountry: 'Select Country:',
    confirmDetails:'Confirm details',
    enterName:'Enter Name',
    Primary:'Primary',
    AddressUpdateSuccess:"Billing Address updated successfully!",
    CardTaken:"Card has already been taken",
    addressValidation:"Some of your fields are not properly filled",
    NameValidation:"This is not right name",
    ZipLengthValidation:"Zip code should only contain digits and have a maximum length of 9 characters",
    CardNotSaved:'Card is not saved',
    CardSaveSuccess:'Card saved successfully',
    Congratulations:"Congratulations",
    PrchaseSuccess:'Your purchase is successfull!',
    GOBackEstate:'Go back to estate planning',
    continueBtn:'Continue',
  },
  Arabic: {
    continueBtn:'متابعة',
    GOBackEstate:'ارجع إلى التخطيط العقاري',
    PrchaseSuccess:'عملية الشراء الخاصة بك ناجحة!',
    Congratulations:'تهانينا',
    CardSaveSuccess:'تم حفظ البطاقة بنجاح',
    ZipMandatory:'الرمز البريدي للولايات المتحدة مطلوب. الرجاء إدخال الرمز البريدي أولا',
    CardNotSaved:'لم يتم حفظ البطاقة',
    ZipLengthValidation:"يجب أن يحتوي الرمز البريدي على أرقام فقط وأن يبلغ الحد الأقصى لطوله 9 أحرف",
    NameValidation:"هذا ليس الاسم الصحيح",
    addressValidation:"بعض الحقول الخاصة بك لم يتم ملؤها بشكل صحيح",
    CardTaken:"لقد تم بالفعل أخذ البطاقة",
    AddressUpdateSuccess:"تم تحديث عنوان إرسال الفواتير بنجاح!",
    Primary:'أساسي',
    enterName:'أدخل الاسم',
    confirmDetails:'تأكيد التفاصيل',
    selectCountry: 'اختر الدولة:',
    EnterZipCode:"أدخل الرمز البريدي",    
    zipCode:'الرمز البريدي : (اختياري)',
    enterCity:'أدخل المدينة',
    city:'مدينة:',
    enterAddress:'أدخل العنوان',
    address:'عنوان:',
    EditAddress: 'تحرير العنوان',
    Name:'اسم:',
    AddAddress: 'إضافة عنوان',
    ExpiresOn:'تنتهي في',
    AuthWithCVV:"المصادقة مع CVV",
    CreditDebitCard:'بطاقة الائتمان/الخصم',
    PaymentMethod:"طريقة الدفع",
    AddNewAddress:'أضف عنوانًا جديدًا',
    EditDetails:"تحرير التفاصيل",
    Services:'خدمات',
    Service:'خدمة',
    BillingAddress:'عنوان الفواتير',
    TotalCharge:'إجمالي الرسوم',
    Payment:'قسط',
    YourService:'خدمتك',
    ProceedPayment:"انتقل إلى الدفع",
    Summary:'ملخص',
  }
}

// Customizable Area End
