// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Documentation";
exports.labelBodyText = "Documentation Body";

exports.btnExampleTitle = "CLICK ME";
exports.rename="Rename";
exports.delete="Delete";
exports.document="Upload Document";
exports.folder="Add Folder";
exports.documents="Documents";
exports.deleteFolder="Delete Folder";
exports.addFolder="Add Folder";
exports.cancelText="Cancel";
exports.createText="Create";
exports.renameText="Rename";
exports.deleteText="Delete";
exports.closeText="CLOSE";
exports.renameFile="RENAME";
exports.deleteFile="DELETE";
exports.yesModal='YES';
exports.noModal='NO';
exports.file='Do you want to delete the File ?';
exports.deletefolder='Do you want to delete the Folder ?';
exports.folderDocument='THIS FOLDER IS EMPTY !';
exports.documentation="DATA NOT FOUND!"


exports.languageListDocumentation = {
  English: {
    SelectFolderAddDocument:"Select a folder to add to your document",
    CreateFolder:"Create New Folder",
    Delete:'Delete',
    AllDocuments:'All Documents',
    View:'View',
    Download:'Download',
    AddToFolder:"Add To Folder",
    NoDocuments:"You don't have any documents yet!",
    FolderDeleteWarning:'Are you sure you want to delete? The folder and any documents stored inside the folder will be deleted permanently.',
    FileDeleteWarning: "Do you want to delete this file?",
    Cancel:"Cancel",
    GiveFolderName:"Give a name for your folder",
    Save:"Save",
    Folders:"Folders",
    RenameFolder:'Rename your folder',
    GiveTitle: "Give a title",
    RemoveFolder:'Remove from folder',
    Rename:'Rename',
    FolderNameTaken:"This folder name has already been taken",
    FolderAddSuccess: "Folder added successfully",
    EnterFileName:'Please Enter File Name',
    EnterFolderName:'Please Folder File Name',
    InternalServerError:'Internal Server Error',
    FileUploadSuccess:'Files uploaded successfully',
    },
  Arabic: {
    FileUploadSuccess:'تم تحميل الملفات بنجاح',
    InternalServerError:'خطأ في الخادم الداخلي',
    EnterFolderName:'من فضلك اسم ملف المجلد',
    EnterFileName:'الرجاء إدخال اسم الملف',
    FolderAddSuccess: "تمت إضافة المجلد بنجاح",
    FolderNameTaken:"لقد تم بالفعل أخذ اسم المجلد هذا",
    RemoveFolder:'إزالة من المجلد',
    GiveTitle: "إعطاء عنوان",
    RenameFolder:'إعادة تسمية المجلد الخاص بك',
    Folders:"المجلدات",
    Save:"يحفظ",
    GiveFolderName:"إعطاء اسم للمجلد الخاص بك",
    Cancel:"يلغي",
    FileDeleteWarning: "هل تريد حذف هذا الملف؟",  
    FolderDeleteWarning:'هل أنت متأكد أنك تريد الحذف؟ سيتم حذف المجلد وأي مستندات مخزنة داخل المجلد نهائيًا.',
    NoDocuments:"ليس لديك أي وثائق حتى الآن!",
    AddToFolder:"إضافة إلى المجلد",
    Download:'تحميل',
    View:'منظر',
    AllDocuments:'جميع المستندات',
    Delete:'يمسح',
    Rename:"إعادة تسمية",
    CreateFolder:"إنشاء مجلد جديد",
    SelectFolderAddDocument:"حدد مجلدًا لإضافته إلى مستندك",
  },
}

// Customizable Area End
