import React from "react";

// Customizable Area Start
import {
  Box,ButtonGroup, Button,Typography,Grid, Tooltip as MuiTooltip, MuiThemeProvider, createTheme
} from "@material-ui/core";
import { PieChart, Pie, Cell, ResponsiveContainer, Label, BarChart,CartesianGrid, XAxis, Tooltip, Bar} from 'recharts';
import { imagenav_BackIcon } from "./assets";
import { formatNumberWithAbbreviations, formatNumberWithCommasAndDecimals } from "../../../components/src/commonFn";
import ProgressBar from "../../../components/src/ProgressBar";
import { renderActiveShape, renderInnerLable, customTooltip,
   customYAxisBarChart,customLegend} from "../../../components/src/ChartUtils";
import { styled } from "@material-ui/styles";
import UserProfile from "../../../components/src/UserProfile.web";
import CustomTooltip from "../../../components/src/CustomTooltip/CustomTooltip";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
const theme = createTheme({
  overrides: {
    MuiTooltip: {
      arrow: {
        color: '#000',
      },
      tooltip: {
        backgroundColor: '#000',
      },
    },
  },
});

// Customizable Area End

import ClientAssetsController, {
  Props,
  configJSON,
} from "./ClientAssetsController";

export default class ClientAssets extends ClientAssetsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  renderCurrencyPieChart = () => {
    return (
      <Box style={webStyle.currencyPieChartContainer}>
        <Box style={webStyle.pieChartCointainer}>
          <Typography style={webStyle.chartHeading}>
            {this.state.assetsType === "Country"?  this.getStringNewDashboard("countryExposure") :this.getStringNewDashboard("currencyExposure") }
          </Typography>
          <ButtonGroup disableElevation variant="contained" style={{ borderRadius: "12px", height: '35px' }}>
            <Button
              data-test-id="currency-change"
              onClick={() => { this.changeType('Currency') }}
              style={{
                textTransform: 'none',
                borderRadius: this.state.direction === "ltr" ? "12px 0px 0px 12px" : "0px 12px 12px 0px",
                backgroundColor: this.state.assetsType === 'Currency' ? "#2DE2D5" : '#D8D8D8',
                ...webStyle.pieChartHeaderButton,
              }}
            >
              {this.getStringNewDashboard("currency")}
            </Button>
            <Button
              data-test-id='country-change'
              onClick={() => { this.changeType('Country') }}
              style={{
                textTransform: 'none',
                borderRadius: this.state.direction === "ltr" ? "0px 12px 12px 0px" : "12px 0px 0px 12px",
                backgroundColor: this.state.assetsType === 'Country' ? "#2DE2D5" : '#D8D8D8',
                ...webStyle.pieChartHeaderButton,
              }}
            >
              {this.getStringNewDashboard("country")}
            </Button>
          </ButtonGroup>
        </Box> 
        <ResponsiveContainer height={374} width="100%">
          <PieChart >
            <Tooltip 
            content={<CustomTooltip />}
            />
            <Pie
              data={this.state.currencyPieChartData}
              outerRadius={140}
              innerRadius={115}
              fill="#fff"
              dataKey="value"
              isAnimationActive={false}
              labelLine={false}
              stroke="none" 
              data-test-id="piechartCurrency"
              activeShape={renderActiveShape}
              onMouseEnter={this.onCurrencyPieEnter}
              onMouseLeave={this.onCurrencyPieOut}
              activeIndex={this.state.activeCurrencyIndex}
            >
              {this.state.currencyPieChartData.map((_entry, index) => (
                <Cell key={`cell-${index}`} fill={this.PIE_CURRENCY_COLORS[index % this.PIE_CURRENCY_COLORS.length]} />
              ))}
              <Label value={`${this.state.currencyPieChartInfo.currency_symbol}${formatNumberWithAbbreviations(this.state.currencyPieChartInfo.total_networth+ '')}`} fill="#fff" style={webStyle.labelStyle} position="center"></Label>
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Box>
    )
  }


renderGainAndPercent = () =>{
  if(this.state.financialSummary2?.total_gain != undefined && this.state.financialSummary2?.total_gain_percentage != undefined){
    
    return <>
      {this.state.currencyPieChartInfo.currency_symbol}
      {formatNumberWithCommasAndDecimals(
        this.state.financialSummary2 && this.state.financialSummary2.total_gain !== undefined && this.state.financialSummary2.total_gain <= 0
          ? `${this.state.financialSummary2?.total_gain.toString()}`
          : `+${this.state.financialSummary2?.total_gain.toString()}`
      )}
      &nbsp;|&nbsp;
      {this.state.financialSummary2 && this.state.financialSummary2.total_gain_percentage !== undefined && this.state.financialSummary2.total_gain_percentage <= 0
        ? `${this.state.financialSummary2?.total_gain_percentage}`
        : `+${this.state.financialSummary2?.total_gain_percentage}`
      }%
    </>

  }else{
    return
  }
}

  renderPerformance = () => {
    return (
      <Box style={webStyle.currencyPieChartContainer}>
        <Box style={webStyle.headingCotnainer}>
          <Box>

          <Box style={{ display: "flex", alignItems:'center', gap:'4px' }}>
          <Typography style={webStyle.performanceGraphHeading}>
              {this.getStringNewDashboard("performance")}
          </Typography>
          <MuiTooltip title={this.getStringNewDashboard("clickToViewList")} placement="top" arrow interactive>
            <ErrorOutlineOutlinedIcon style={{ fontSize: '18px', color: '#2DE2D5', cursor: 'pointer' }} data-test-id="btnIconClick" />
          </MuiTooltip>
        </Box>
            <Box style={webStyle.performanceMatrixContainer}>
              <Typography style={webStyle.totalGainText}>
                {this.getStringNewDashboard("totalGain")}
              </Typography><Typography style={webStyle.totalGainPlusText}>
                {this.renderGainAndPercent()}
                </Typography>
            </Box>
          </Box> 
          <Box style={webStyle.filterContainer}>
            <Typography data-test-id="6M" style={this.state.performanceFilter === "6M" ? webStyle.activeStyle : webStyle.inActiveStyle} onClick={() => { this.handleSetPerformanceFilter("6M") }}>
                 {this.getStringNewDashboard("6M")}
            </Typography>
            <Typography  data-test-id="1Y" style={this.state.performanceFilter === "1Y" ? webStyle.activeStyle : webStyle.inActiveStyle} onClick={() => { this.handleSetPerformanceFilter("1Y") }}>
              {this.getStringNewDashboard("1Y")}
            </Typography>
            <Typography data-test-id="YTD" style={this.state.performanceFilter === "YTD" ? webStyle.activeStyle : webStyle.inActiveStyle} onClick={() => { this.handleSetPerformanceFilter("YTD") }}>
            {this.getStringNewDashboard("YTD")}
            </Typography>
          </Box>
        </Box>
        <ResponsiveContainer width="100%" height={600}>
          <BarChart stackOffset="sign"
            data={this.state.performanceData} barSize={80} margin={{ top: 20, right: 30, left: 20, bottom: 5 }} >
            <CartesianGrid stroke="#eee" strokeOpacity={0.5} strokeDasharray="none" vertical={false} />
            <XAxis dataKey="name" tick={{ fill: "rgba(255, 255, 255, 0.5)", fontFamily: "Poppins, sans-serif" }} />
            {customYAxisBarChart(this.state.currencyPieChartInfo.currency_symbol,this.state.direction)}
            <Tooltip content={customTooltip} cursor={{ fill: "transparent" }} />
            {customLegend()}
            {
              this.state.currentLevel.level === 1 ? this.performanceBarData.map((barData)=>{
                return  <Bar key={barData.name} dataKey={barData.name} stackId="a" fill={barData.fill}></Bar>
              }) : this.state.performanceBarData.map((barData, index)=>{
                return  <Bar key={barData} dataKey={barData} stackId="a" fill={this.PIE_CURRENCY_COLORS[index % this.PIE_CURRENCY_COLORS.length]}></Bar>
              })
            }
          </BarChart>
        </ResponsiveContainer>
      </Box>
    )
  }

  renderAllocation = () => {
    return (
      <Box style={webStyle.currencyPieChartContainer}>
        <Box style={{ display: "flex", alignItems:'center', gap:'4px',   }}>
          <Typography style={webStyle.chartHeading}>
            {this.getStringNewDashboard("allocation")}
          </Typography>
          <MuiTooltip title={this.getStringNewDashboard("clickToZoom")} placement="top" arrow interactive>
            <ErrorOutlineOutlinedIcon style={{color: '#2DE2D5', fontSize: '18px', cursor: 'pointer' }} data-test-id="btnIconClick" />
          </MuiTooltip>
        </Box>
        <ResponsiveContainer width="100%" height={375}>
          <PieChart>
            <Tooltip
            content={<CustomTooltip />}
            />
            <Pie
              activeIndex={this.state.activeIndex}
              data={this.state.allocationPieChart}
              innerRadius={115}
              fill="#fff"
              outerRadius={140}
              dataKey="value"
              stroke="none"
              labelLine={false}
              // label={(props) => renderLabel(props, this.state.allocationPieChartPositions,this.state.direction,false)}
              data-test-id="piechartAllocation"
              activeShape={renderActiveShape}
              onMouseEnter={this.onPieEnter}
              onMouseLeave={this.onPieLeave}
              isAnimationActive={false}
              onClick={(data:{
                name:string
              })=>{
                this.onPieChartClick(data.name)
              }}
            >    
              {this.state.allocationPieChart.map((entry, index) => {
                const  fill= this.state.currentLevel.level === 1 ? this.getColor(entry.name) : this.PIE_CURRENCY_COLORS[index % this.PIE_CURRENCY_COLORS.length] 
                return(
                <Cell key={`cell-${index}`} fill={fill} />
              )})}
              <Label
                value={`${this.state.currencyPieChartInfo.currency_symbol}${formatNumberWithAbbreviations(this.state.currencyPieChartInfo.total_networth+ '')}\n ${this.getFundTypeText()}  `}
                fill="#fff"
                style={webStyle.labelText}
                position="center"
                data-test-id="allocationLable"
                content={renderInnerLable}
              />
            </Pie>

          </PieChart>
        </ResponsiveContainer>
        {
          this.state.currentLevel.level === 1 && 
          <MuiThemeProvider theme={theme}>
          <React.Fragment>
              <Box style={{ display: "flex", alignItems:'center', gap:'4px' }}>
            <Typography style={webStyle.diversificationheading}>{this.getStringNewDashboard("score")}</Typography>
            <MuiTooltip title={this.getStringNewDashboard("scoreTooltip")} placement="top" arrow interactive>
                <ErrorOutlineOutlinedIcon style={{ fontSize: '18px', color: '#2DE2D5', cursor: 'pointer' }} data-test-id="btnIconClick" />
              </MuiTooltip>
              </Box>
            <ProgressBar percentage={this.state.allocationPercentage} direction={this.state.direction} />
          </React.Fragment>
          </MuiThemeProvider>
        }
       
      </Box>
    )
  }
  
  renderHeader = () => {
    return (
      <ThemeWrapper>
        <Grid container className="headerContainer">
          <Grid item xs={12} className="headerItem">
            <Box className="profileNameNew">
              <Box onClick={this.handleBack} data-test-id="handle-back" >
                {this.state.currentLevel.level !== 1 && 
                  <img src={imagenav_BackIcon} className="marginImg1" alt="back" />}
              </Box>
              <Box className="planning">{this.state.currentLevel.name}</Box>
            </Box>
            <UserProfile username={this.state.plannerInfo.name} email={this.state.plannerInfo.email} profilePicture={this.state.plannerInfo.image} />
          </Grid>
          <Grid item md={12} className="tabsContainer">
            <Grid container className="clientTabsSpacing">
              <Grid item>
                <Typography className="inActiveTab" data-test-id="details-tab" onClick={() => {
                  this.navigateRoute("ClientDetails")
                }}> Details </Typography>
              </Grid>
              <Grid item>
                <Typography className="activeTab" data-test-id="assets-tab"> Assets </Typography>
              </Grid>
              <Grid item>
                <Typography className="inActiveTab" data-test-id="service-tab" onClick={() => {
                  this.navigateRoute("ClientService")
                }}> Services </Typography>
              </Grid>
              <Grid item>
                <Typography className="inActiveTab" onClick={() => {
                  this.navigateRoute("UploadThing")
                }} data-test-id="vault-tab"> Vault </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ThemeWrapper>
    )
  }

  renderNetworthMatrix = () => {
    if(!this.state.financialSummary){
      return <React.Fragment></React.Fragment>
    }
    return (
      <Box style={{marginTop:"2rem"}} data-test-id="networthTab">
        <Typography style={webStyle.chartHeading}>
          Total Investment
        </Typography>
        <Typography style={webStyle.investmentAmount}>
          {this.state.currencyPieChartInfo.currency_symbol}  {formatNumberWithCommasAndDecimals(this.state.financialSummary.total_investment?.toString())}
        </Typography>
        <Box style={webStyle.investmentMatrixContainer} className="totalGainBox">
          <Grid container spacing={5}>
            <Grid item md={4} />
            <Grid item  >
              <Box style={webStyle.titleContainer} >
                <Typography style={webStyle.investmentTitle}>
                  {this.getStringNewDashboard("totalGain")}
                </Typography>
                <Typography style={this.state.financialSummary.total_gain_percentage< 0 ?webStyle.minusInvestment : webStyle.plusInvestment}>
                {this.state.currencyPieChartInfo.currency_symbol}{formatNumberWithCommasAndDecimals(this.state.financialSummary?.total_gain?.toString())} ({this.state.financialSummary?.total_gain_percentage}%)
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item md={4} />
            <Grid item  >
              <Box style={webStyle.titleContainer} >
                <Typography style={webStyle.investmentTitle}>
                  Monthly gain
                </Typography >
                <Typography style={this.state.financialSummary.monthly_gain_percentage< 0 ?webStyle.minusInvestment : webStyle.plusInvestment}>
                {this.state.currencyPieChartInfo.currency_symbol}{formatNumberWithCommasAndDecimals(this.state.financialSummary.monthly_gain?.toString())} ({this.state.financialSummary.monthly_gain_percentage}%)
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item md={4} />
            <Grid item  >
              <Box style={webStyle.titleContainer} >
                <Typography style={webStyle.investmentTitle}>
                  Yearly gain
                </Typography >
                <Typography style={this.state.financialSummary.yearly_gain_percentage< 0 ?webStyle.minusInvestment : webStyle.plusInvestment}>
                {this.state.currencyPieChartInfo.currency_symbol}{formatNumberWithCommasAndDecimals(this.state.financialSummary.yearly_gain?.toString())} ({this.state.financialSummary.yearly_gain_percentage}%)
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box style={webStyle.overviewContainer} className="clientOverviewBox">
          <Typography style={webStyle.overviewText}>
            Asset count: {this.state.financialSummary.total_asset_count}| M: {(this.state.financialSummary?.monthly_gain_percentage ?? 0) >= 0 
            ? `+${this.state.financialSummary?.monthly_gain_percentage}` : this.state.financialSummary?.monthly_gain_percentage.toString()}%, Y:{(this.state.financialSummary?.yearly_gain_percentage ?? 0) >= 0 
              ? `+${this.state.financialSummary?.yearly_gain_percentage}` : this.state.financialSummary?.yearly_gain_percentage.toString()}%
          </Typography>
        </Box>
      </Box>
    )
  }

  renderIndividualNetworthMatrix = () => {
    return (
      <Box style={{marginTop:"2rem"}} data-test-id="individual-matrix">
        <Box style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: "center" }}>
          <Box className="investment-container">
            <Typography style={webStyle.chartHeading}>
              Total Investment
              </Typography>
              <Typography className="investment-data" style={webStyle.investmentAmount}>
                {this.state.currencyPieChartInfo.currency_symbol}  {formatNumberWithCommasAndDecimals(this.state.currencyPieChartInfo.total_networth?.toString())}
            </Typography>
          </Box>
          <Typography style={webStyle.chartHeading} className="total-assets">
            Total Assets <span style={{ color: "rgb(45, 226, 213)" }}>{this.state.financialSummary2?.total_asset_count || 0}</span>
          </Typography>
        </Box>
       
          <Grid container spacing={2} style={{marginTop:"1rem",marginBottom:"3rem"}}>
            <Grid item xs={12} sm={6} md={6} >
            <Box style={webStyle.individualInvestmentMatrixContainer}>
                <Typography style={webStyle.investmentTitle}>
                  {this.getStringNewDashboard("totalGain")}
                </Typography>
                <Typography style={{marginTop: "13px", ...(this.state.financialSummary2?.total_gain2 ?? 0) >= 0 
      ? webStyle.plusInvestment : webStyle.minusInvestment}}>
                {this.state.currencyPieChartInfo.currency_symbol}
                {formatNumberWithCommasAndDecimals(this.state.financialSummary2?.total_gain2?.toString() as string ?? '0')}
                ({this.state.financialSummary2?.total_gain_percentage2 ?? 0}%)
                </Typography>
              </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} >
              <Box style={webStyle.individualInvestmentMatrixContainer}>
                <Typography style={webStyle.investmentTitle}>
                  Monthly gain
                </Typography>
                <Typography style={{...(this.state.financialSummary2?.monthly_gain ?? 0) >= 0 
      ? webStyle.plusInvestment : webStyle.minusInvestment, marginTop:"13px"}}>
                {this.state.currencyPieChartInfo.currency_symbol}{formatNumberWithCommasAndDecimals(this.state.financialSummary2 ? this.state.financialSummary2.monthly_gain?.toString() : "0")}({this.state.financialSummary2 ? this.state.financialSummary2.monthly_gain_percentage : 0}%)
                </Typography>
              </Box>
              </Grid>
          </Grid>
      
      </Box>
    )
  }

  renderNoPermission = () => {
    return (
      <Box data-test-id="networthTab">
        <Box style={webStyle.noPermissionContainer}>
          <div style={webStyle.noPermissionInnerContent}>
            <div style={webStyle.ctmText}>{!this.state.hasPermission ?"You do not have permission to view assets." : "Access to assets not available yet." }</div>
           {this.state.hasPermission && <div style={webStyle.ctmText}>Try again later.</div> } 
          </div>
        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <StyleWrapper className="pagedashboard-wrapper2">
        <ThemeWrapper>
          <Box className="mainContainer">
            <Box dir={this.state.direction}>
              <Box>
                <Box>
                  {this.renderHeader()}
                  {!this.state.hasPermission ? this.renderNoPermission() : (
                    <>
                      {this.state.currentLevel.level === 1 ? this.renderNetworthMatrix() : this.renderIndividualNetworthMatrix()}
                      <Box style={{ marginBottom: "2rem" }}>
                        {this.renderPerformance()}
                      </Box>
                      <Box style={{ marginBottom: "2rem" }}>
                        {this.renderAllocation()}
                      </Box>
                      {this.renderCurrencyPieChart()}
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </ThemeWrapper>
      </StyleWrapper>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyleWrapper = styled(Box)({
  '& .totalGainBox': {
    padding: '42px',
    '@media (max-width: 500px)': {
      padding: '20px'
    }
  },
  '& .clientOverviewBox': {
    padding: '30px',
    '@media (max-width: 500px)': {
      padding: '20px'
    }
  },

  '& .clientTabsSpacing': {
    gap: '30px',
    flexWrap: 'wrap',
    '@media (max-width: 500px)': {
      gap: '10px'
    }
  },
  "@media screen and (max-width: 575px)": {
    padding: "20px 20px 0"
  }
})

const ThemeWrapper = styled(Box)({
  '& .headerContainer': {
    flexWrap: "wrap",
    background: "#2a2a39"
  },
  '& .headerItem': {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between"
  },
  '& .profileNameNew': {
    display: 'flex',
    alignItems: 'center'
  },
  '& .marginImg1': {
    margin: '0px',
    marginRight: "18px",
    cursor: "pointer"
  },
  '& .planning': {
    fontSize: "28px",
    color: "#fff",
    fontFamily: 'Poppins',
    fontWeight: 500,
  },
  '& .tabsContainer': {
    marginBottom: "24px",
    '@media (max-width: 600px)': {
      marginTop: '20px',
    }
  },
  '& .inActiveTab': {
    fontSize: "22px",
    color: "#2de2d5",
    cursor: "pointer",
    fontFamily: "Poppins"
  },
  '& .activeTab': {
    fontSize: "22px",
    fontWeight: 600,
    color: "#2de2d5",
    cursor: "pointer",
    borderBottom: "1px solid",
    fontFamily: "Poppins"
  },
})
type IStyle = { [key: string]: React.CSSProperties }

const webStyle: IStyle= {
  ctmText: { color: "white", fontSize: '18px' },
  labelStyle: {
    fontWeight:700,
    fontFamily:"Poppins"
  },
  labelText: {
    fontWeight:700,
    fontFamily:"Poppins",
    marginTop:"18px"
  },
  currencyPieChartContainer:{
    padding :"16px",
    background: "#3c3c50",
    borderRadius:"12px",
    marginBottom:"2rem"
  },
  pieChartHeaderButton: {
    color: "#3C3C50",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  pieChartCointainer:{
    display:"flex",
    justifyContent:"space-between",
    flexWrap: "wrap"
  },
  title:{ color: 'white', fontSize: '26px', fontFamily: 'Poppins', fontWeight: 600 },
  headerContainer:{ display: 'flex', justifyContent: 'space-between', alignItems: "center" },
  chartHeading :{fontSize:"24px",color:"#fff",fontFamily:"Poppins",fontWeight:700},
  performanceGraphHeading :{fontSize:"22px",color:"#fff",fontFamily:"Poppins",fontWeight:700},
  accountLogo:{ width: '41px',marginLeft:"12px", height: '41px', cursor: "pointer" },
  diversificationheading:{fontSize:"20px",color:"#fff",fontFamily:"Poppins",fontWeight:500},
  mainContainer:{width:"100%",height:"100vh"},
  filterContainer:{display:"flex",gap:"40px",padding:"12px"},
  headingCotnainer:{display:"flex",alignItems:"center",justifyContent:"space-between",marginBottom:"22px",marginRight:"16px",marginLeft:"16px", flexWrap: "wrap"},
  activeStyle:{ color: '#2ce2d5', fontSize: '20px', fontFamily: 'Poppins', fontWeight: 600,cursor:"pointer" },
  inActiveStyle:{ color: '#fff', fontSize: '20px', fontFamily: 'Poppins', fontWeight: 600,cursor:"pointer" },
  investmentAmount :{fontSize:"26px",color:"#2de2d5",fontFamily:"Poppins",fontWeight:700},
  investmentMatrixContainer:{
    background: "#3c3c50",
    borderRadius:"12px",
    marginBottom:"16px",
    marginTop:"1.5rem"
  },
  noPermissionContainer:{
    padding :"42px",
    background: "#3c3c50",
    borderRadius:"12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "75vh",
    marginBottom:"16px",
  },
  noPermissionInnerContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  investmentTitle:{fontSize:"22px",color:"#fff",opacity:0.7,fontWeight: 500 ,minWidth:"200px",fontFamily:"Poppins"},
  plusInvestment:{fontSize:"22px",color:"#24d4b0",fontWeight: 500 ,fontFamily:"Poppins", wordWrap:'break-word'},
  minusInvestment:{fontSize:"22px",color:"#ee5353",fontWeight: 500 ,fontFamily:"Poppins", wordWrap:'break-word'},
  titleContainer:{display:"flex",alignItems:"center",justifyContent:"space-between", flexWrap: "wrap"},
  overviewText:{fontSize:"22px",color:"#fff",fontWeight: 700 ,fontFamily:"Poppins",textAlign:"center"},
  overviewContainer:{
    background: "#3c3c50",
    borderRadius:"12px",
    marginBottom:"4rem",
  },
  performanceMatrixContainer:{display:"flex",alignItems:"center",gap:"8px",marginTop:"6px"},
  totalGainText: {fontSize:"18px",color:"#fff",fontWeight: 500 ,fontFamily:"Poppins"},
  totalGainPlusText: {fontSize:"18px",color:"#2de2d5",fontWeight: 500 ,fontFamily:"Poppins"},
  backBtn:{ width: '24px', height: '13px', cursor: "pointer" ,marginRight:"14px"},
  individualInvestmentMatrixContainer:{    background: "#3c3c50",  borderRadius:"12px",textAlign:"center", padding :"100px 42px",},
};
// Customizable Area End 