Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "forecastingandbudgeting";
exports.exampleAPiPUTMethod = "PUT";
exports.labelBodyText = "forecastingandbudgeting Body";
exports.Loginsubmittoken = "bx_block_profile/profiles/currency_list";
exports.Loginsubmittokenselect = "bx_block_profile/profiles/add_currency_to_account";
exports.exampleAPiMethodtoken = "GET";
exports.exampleAPiMethodtokenselect = "PUT";
exports.reacttransactions = "bx_block_budgetingforecasting/graph_charts/recent_transactions?page=1";
exports.reacttransactionsget = "GET"
exports.reactspendings = "bx_block_budgetingforecasting/graph_charts/spendings"
exports.reactspendingsget = "GET"
exports.reactMonths = "bx_block_budgetingforecasting/graph_charts/transaction_months"
exports.reactMonthsget = "GET"
exports.reacttransactionsNew = "bx_block_budgetingforecasting/graph_charts/recent_transactions?page=1&language=arab&api=dashboard"
exports.transactionApiEndPoint = "bx_block_budgetingforecasting/graph_charts/filter_transaction"
// bx_block_budgetingforecasting/graph_charts/spendings

exports.btnExampleTitle = "CLICK ME";
exports.languageListForecasting = {
  English: {
    Trends: "Trends",
    Income: "Income",
    Expense: "Expenses",
    Status: 'Status',
    Savings: "Savings",
    SpendingCategory: "Spending Category",
    Source:'Source',
    AllTransactions:"All transactions",
    Category:'Category',
    CurrentAccount:'Current Account',
    Amount:'Amount',
    Date:"Date",
    Time:'Time',
    Save: 'Save',
    Delete:'Delete',
    Cancel:'Cancel',
    DeleteNote: "Are you sure want to delete",
    DeleteSuccess: "Transaction Deleted Successfully.",
    TheTransaction:"the transaction?",
    RecentTransactions: "Recent Transactions",
    nodataAvailable: "No data available",
  },
  Arabic: {
    Category: 'فئة',
    DeleteSuccess: "تم حذف المعاملة بنجاح.",
    Cancel:'يلغي',
    Delete:'يمسح',
    TheTransaction:"الصفقة؟",
    DeleteNote: "هل أنت متأكد أنك تريد الحذف",
    Save: 'يحفظ',
    Time: 'وقت',
    Date: 'تاريخ',
    Amount: 'كمية',
    Source: 'مصدر',
    CurrentAccount: 'الحساب الجاري',
    Status: 'حالة',
    RecentTransactions: "أحدث المعاملات",
    SpendingCategory: "فئة الإنفاق",
    AllTransactions:"كل الحركات المالية",
    Savings: "مدخرات",
    Expense: "مصروف",
    Income: "دخل",
    Trends: "الاتجاهات",
    nodataAvailable: "لا توجد بيانات متاحة",
  }
}
// Customizable Area End