import React, { Component } from 'react';
import { loadStripe, Stripe, StripeCardElement, StripeElements } from '@stripe/stripe-js';
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { Box, Typography } from '@material-ui/core';
import toast from 'react-hot-toast';

// Load your publishable key
const stripePromise = loadStripe('pk_test_51MoKUqLyQFPDlOQ1SgH5l1OY1MUmtKMWzD8AJpFt0uGUdhAYz4X2fcCP6kmA1A5IQib5rLpg5fZeKO0RJXXjDqWV00qeK5nN8C');

// Define text structure
interface Texts {
    cardHolderName: string;
    cardNumber: string;
    expiryDate: string;
    cvc: string;
    pleaseEnterCardName: string;
    pleaseAddCardInfo: string;
    cardNotSupported: string;
    cardElementNotAvailable: string;
    completePurchase: string;
}

interface LanguageTexts {
    English: Texts;
    Arabic: Texts;
}

interface PaymentFormProps {
    stripe: Stripe | null;
    elements: StripeElements | null;
    onTokenGenerated: (token: any) => void;
    buttonName?: string;
}

interface PaymentFormState {
    cardName: string;
    errorMessage: string | null;
    buttonDisable: boolean;
    cardNameError: boolean;
    language: 'English' | 'Arabic';  // Restrict language to valid keys
}

class PaymentForm extends Component<PaymentFormProps, PaymentFormState> {
    constructor(props: PaymentFormProps) {
        super(props);
        this.state = {
            cardName: '',
            errorMessage: null,
            buttonDisable: true,
            cardNameError: false,
            language: 'English', // default language
        };

        this.handlePayment = this.handlePayment.bind(this);
        this.setCardName = this.setCardName.bind(this);
    }

    componentDidMount() {
        // Load language from localStorage and update state
        const language = (localStorage.getItem('language') as 'English' | 'Arabic') || 'English';
        this.setState({ language });
    }

    async handlePayment() {
        if (this.state.cardName === '') {
            this.setState({
                cardNameError: true
            });
            return;
        }
        const { stripe, elements } = this.props;

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            toast.error(this.getText('pleaseAddCardInfo'));
            return;
        }

        const cardNumberElement = elements.getElement(CardNumberElement);
        if (cardNumberElement) {
            const { token, error } = await stripe.createToken(cardNumberElement as unknown as StripeCardElement, {
                name: this.state.cardName, // Optionally include the cardholder's name
            });
            if (error) {
                toast.error(error.message || this.getText('cardNotSupported'));
            } else {
                this.props.onTokenGenerated(token);
            }
        } else {
            toast.error(this.getText('cardElementNotAvailable'));
        }
    }

    setCardName(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ cardName: e.target.value, cardNameError: false });
    }

    // Function to get text based on current language
    getText(key: keyof Texts) {
        const text: LanguageTexts = {
            English: {
                cardHolderName: 'Card holder name',
                cardNumber: 'Card number',
                expiryDate: 'Expiry date',
                cvc: 'CVC',
                pleaseEnterCardName: 'Please enter card holder name',
                pleaseAddCardInfo: 'Please add card info',
                cardNotSupported: 'Your card is not supported.',
                cardElementNotAvailable: 'Card number element is not available.',
                completePurchase: 'Complete Purchase',
            },
            Arabic: {
                cardHolderName: 'اسم حامل البطاقة',
                cardNumber: 'رقم البطاقة',
                expiryDate: 'تاريخ الانتهاء',
                cvc: 'رمز التحقق من البطاقة',
                pleaseEnterCardName: 'يرجى إدخال اسم حامل البطاقة',
                pleaseAddCardInfo: 'يرجى إضافة معلومات البطاقة',
                cardNotSupported: 'البطاقة غير مدعومة.',
                cardElementNotAvailable: 'عنصر رقم البطاقة غير متوفر.',
                completePurchase: 'إكمال الشراء',
            }
        };
        
        return text[this.state.language][key] || key;
    }

    render() {
        const { language } = this.state;
        const cardElementOptions = {
            style: {
                base: {
                    fontSize: '16px',
                    color: 'white',
                    letterSpacing: '0.025em',
                    fontFamily: 'Source Code Pro, monospace',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                    padding: '10px',
                    direction: language === 'Arabic' ? 'rtl' : 'ltr' // Right-to-left for Arabic
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        };

        return (
            <>
                <Box>
                    <Box mb={2}>
                        <Typography style={{
                            color: 'white',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: 300,
                            fontFamily: 'Poppins',
                        }}>{this.getText('cardHolderName')}</Typography>
                        <input
                            type="text"
                            placeholder={this.getText('cardHolderName')}
                            onChange={this.setCardName}
                            style={{
                                width: '100%',
                                height: '56px',
                                padding: '14.5px 14px',
                                borderRadius: '10px',
                                border: '1px solid #2DF1E3',
                                fontFamily: 'Poppins',
                                direction: language === 'Arabic' ? 'rtl' : 'ltr' // RTL input for Arabic
                            }}
                        />
                        {
                            this.state.cardNameError && <Typography style={{
                                color: '#ff2525',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontWeight: 300,
                                fontFamily: 'Poppins',
                            }}>{this.getText('pleaseEnterCardName')}</Typography>
                        }
                    </Box>

                    <Box>
                        <Typography style={{
                            color: 'white',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontWeight: 300,
                            fontFamily: 'Poppins',
                        }}>{this.getText('cardNumber')}</Typography>
                        <Box mb={2} p={2} border="1px solid #2DF1E3" borderRadius="10px">
                            <CardNumberElement options={cardElementOptions} />
                        </Box>
                    </Box>
                    <Box style={
                        {
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            width: '100%'
                        }
                    }>
                        <Box width={'100%'}>
                            <Typography style={{
                                color: 'white',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontWeight: 300,
                                fontFamily: 'Poppins',
                            }}>{this.getText('expiryDate')}</Typography>
                            <Box mb={2} p={2} border="1px solid #2DF1E3" borderRadius="10px">
                                <CardExpiryElement options={cardElementOptions} />
                            </Box>
                        </Box>

                        <Box width={'100%'}>
                            <Typography style={{
                                color: 'white',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontWeight: 300,
                                fontFamily: 'Poppins',
                            }}>{this.getText('cvc')}</Typography>
                            <Box mb={2} p={2} border="1px solid #2DF1E3" borderRadius="10px" >
                                <CardCvcElement options={cardElementOptions} />
                            </Box>
                        </Box>
                    </Box>

                    <button
                        type="button"
                        onClick={this.handlePayment}
                        style={{
                            backgroundColor: '#C9F9F6',
                            display: 'flex',
                            height: '56px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '10px',
                            marginTop: '16px',
                            cursor: 'pointer',
                            width: '100%',
                            color: '#3C3C50',
                            fontStyle: 'normal',
                            fontSize: '16px',
                            fontWeight: 600,
                            fontFamily: 'Poppins',
                            border: 'none'
                        }}
                    >
                        {this.props.buttonName || this.getText('completePurchase')}
                    </button>
                </Box>
            </>
        );
    }
}

interface StripePaymentProps {
    handleToken: (token: any) => void;
    buttonName?: string
}

// Wrap your PaymentForm component with Elements to inject stripe and elements context
class StripePayment extends Component<StripePaymentProps> {
    render() {
        return (
            <Elements stripe={stripePromise}>
                <ElementsConsumer>
                    {({ stripe, elements }) => (
                        <PaymentForm stripe={stripe} elements={elements} onTokenGenerated={this.props.handleToken} buttonName={this.props.buttonName} />
                    )}
                </ElementsConsumer>
            </Elements>
        );
    }
}

export default StripePayment;
