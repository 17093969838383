Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.audienceFollowersAPiEndPoint = "visual_analytics/audience_followers";
exports.userStatisticAPiEndPoint = "visual_analytics/user_statistic";
exports.reactNewApi = "bx_block_budgetingforecasting/graph_charts/recent_transactions?language=arab&api=dashboard"
exports.recentTransApi = 'bx_block_budgetingforecasting/graph_charts/recent_transactions'
exports.apiNewreact = "bx_block_budgetingforecasting/graph_charts/filter_transaction?category=&date=&type=&language=arab&api=dashboard"
exports.exampleAPiMethod = "POST";
exports.getApiMethodGETType = "GET";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.textInputPlaceHolderData = "Enter Text Data";
exports.labelTitleText = "visualanalytics";
exports.labelBodyText = "visualanalytics Body";
exports.labelDataText = "visualanalytics Data";
exports.exampleAPiPostMethod = "POST";
exports.labelBodyTextArabic=["الشهر الماعني", "الأسبوع الماضني", "اليوم"];
exports.lableTextArabic=["دخل", "مصروف"]

// Params
exports.bankAccId = 'bank_account_id'

exports.languageListForecasting = {
  English: {
    Thanlastmonth: "than last month",
    RecentTransactions:"Recent Transactions",
    SpendingCategory:"Spending Category",
    SeeAll:"see all",
    spending:"spending",
    Selectyourbank:"Select your bank",
    TotalExpenses:"Total Expenses",
    Transactions:"Transactions",
    Filter:"Filter",
    Type:"Type",
    Date:"Date",
    Category:"Category",
    Cancel:"Cancel",
    Save:"Save",
    Source:"Source",
    CurrentAccount:"Current Account",
    Amount:"Amount",
    Time:"Time",
    Status:"Status",
    Delete:"Are you sure you want to delete",
    Transaction:"the transaction?",
    DeleteNew:"Delete",
    NoDataAvailable:"No data available",
    Unknown:'Other',
    arabicTargetDate:"Are you sure you want to delete the transaction?"
  },
  Arabic: {
    arabicTargetDate:"إعادة ياو متأكد أنك تريد الحذف المقطع؟",
    DeleteNew:"يمسح",
    Transaction:"الصفقة؟",
    Delete:"هل أنت متأكد أنك تريد حذف",
    NoDataAvailable:"لا توجد بيانات متاحة",
    Unknown:'آخر',
    Status:"حالة",
    Time:"وقت",
    Amount:"كمية",
    CurrentAccount:"الحساب الحالي",
    Source:"مصدر",
    Save:"يحفظ",
    Cancel:"يلغي",
    Category:"فئة",
    Date:"تاريخ",
    Type:"يكتب",
    Filter:"منقي",
    Transactions:"المعاملات",
    TotalExpenses:"المصروفات الكلية",
    Selectyourbank:"اختر البنك الذي تتعامل معه",
    spending:"الإنفاق",
    SeeAll:"اظهار الكل",
    SpendingCategory:"فئة الإنفاق",
    RecentTransactions:"التحويلات الاخيرة",
    Thanlastmonth: "من الشهر الماضي"
  }
}
// Customizable Area End