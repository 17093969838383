import React from "react";

// Customizable Area Start
import '../assets/docOpenerModal.css';
import '../assets/galleryModal.css';
import '../assets/propertyAsset.css';
import '../assets/confirmationModal.css';
import { Select, MenuItem, FormControl, Grid, Box,Menu } from '@material-ui/core'
import { Link } from 'react-router-dom';
// Customizable Area End
import { areaSizeCount, bathCount, bedCount, headereBackIcon, settingicon, vertical3Dots } from "./assets";
import AssetOverviewController, {
  Props,
} from "./AssetOverviewController";
import { addCommas } from "../../../components/src/commonFn";

export default class PropertyAssetsOverview extends AssetOverviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getSelectedCurrency(property_currency_list: any[]) {
    return property_currency_list.length > 0 && property_currency_list.find((currency: any) => currency.id === this.state.currencyList.selection) || null;
  }

  getCurrency() {
    return this.state.currencyList && this.state.currencyList.list && this.state.currencyList.list.length > 0 ? this.state.currencyList.list : [];
  }

  getPropertyDeedFileExtension(docUrl: string) {
    return docUrl && this.getFileExtension(docUrl) || null;
  }

  checkDocTypeImage(fileExtension: any) {
    return fileExtension && ['jpg', 'jpeg', 'png'].includes(fileExtension);
  }

  getFloorAppend(floorNumber: number) {
    if (floorNumber % 100 === 11 || floorNumber % 100 === 12 || floorNumber % 100 === 13) {
      return "th";
    }

    const lastDigit = floorNumber % 10;
    switch (lastDigit) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  fetchPropertyAsssetKeys(data: any) {
    const attributes = data && data.attributes;
    const obj: any = {
      name: '',
      price: '',
      currencySymbol: '',
      galleryImages: '',
      purchaseDate: '',
      model: '',
      status: '',
      location: '',
      aprtmentNo: '',
      floorNo: '',
      bedRoom: '',
      bathRoom: '',
      areaSize: '',
      titleDeeds: [],
      defaultImage: null,
      purchasePrice: '',
      marketPrice: '',
      profit: '',
      profitBool: false,
      shouldShowDefaultImage: false,
    }
    if (attributes) {
      obj.name = attributes.name;
      obj.currencySymbol = attributes.converted_currency_symbol;
      obj.price = `${attributes.converted_currency_symbol}${attributes.price}`;
      obj.galleryImages = attributes.images || [];
      obj.purchaseDate = attributes.purchase_date;
      obj.model = attributes.model;
      obj.status = attributes.status && attributes.status.name;
      obj.location = attributes.location;
      obj.aprtmentNo = attributes.apartment_number && `#${attributes.apartment_number}`;
      obj.floorNo = attributes.floor_number && `${attributes.floor_number}${this.getFloorAppend(attributes.floor_number)} Floor`;
      obj.bedRoom = attributes.bed_rooms;
      obj.bathRoom = attributes.bath_rooms;
      obj.areaSize = attributes.area_size && `${attributes.area_size} sq m`;
      obj.titleDeeds = attributes.title_deeds || [];
      obj.shouldShowDefaultImage = !attributes.images;
      obj.defaultImage = attributes.sub_category && attributes.sub_category.image && attributes.sub_category.image.url
      obj.purchasePrice = attributes.converted_currency_symbol + addCommas(attributes.converted_price?.toString());
      obj.marketPrice = attributes.converted_currency_symbol + addCommas(attributes.converted_market_value?.toString());
      obj.profit = `${attributes.operand}${attributes.converted_currency_symbol}${addCommas(attributes.profit?.toString())} (${Math.round(attributes.profit_percentage)}%)`
      obj.profitBool = attributes.operand == '+'
      return obj;
    }
    return obj;
  }

  renderPropertyDocOpenerModal() {
    const deedExtension = this.getPropertyDeedFileExtension(this.state.docUrl);
    const isImage = this.checkDocTypeImage(deedExtension);
    return (
      <>
        {
          this.state.isDocOpen && <div className="main-doc-opener-wrapper">
            <div className="doc-modal-popup-container" ref={this.docModalRef}>
              {
                isImage ? <img src={this.state.docUrl} width="100%" height="100%" />
                  : <embed
                    type="application/pdf"
                    src={this.state.docUrl}
                    width="100%"
                    height="100%"
                  />
              }
              <button data-test-id="property-share-button" className="property-share-button" onClick={() => this.handleDownloadDoc(this.state.docUrl)}>{this.getString("propertyShare")}</button>
            </div>
          </div>
        }
      </>
    )
  }

  renderPropertyGalleryModal() {
    return <>
      {
        this.state.isGalleryModalOpen && <div className="property-asset-gallery-main-container">
          <div className="property-gallery-modal-popup-container" ref={this.galleryModalRef}>
            <div className="gallery-image-container-property">
              {
                this.state.selectedGallery && this.state.selectedGallery.length > 0 &&
                this.state.selectedGallery.map((imgData: any, index: number) => {
                  return (
                    <div className="gallery-card" key={index}>
                      <img src={imgData.url} alt='gallery image' />
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      }
    </>
  }

  renderPropertyMainImage(galleryImages: any) {
    return (
      <div className={`property-gallery-image-container-main ${galleryImages.length === 1 && 'main-image-border-radius full-width' || ''} ${galleryImages.length > 1 && 'full-width'}`}>
        <img src={galleryImages[0].url} alt='gallery image 1' />
      </div>
    )
  }

  renderPropertyDefaultImage(imgUrl: string, shouldShow: any = false) {
    if (shouldShow) {
      return (
        <div className={`property-gallery-image-container-main property-cursor-pointer-none full-width`}>
          <img src={imgUrl} alt='default gallery image 1' />
        </div>
      )
    }
  }

  renderPropertySubImages(galleryImages: any) {
    if (galleryImages.length > 1) {
      return (
        <div className="property-gallery-image-container-sub ">

          <div className="property-small-image-container border-top-radius">
            <img src={galleryImages[1].url} alt='gallery image 1' />
          </div>

          {
            galleryImages.length > 2 && <div className="property-small-image-container">
              <img src={galleryImages[2].url} alt='gallery image 2' />
            </div>
          }
          {
            galleryImages.length > 3 && <div className="property-small-image-container relative border-bottom-radius">
              <img src={galleryImages[3].url} alt='gallery image 2' />
              {
                galleryImages.length > 4 &&
                <div className="more-image-counter">{`${galleryImages.length - 4}+`}</div>
              }
            </div>
          }
        </div>
      )
    }
  }

  renderPropertyDeleteConfirmationModal() {
    if (this.state.isDeleteConfimationModalOpen && this.state.currentCardId) {
      return (
        <div className="property-modal-background">
          <div className="property-modal-popup-container">
            <div className="property-modal-title">{this.getString("propertyDeleteConfirm")}</div>
            <div className="property-modal-button-container">
              <div className="property-modal-button modal-save" data-test-id='property-popup-cancel-click'
                onClick={() => this.setState({ isDeleteConfimationModalOpen: false })}>
                {this.getString("propertyCancel")}
              </div>

              <div className="property-modal-button modal-discard" data-test-id='property-popup-delete-click'
                onClick={() => {
                  this.setState({ isDeleteConfimationModalOpen: false });
                  this.deletePropertyAssetData(this.state.currentCardId)
                }}>
                {this.getString("propertyDelete")}
              </div>
            </div>

          </div>
        </div>
      )
    }
  }

  getClassforParentGallery(galleryImages: any[], shouldShowDefaultImage: boolean) {
    let classForParent = '';
    return `${classForParent} ${galleryImages.length > 0 && 'property-cursor-pointer'} ${(galleryImages.length === 1 || shouldShowDefaultImage) && 'property-gallery-container-main-single'}`;
  }

  addToggleBoxes(){
    return this.state.propertyFiltersData.length > 0 && this.state.propertyFiltersData.map(filterData => (
      <Grid item>
        <Box
          className={`portable-btn-upload portable-single-btn-upload btn-title-doc black-btn-title filter-btn ${this.state.selectedPropertyFilterId === filterData.attributes.id ? "active" : ""}`}
          data-test-id={`property-filter-${filterData.attributes.id}`}
          onClick={() => this.handleFilterSelect(filterData.attributes.id)}
        >
          {filterData.attributes.name}
        </Box>
      </Grid>
    ))
  }

  getTopRowStyle() {
    return (this.state.direction === "rtl" ? { justifyContent: 'right' } : {})
  }

  // Customizable Area End

  render() {
    const property_currency_list = this.getCurrency();
    const selectedPropertyCurrency = this.getSelectedCurrency(property_currency_list);
    return (
      // Customizable Area Start
      <div className="property-asset-overview-main-container" dir={this.state.direction}>
        <div className="property-asset-overview-container">
          <div className="property-header-wrapper">
            <div className="property-page-title">
              <h4>
                <Link to={'/Dashboard'} className="property-navigation-back-icon" style={this.state.direction === 'rtl' ? {marginRight: 'unset', marginLeft: "14px"} : {}}>
                  <img src={headereBackIcon} style={{transform: this.state.direction == 'rtl' ? "rotate(180deg)" : "rotate(0deg)"}}></img>
                </Link>
                {this.getString("propertyProperty")}</h4>
            </div>
            <div className="property-page-info">
              <div className="property-drop-menu">
                <div>
                  <FormControl className="property-an-custom-select">
                    <Select
                      displayEmpty
                      value={selectedPropertyCurrency ? selectedPropertyCurrency.id : ''}
                      data-test-id="select-change"
                      onChange={async (e: any) => {
                        if (e.target.value) {
                          await this.onAddCurrencyToAccount(e.target.value);
                          setTimeout(async () => {
                            await this.loadPropertyAssetData();
                          }, 500);
                        }
                      }}
                      className="property-an-select-dropdown"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {property_currency_list.length > 0 &&
                        property_currency_list.map((currency: any) => (
                          <MenuItem key={currency.id} value={currency.id} className="property-an-custom-menu-item">
                            {`${currency.symbol} ${currency.currency_type}`}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <Link to="/Account-Detail">
                    <img src={settingicon} alt="setting" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="property-filters">
            <Grid container spacing={4} className="property-filters-container">
              
              {this.addToggleBoxes()}
              <Grid item>
                <Box 
                  className={`portable-btn-upload portable-single-btn-upload btn-title-doc black-btn-title filter-btn ${this.state.selectedPropertyFilterId === 0 ? "active" : ""}`}
                  onClick={() => this.handleFilterSelect(0)}
                  data-test-id={`property-filter-0`}
                >
                  {this.getString("propertyAll")}
                </Box>
              </Grid>
            </Grid>
          </div>
          <div className="property-asset-cards">
            {
              this.state.filteredPropertyAssetData.length > 0 && this.state.filteredPropertyAssetData.map((data: any, index: number) => {
                const { name, galleryImages, aprtmentNo, floorNo, bedRoom, bathRoom,
                  areaSize, titleDeeds, shouldShowDefaultImage, defaultImage, profit, purchasePrice, marketPrice, profitBool } = this.fetchPropertyAsssetKeys(data);
                const getClass = this.getClassforParentGallery(galleryImages, shouldShowDefaultImage)
                return (
                  <div className="property-card" key={data.id}>
                    <div className="property-card-top">
                      <div className="property-card-left-upload">
                        <div className={`property-gallery-container-main ${getClass}`}>
                          {
                            galleryImages.length > 0 &&
                            <div data-test-id={`on-gallery-click${index}`}
                              className="property-gallery-image-container"
                              onClick={() => this.setState({ selectedGallery: galleryImages, isGalleryModalOpen: true, currentCardId: data.id })}>
                              {this.renderPropertyMainImage(galleryImages)}
                              {this.renderPropertySubImages(galleryImages)}
                            </div>
                          }
                          {
                            this.renderPropertyDefaultImage(defaultImage, shouldShowDefaultImage)
                          }
                        </div>

                        <div className="property-btn-container-main">
                          {titleDeeds.length > 0 &&
                            <div data-test-id={`on-share-deed-click${index}`}
                              className="property-single-btn"
                              onClick={() => this.setState({ isDocOpen: true, currentCardId: data.id, docUrl: titleDeeds[0].url })}>
                              <div
                                className="property-btn-upload property-single-btn-upload btn-title-doc property-white-btn-title">
                                {this.getString("propertyShareDeed")}
                              </div>
                            </div> ||
                            <div 
                              onClick={() => this.handleEditProperty(data)}
                              data-test-id={`on-share-deed-click${index}`}
                              className="property-single-btn">
                              <div
                                className="property-btn-upload property-single-btn-upload btn-title-doc property-white-btn-title">
                                {this.getString("propertyUploadDocument")}
                              </div>
                            </div>}

                          <div className="property-single-btn">
                          <a
                            href="https://www.google.com/"
                            target="_blank" 
                            className="portable-btn-upload portable-single-btn-upload btn-title-doc black-btn-title"
                          >
                          {this.getString("propertyInsure")}
                         </a>
                          </div>
                        </div>
                      </div>

                      <div className="property-upper-card-right">
                        <img data-test-id={`property-vertical-icon-click-${index}`}
                          src={vertical3Dots}
                          onClick={(event: React.MouseEvent<HTMLElement>) => this.handleMenuModal(event, data.id)}
                          alt='vertival-dots' />
                        {this.state.isVerticalPopoverOpen && data.id === this.state.currentCardId && (
                          <Menu 
                          keepMounted
                          data-test-id="cash-popup-menu"
                          onClose={this.handleDocumentMenuModalClose}
                          open={Boolean(this.state.anchorEl)}
                          anchorEl={this.state.anchorEl}
                          transformOrigin={{
                            horizontal: 'right',
                            vertical: 'top',
                          }}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          PaperProps={{
                            style: {background:"#395D6B",width:'55px', padding:"0 5px", borderRadius:'8px'}
                          }}
                          id="simple-menu"
                          >
                            <div
                              className="property-edit-option"
                              onClick={() => this.handleEditProperty(data)}
                              data-test-id={`property-edit-${index}`} >
                              {this.getString("propertyEdit")}
                            </div>
                            <div className="property-delete-option"
                              onClick={() => {
                                this.setState({ isVerticalPopoverOpen: false, isDeleteConfimationModalOpen: true, currentCardId: data.id });
                              }}
                              data-test-id={`property-delete-${index}`}>
                              {this.getString("propertyDelete")}
                            </div>
                          </Menu>
                        )}
                      </div>
                    </div>
                    <div className="property-card-bottom">
                      <div className="property-card-detail">
                        <div className='attribute-name'> <span className="propertyNameOverLape">{name}</span></div>
                        <Grid container spacing={2}>
                          <Grid item xs={3} className="property-data-top-row" style={this.getTopRowStyle()}>
                            <div className="sub-tile-bold-section">
                              <img src={bedCount} alt="property area size" />
                            </div>
                            <div className="middlesub-tile-normal-section">{bedRoom}</div>
                          </Grid>
                          <Grid item xs={3} className="property-data-top-row" style={this.getTopRowStyle()}>
                            <div className="sub-tile-bold-section">
                              <img src={bathCount} alt="property area size" />
                            </div>
                            <div className="middlesub-tile-normal-section">{bathRoom}</div>
                          </Grid>
                          <Grid item xs={3} className="property-data-top-row" style={this.getTopRowStyle()}>
                            <div className="sub-tile-bold-section">
                              <img src={areaSizeCount} alt="property area size" />
                            </div>
                            <div className="middlesub-tile-normal-section">{areaSize}</div>
                          </Grid>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={3} className="property-data-last-row">
                            <div className="sub-tile-bold-section">{this.getString("propertyPurchasePrice")}</div>
                            <div style={{color: "#2CE2D5"}} className="sub-tile-normal-section">{purchasePrice}</div>
                          </Grid>
                          <Grid item xs={3} className="property-data-last-row">
                            <div className="sub-tile-bold-section">{this.getString("propertyMarketPrice")}</div>
                            <div style={{color: '#2CE2D5'}} className="sub-tile-normal-section">{marketPrice}</div>
                          </Grid>
                          <Grid item xs={3} className="property-data-last-row">
                            <div className="sub-tile-bold-section">{this.getString("propertyProfit")}</div>
                            <div style={{color: profitBool ? '#2CE2D5' : '#EC5353'}} className="sub-tile-normal-section">{profit}</div>
                          </Grid>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={3} className="property-data-last-row">
                            <div className="sub-tile-bold-section">{this.getString("propertyApartmentNo")}</div>
                            <div className="sub-tile-normal-section" style={{
                              width: '100px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}>{aprtmentNo}</div>
                          </Grid>
                          <Grid item xs={3} className="property-data-last-row">
                            <div className="sub-tile-bold-section">{this.getString("propertyFloorNo")}</div>
                            <div style={this.state.assetSatyle} className="sub-tile-normal-section">{floorNo}</div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                )
              }
              )
            }
            {
              this.state.filteredPropertyAssetData.length == 0 && 
              <Box className="no-asset-box">
                <span>
                  {this.getString("propertyNoassetsavailable")}
                </span>
              </Box>
            }
          </div>
        </div>
        {
          this.renderPropertyGalleryModal()
        }
        {
          this.renderPropertyDocOpenerModal()
        }
        {
          this.renderPropertyDeleteConfirmationModal()
        }
      </div >
      // Customizable Area End
    );
  }
}
