import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import {Legend, Sector, SectorProps, Text, YAxis } from 'recharts';
import { ValueType,NameType } from 'recharts/types/component/DefaultTooltipContent';
import { ContentType } from 'recharts/types/component/Label';
import { ContentType as TooltipContent } from 'recharts/types/component/Tooltip';
import { addCommas } from './commonFn';

interface LabelProps {
  name: string;
  value: number;
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  index: number;
  fill?: string;
  payload?: any;
}
const RADIAN = Math.PI / 180;




export const renderLabel = (props: LabelProps, previousLabels: {
  index:number,
  position:number
}[],direction:string, displayPercentage = true) => {
  const { name, value, cx, cy, innerRadius, outerRadius, midAngle,index } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  let y = cy + radius * Math.sin(-midAngle * RADIAN);
  // Adjust y-position to avoid overlap
  let buffer = 24; // Distance to shift the label if overlap is detected
  let checkIndex= previousLabels.findIndex(data=>data.index === index);
  if(checkIndex === -1){
    previousLabels.forEach((prevY) => {
      if (Math.abs(prevY.position - y) < buffer) {
          y = prevY.position + buffer;
      }
  });
  previousLabels.push({
    index,
    position:y
  });
  }else{
    y = previousLabels[checkIndex].position
  }

  let anchorDirection: "start" | "end" = x > cx  ? 'start' : 'end';
  if(direction === 'rtl'){
    anchorDirection =  anchorDirection === "start" ? "end":"start"
  }
  let formattedName = formatText(name ?? "")
  return (
      <Text x={x} y={y} fill="#fff" textAnchor={anchorDirection} dominantBaseline="central" style={{ fontFamily: 'Poppins',textTransform:"capitalize" }}>
      {displayPercentage ? `${formattedName} (${value}%)` : `${formattedName}`}    
      </Text>
  );
};


export const renderActiveShape = (props:SectorProps) => {
    const { cx, cy, innerRadius=0, outerRadius=0, startAngle, endAngle,
      fill } = props;
  
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius-5}
          outerRadius={outerRadius+5}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  export const renderInnerLable:ContentType = ({value}) => {
    if (value) {
      const lines = value.toString().split('\n');
      return (
        <text fill="#fff" style={{  fontWeight:700,
          fontFamily:"Poppins"}} x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
          {lines.map((line, index) => (
            <tspan key={index} x="50%" dy={`${index * 1.2}em`}>{line}</tspan>
          ))}
        </text>
      );
    }
  };

  export const customTooltip: TooltipContent<ValueType, NameType> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      let direction =payload[0].payload.direction
      return (
        <div style={webstyles.tooltipContainer} dir={direction}>
          <Typography style={webstyles.tooltipLabel}>
            {label}, {payload[0].payload.currency}{" "}
          </Typography>
          {payload.map((entry, index) => (
            <Grid container key={index}>
              <Grid item xs={8}>
                <Box style={webstyles.colorContainer}>
                  <Box
                    style={{
                      height: "16px",
                      background: entry.color,
                      borderRadius: "50%",
                      width: "16px",
                      marginRight: direction === 'rtl' ? 0 : '8px',
                      marginLeft: direction === 'rtl' ? '8px' : 0,
                    }}
                  />
                  <Typography style={webstyles.tooltipNameLabel}>
                    {direction == 'rtl' ? showArabicName(entry.name?.toString().replace(/^(.*\b(asset)\b).*$/, "$1").trim().replace(/\s*asset\s*$/, '') as string) : entry.name?.toString().replace(/^(.*\b(asset)\b).*$/, "$1").trim().replace(/\s*asset\s*$/, '')}
                  </Typography>
                </Box>
              </Grid>
              <Grid
              xs={4}
                item
                style={{width:'100%'}}
              >
                <Typography style={{...webstyles.tooltipNameLabel,textAlign: direction == 'rtl' ? 'left' : 'right', width:'100%', padding: direction === 'rtl' ? '0 0 0 15px':'0 15px 0 0'}}>
                  {entry.value ? Math.floor(Number(entry.value)).toLocaleString() : "0"}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </div>
      );
    }
  
    return null;
  };
  

export const customYAxisBarChart = (currency: string,direction:string) => {
  return (
    <YAxis
      tick={(tickObject) => {
        let fill = tickObject.payload.value >= 0 ? "#2ce2d5" : "#ee5353";
        const formattedValue =
          tickObject.payload.value > 999 || tickObject.payload.value < -999
           ? addCommas(tickObject.payload.value )

            : `${tickObject.payload.value}`;
            let x = tickObject.x;
            let textAnchor = "end"
            if(direction === "rtl"){
              textAnchor= "start";
            }
        return (
          <text
            {...tickObject}
            x={x}
            fill={fill}
            fontFamily="Poppins, sans-serif"
            fontSize={"16px"}
            textAnchor={textAnchor}
          >
            {currency}{formattedValue}
          </text>
        );
      }}
      axisLine={{ stroke: "#2ce2d5" }}
    />
  );
};

export const customLegend = () => {
  
  return (
    <Legend
      align="center"
      content={(props) => {
        return (
          <div style={webstyles.legendContainer}>
            {props.payload &&
              props.payload.map((entry, index) => (
                <div style={webstyles.legendTxtContainer}>
                  <p style={{
                    background: entry.color,
                    height: "24px",
                    width: "24px"
                  }} ></p> <p style={webstyles.legendTxt}>{entry.value?.toString().replace(/^(.*\b(asset)\b).*$/, "$1").trim().replace(/\s*asset\s*$/, '')}</p>
                </div>

              ))}
          </div>
        );
      }}
      margin={{
        right: 14,
      }}
      wrapperStyle={webstyles.wrapperStyle}
      style={webstyles.legendMainStyle}
    />
  );
};
export const formatText = (text:string) =>  {
  // Replace underscores with spaces
  let formattedText = text.replace(/_/g, ' ');
  // Capitalize each word
  formattedText = formattedText.replace(/\b\w/g, char => char.toUpperCase());
  return formattedText;
}



const showArabicName = (assetName: string) => {

  switch (assetName.toLowerCase()) {
    case 'cash':
      return 'نقدي';

    case 'bank accounts':
      return 'حسابات بنكية';

    case 'stocks':
      return 'مخازن';

    case 'property':
      return 'ملكية';

    case 'shares & funds':
      return 'الأسهم والأموال';

    case 'crypto currency':
      return 'عملة مشفرة';

    case 'crypto':
      return 'عملة مشفرة';

    case 'luxury':
      return 'رفاهية';

    case 'pension':
      return 'مَعاش';

    case 'liabilities':
      return 'مسئولية قانونية';

    case 'commodity':
      return 'سلعة';

    case 'commodities':
      return 'سلعة';

    case 'bank':
      return 'بنك';

    default:
      return assetName;
  }
};

type IStyle = { [key: string]: React.CSSProperties };

const webstyles: IStyle = {
  tooltipLabel: {
    color: "#fff",
    fontFamily:"Poppins",
    fontSize:"16px",
    fontWeight:500,
    marginBottom:"14px"
  },
  tooltipNameLabel: {
    color: "#fff",
    fontFamily:"Poppins",
    fontSize:"16px",
    fontWeight:500,
    marginLeft:"12px",    
  },
  tooltipContainer:{
    backgroundColor: "#111",
    opacity: 0.6,
    width: "450px",
    padding: "10px",
    margin:0,
    border: 'none',
    borderRadius:"12px"
  },
  colorContainer:{
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    marginBottom:"12px",
  },
  legendContainer:{
    display: "flex",
    alignItems: "center",
    gap:"18px",
    flexWrap:"wrap",
    justifyContent:"center"
  },
  legendTxtContainer:{
    display: "flex",
    alignItems: "center",
  },
  legendTxt:{
    color: "#fff",
    fontFamily:"Poppins",
    fontSize:"14px",
    marginRight:"12px",    
    marginLeft:"12px",    
  },
  legendMainStyle:{
    fontSize: '14px',
    color: '#fff',
    marginRight: '14px',
  },
  wrapperStyle:{
    paddingLeft: '14px',
    fontFamily: 'Poppins, sans-serif',
  }
}