// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import { Toaster } from 'react-hot-toast';
import "react-modern-calendar-datepicker/lib/DatePicker.css";

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Customisableuserprofiles2 from "../../blocks/customisableuserprofiles2/src/Customisableuserprofiles2";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Elasticsearch2 from "../../blocks/elasticsearch2/src/Elasticsearch2";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Cfdarklightmode from "../../blocks/cfdarklightmode/src/Cfdarklightmode";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth.web";
import Applepayintegration2 from "../../blocks/applepayintegration2/src/Applepayintegration2";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import VisualAnalytics from "../../blocks/visualanalytics/src/budget.web";
import Transactions from "../../blocks/visualanalytics/src/AllTransactions.web";
import spendingCategory from "../../blocks/visualanalytics/src/AllSpendings.web";
import Filters from "../../blocks/visualanalytics/src/filter.web";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import MultipleCurrencySupport from "../../blocks/multiplecurrencysupport/src/MultipleCurrencySupport";
import Automaticformcreation from "../../blocks/automaticformcreation/src/Automaticformcreation";
import Download from "../../blocks/download/src/Download";
import Freetrial from "../../blocks/freetrial/src/Freetrial";
import ChangePlan from "../../blocks/freetrial/src/Changeplan.web";
import Questionbank from "../../blocks/questionbank/src/Questionbank";
import Quetionnaire from "../../blocks/questionbank/src/Quetionnaire.web";
import FinancialQuestion from "../../blocks/questionbank/src/FinancialQuestion";
import Progress from "../../blocks/questionbank/src/Progress.web"
import Apiintegrationstocksshares from "../../blocks/apiintegrationstocksshares/src/Apiintegrationstocksshares";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Cfapiintegrationcrypto from "../../blocks/cfapiintegrationcrypto/src/Cfapiintegrationcrypto";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import Realtimeupdates2 from "../../blocks/realtimeupdates2/src/Realtimeupdates2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Apiintegrationbankaccounts from "../../blocks/apiintegrationbankaccounts/src/ApiIntegrationbankaccountsmain.web";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Settings2 from "../../blocks/settings2/src/Settings2";
import EstatePlanningPreferences from "../../blocks/settings2/src/EstatePlanningPreferences.web";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Applelogin2 from "../../blocks/applelogin2/src/Applelogin2";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Cftruelayerapiintegration2 from "../../blocks/cftruelayerapiintegration2/src/Cftruelayerapiintegration2";
import Cffacialrecognition30 from "../../blocks/cffacialrecognition30/src/Cffacialrecognition30";
import Cflogicalgorithm4 from "../../blocks/cflogicalgorithm4/src/Cflogicalgorithm4";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Loginplanner from "../../blocks/email-account-login/src/LoginPlanner.web"
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP.web";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import PasswordSuccess from "../../blocks/forgot-password/src/passwordSuccess.web"
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import Homepage from "../../blocks/dashboard/src/Homepage.web";
import ScheduleSection from '../../blocks/dashboard/src/ScheduleSection.web'
import Clients from "../../blocks/dashboard/src/Clients.web";
import Cfmatchalgorithm22 from "../../blocks/cfmatchalgorithm22/src/Cfmatchalgorithm22";
import Goalmanagement from "../../blocks/goalmanagement/src/goals.web";
import AddMonthlyGoal from "../../blocks/goalmanagement/src/addMonthlyGoal.web";
import AddOtherGoal from "../../blocks/goalmanagement/src/addOtherGoal.web";
import Deeplinking from "../../blocks/deeplinking/src/Deeplinking";
import TermsConditions from "../../blocks/termsconditions/src/TermsAndConditions.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Forecastingandbudgeting from "../../blocks/forecastingandbudgeting/src/Forecastingandbudgeting";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import CreateAccount from "../../blocks/email-account-registration/src/CreateAccount.web"
import Cfplaidapiintegration2 from "../../blocks/cfplaidapiintegration2/src/Cfplaidapiintegration2";
import Analytics from "../../blocks/analytics/src/Analytics";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Multipageforms2 from "../../blocks/multipageforms2/src/Multipageforms2";
import EmailAccountLogin from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import EmailAccountRegistrationBlockweb from "../../blocks/email-account-registration/src/EmailAccountRegistrationBlock.web"
import SignupAccount from "../../blocks/email-account-registration/src/SignupAccount.web"
import CategoriessubcategoriesWeb from "../../blocks/categoriessubcategories/src/Categoriessubcategories.web";
import PortableAssetweb from "../../blocks/multipageforms2/src/PortableAsset.web";
import AddSharesWeb from "../../blocks/multipageforms2/src/AddShares.web";
import AddCashWeb from "../../blocks/multipageforms2/src/AddCash.web";
import AddPensionweb from "../../blocks/multipageforms2/src/AddPension.web";
import AddPropertyweb from "../../blocks/multipageforms2/src/AddProperty.web";
import Splashscreen from '../../blocks/splashscreen/src/Splashscreen';
import Sidebar from "../../components/src/sidebar.web";
import MyneLanguage from '../../blocks/settings2/src/MyneLanguageScreen.web'
import MyneCurrency from '../../blocks/settings2/src/MyneCurrencyScreen.web';
import MyneSettingsProfile from "../../blocks/settings2/src/MyneSettings.web";
import MyneResetPasswordScreen from "../../blocks/settings2/src/MyneResetPasswordScreen.web";
import MyneDeleteScreen from "../../blocks/settings2/src/MyneDeleteScreen.web";
import MyneBankWalletLoginScreen from '../../blocks/settings2/src/MyneBankWalletScreen.web';
import PortableAssetsOverview from '../../blocks/automaticformcreation/src/PortableAssets.web';
import PropertyAssetsOverview from '../../blocks/automaticformcreation/src/PropertyAssets.web';
import CashAssetsOverview from '../../blocks/automaticformcreation/src/CashAssets.web';
import ShareAssetsOverview from '../../blocks/automaticformcreation/src/ShareAssets.web';
import PensionAssetsOverview from '../../blocks/automaticformcreation/src/PensionAssets.web';
import LiabilityAssets from '../../blocks/cradditionalformsformschangerequests/src/LiabilityAssets.web';
import MainDashboard from "../../blocks/dashboard/src/MainDashboard.web";
import ForecastingandbudgetingTrends from "../../blocks/forecastingandbudgeting/src/ForecastingandbudgetingTrends.web"
import TransactionAndSpendingDetailsPage from "../../blocks/forecastingandbudgeting/src/TransactionAndSpendingDetailsPage.web"
import MainCatalogueWeb from "../../blocks/catalogue/src/MainCatalogue.web"
import Proposalgeneration2 from "../../blocks/proposalgeneration2/src/Proposalgeneration2.web"
import ViewInvestments from "../../blocks/catalogue/src/viewInvestment.web"
import PaymentsFreeTrial from "../../blocks/freetrial/src/PaymentsFreeTrial.web"
import EditPayment from "../../blocks/freetrial/src/EditPayment.web"
import SuccessScreenForPayment from "../../blocks/freetrial/src/SuccessScreen.web"
import ManageSubscriptionController from "../../blocks/customisableusersubscriptions/src/ManageSubscription.web"
import StockSelection from "../../blocks/dashboard/src/stockSelection.web"
import CryptoSelection from "../../blocks/dashboard/src/cryptoSelection.web"
import AddStockTicker from "../../blocks/dashboard/src/addStockTicker.web"
import AddCryptoTicker from "../../blocks/dashboard/src/addCryptoTicker.web"
import Portfolio from "../../blocks/dashboard/src/portfolio.web"
import Wallet from "../../blocks/dashboard/src/wallet.web"
import StockDetails from "../../blocks/dashboard/src/stockDetails.web"
import CryptoDetails from "../../blocks/dashboard/src/cryptoDetails.web"
import Documentation from '../../blocks/documentation/src/Documentation'
import ServicePayment from '../../blocks/formapprovalworkflow/src/ServicePayment.web';
import Document from '../../blocks/documentation/src/Document.web';
import Cradditionalformsformschangerequests from '../../blocks/cradditionalformsformschangerequests/src/Cradditionalformsformschangerequests.web'
import ManualForm from "../../blocks/cradditionalformsformschangerequests/src/ManualForm.web";
import AddLiabilityWeb from "../../blocks/cradditionalformsformschangerequests/src/AddLiability.web";
import LiabilityForm from "../../blocks/cradditionalformsformschangerequests/src/LiabilityForm.web"
import UploadThing from '../../blocks/dashboard/src/uploadThing.web'

import EnterPin from '../../blocks/passcodelock/src/enterPin.web';
import PasscodeLock from "../../blocks/passcodelock/src/PasscodeLock.web"
import SetupPin from "../../blocks/passcodelock/src/setupPin.web"
import ConfirmPin from "../../blocks/passcodelock/src/confirmPIN.web"
import ForgotPin from "../../blocks/passcodelock/src/forgotPin.web"
import ForgotPinOtp from "../../blocks/passcodelock/src/ForgotPinOTP.web"
import SetupNewPin from "../../blocks/passcodelock/src/setupNewPin.web"
import ConfirmNewPin from "../../blocks/passcodelock/src/confirmNewPin.web"
import EstatePlanning from "../../blocks/formapprovalworkflow/src/FormApprovalWorkflow.web"
import AppHeader from "../../components/src/AppHeader.web"
import PlannerSidebar from "../../components/src/plannersidebar.web"
import FinancialPlanner from "../../blocks/formapprovalworkflow/src/Financialplanner.web"
import Appointment from "../../blocks/formapprovalworkflow/src/Appointment.web"
import AppointmentPayment from "../../blocks/formapprovalworkflow/src/AppointmentPayment.web"
import SuccessPayment from "../../blocks/formapprovalworkflow/src/SuccessPayment.web"
import ServicePaymentSuccess from '../../blocks/formapprovalworkflow/src/ServicePaymentSuccess.web'
import RescheduledSuccess from "../../blocks/formapprovalworkflow/src/RescheduledSuccess.web"
import StockSuccess from '../../blocks/dashboard/src/StockSuccess.web';
import Calender from "../../blocks/formapprovalworkflow/src/Calender.web";
import RescheduleCalender from '../../blocks/formapprovalworkflow/src/RescheduleCalender.web';
import NetworthFeature from "../../blocks/landingpage/src/networthFeature.web"
import BudgetWeb from "../../blocks/landingpage/src/Budget.web"
import InvestmentMarketplaceWeb from "../../blocks/landingpage/src/InvestmentMarketplace.web"
import EstatePlanningWeb from "../../blocks/landingpage/src/EstatePlanning.web"
import EngagementRequest from "../../blocks/formapprovalworkflow/src/EngagementRequest.web"
import EngagementServiceProposal from "../../blocks/formapprovalworkflow/src/EngagementServiceProposal.web"

import ModifyBooking from "../../blocks/formapprovalworkflow/src/ModifyBooking.web"
import EstatePlannerDashboard from "../../blocks/formapprovalworkflow/src/EstatePlannerDashboard.web"
import EstatePlannerReview from "../../blocks/formapprovalworkflow/src/EstatePlannerReview.web"
import Flanksapi1 from "../../blocks/flanksapi1/src/Flanksapi1.web"
import ComingSoon from "../../blocks/landingpage/src/ComingSoon.web";
import ClientService from "../../blocks/dashboard/src/ClientService.web"
import ClientDetails from "../../blocks/dashboard/src/ClientDetails.web";
import ClientAssets from "../../blocks/dashboard/src/ClientAssets.web";
import AddCommodity from "../../blocks/cradditionalformsformschangerequests/src/AddCommodity.web"
import CommodityOverview from "../../blocks/cradditionalformsformschangerequests/src/CommodityOverview.web"
import BankOverview from "../../blocks/cradditionalformsformschangerequests/src/BankOverview.web"
import Payment from "../../blocks/dashboard/src/Payment.web";
import EditPaymentDetails from "../../blocks/dashboard/src/EditPaymentDetails.web";
import VideoCall from "../../blocks/groupvideocall/src/VideoCall.web"
import WealthPlannerProfile from "../../blocks/settings2/src/WealthPlannerSettings.web"
import PlannerVideoCall from "../../blocks/dashboard/src/PlannerVideoCall.web"
import EditAccountInfo from "../../blocks/dashboard/src/EditAccountInfo.web";
import EditPlan from "../../blocks/dashboard/src/EditPlan.web";
import NewDashboard from "../../blocks/dashboard/src/NewDashboard.web";
import Chat from "../../blocks/chat/src/Chat.web";
import AppleLoginSucess from "../../blocks/applelogin2/src/AppleLoginSucess.web"
import Messages from "../../blocks/chat/src/ViewChat.web";
import RatingButtonComponent from "../../blocks/formapprovalworkflow/src/RatingButtonComponent.web"
import CallEndForWealthPlanner from "../../blocks/formapprovalworkflow/src/CallEndForWealthPlanner.web"
// import RescheduleCalender from '../../blocks/formapprovalworkflow/src/RescheduleCalender.web';

const routeMap = {
  Customisableuserprofiles2: {
    component: Customisableuserprofiles2,
    path: "/Customisableuserprofiles2"
  },
  NetworthFeature: {
    component: NetworthFeature,
    path: "/NetworthFeature"
  },
  BudgetWeb: {
    component: BudgetWeb,
    path: "/BudgetWeb"
  },
  InvestmentMarketplaceWeb: {
    component: InvestmentMarketplaceWeb,
    path: "/InvestmentMarketplaceWeb"
  },
  EstatePlanningWeb: {
    component: EstatePlanningWeb,
    path: "/EstatePlanningWeb"
  },
  UploadThing: {
    component: UploadThing,
    path: "/UploadThing"
  },
  // Splashscreen:{
  //  component:Splashscreen,
  // path:"/Splashscreen"},
  Proposalgeneration2: {
    component: Proposalgeneration2,
    path: "/Services"
  },

  PlannerProfile:{
    component: WealthPlannerProfile,
    path: "/PlannerProfile"
  },

  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  Elasticsearch2: {
    component: Elasticsearch2,
    path: "/Elasticsearch2"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  Cfdarklightmode: {
    component: Cfdarklightmode,
    path: "/Cfdarklightmode"
  },

  Applepayintegration2: {
    component: Applepayintegration2,
    path: "/Applepayintegration2"
  },
  Contentmanagement3: {
    component: Contentmanagement3,
    path: "/Contentmanagement3"
  },
  LanguageSupport: {
    component: LanguageSupport,
    path: "/LanguageSupport"
  },

  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  MultipleCurrencySupport: {
    component: MultipleCurrencySupport,
    path: "/MultipleCurrencySupport"
  },
  Automaticformcreation: {
    component: Automaticformcreation,
    path: "/Automaticformcreation"
  },
  Download: {
    component: Download,
    path: "/Download"
  },
  Freetrial: {
    component: Freetrial,
    path: "/Freetrial"
  },
  ChangePlan: {
    component: ChangePlan,
    path: "/ChangePlan"
  },
  Apiintegrationstocksshares: {
    component: Apiintegrationstocksshares,
    path: "/Apiintegrationstocksshares"
  },
  Paymentadmin2: {
    component: Paymentadmin2,
    path: "/Paymentadmin2"
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: "/Customisableusersubscriptions"
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: "/SubscriptionDetails"
  },
  Cfapiintegrationcrypto: {
    component: Cfapiintegrationcrypto,
    path: "/Cfapiintegrationcrypto"
  },
  Subscriptionbilling2: {
    component: Subscriptionbilling2,
    path: "/Subscriptionbilling2"
  },
  Realtimeupdates2: {
    component: Realtimeupdates2,
    path: "/Realtimeupdates2"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },

  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  Scheduling: {
    component: Scheduling,
    path: "/Scheduling"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: "/Rolesandpermissions"
  },
  Applelogin2: {
    component: Applelogin2,
    path: "/Applelogin2"
  },
  DocumentOpener: {
    component: DocumentOpener,
    path: "/DocumentOpener"
  }, 
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  Cftruelayerapiintegration2: {
    component: Cftruelayerapiintegration2,
    path: "/Cftruelayerapiintegration2"
  },
  Cffacialrecognition30: {
    component: Cffacialrecognition30,
    path: "/Cffacialrecognition30"
  },
  Cflogicalgorithm4: {
    component: Cflogicalgorithm4,
    path: "/Cflogicalgorithm4"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  Loginplanner: {
    component: Loginplanner,
    path: "/Loginplanner"
  },
  CreateAccount: {
    component: CreateAccount,
    path: "/CreateAccount"
  },
  Homepage: {
    component: Homepage,
    path: "/Homepage"
  },
  CallEndForWealthPlanner: {
    component: CallEndForWealthPlanner,
    path: "/CallEndForWealthPlanner"
  },
  Schedule: {
    component: ScheduleSection,
    path: "/Schedule"
  },
  Clients: {
    component: Clients,
    path: "/Clients"
  },
  Messages: {
    component: Messages,
    path: "/Messages"
  },
  ClientService: {
    component: ClientService,
    path: "/ClientService"
  },
  ClientDetails: {
    component: ClientDetails,
    path: '/ClientDetails'
  },
  ClientAssets: {
    component: ClientAssets,
    path: '/ClientAssets'
  },

  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },



  Cfmatchalgorithm22: {
    component: Cfmatchalgorithm22,
    path: "/Cfmatchalgorithm22"
  },

  Deeplinking: {
    component: Deeplinking,
    path: "/Deeplinking"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  Forecastingandbudgeting: {
    component: Forecastingandbudgeting,
    path: "/Forecastingandbudgeting"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Cfplaidapiintegration2: {
    component: Cfplaidapiintegration2,
    path: "/Cfplaidapiintegration2"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  PhotoLibrary: {
    component: PhotoLibrary,
    path: "/PhotoLibrary"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Multipageforms2: {
    component: Multipageforms2,
    path: "/Multipageforms2"
  },

  Home: {
    component: EmailAccountLogin,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  EmailAccountLogin: {
    component: EmailAccountLogin,
    path: '/EmailAccountLogin'
  },
  EmailAccountRegistrationBlock: {
    component: EmailAccountRegistrationBlockweb,
    path: '/EmailAccountRegistrationBlockweb'
  },
  SignupAccount: {
    component: SignupAccount,
    path: '/SignupAccount'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },

  Questionbank: {
    component: Questionbank,
    path: "/Questionbank"
  },

  Apiintegrationbankaccounts: {
    component: Apiintegrationbankaccounts,
    path: "/Apiintegrationbankaccounts"
  },

  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  PasswordSuccess: {
    component: PasswordSuccess,
    path: "/PasswordSuccess"
  },
  Questionbank: {
    component: Questionbank,
    path: "/Questionbank"
  },

  Apiintegrationbankaccounts: {
    component: Apiintegrationbankaccounts,
    path: "/Apiintegrationbankaccounts"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },

  PaymentsFreeTrial: {
    component: PaymentsFreeTrial,
    path: "/PaymentsFreeTrial/:id"
  },
  Payment: {
    component:Payment,
    path: "/Payment"
  },
  EditPaymentDetails: {
    component:EditPaymentDetails,
    path: "/EditPaymentDetails"
  },
  EditAccountInfo: {
    component:EditAccountInfo,
    path: "/EditAccountInfo"
  },
  AddBankAccounts: {
    component: Apiintegrationbankaccounts,
    path: "/AddBankAccounts"
  },

  Investment: {
    component: ComingSoon,
    path: "/investment"
  },
  Planning: {
    component: ComingSoon,
    path: "/planning"
  },


  PlannerVideoCall:{
    component:PlannerVideoCall,
    path: "/PlannerVideoCall"
  },
  
  VideoCall:{
    component:VideoCall,
    path: "/VideoCall"
  },

  EditPlan:{
    component:EditPlan,
    path: "/EditScheduler"
  },
  AppleLoginSucess: {
    component: AppleLoginSucess,
    path: "/AppleLoginSucess"
  },
 
};

const authRoutes = {
  AddCommodity: {
    component: AddCommodity,
    path: "/AddCommodity"
  },
  EngagementRequest: {
    component: EngagementRequest,
    path: "/EngagementRequest/:id"
  },
  Chat: {
    component: Chat,
    path: "/Chat"
  },
  EngagementServiceProposal: {
    component: EngagementServiceProposal,
    path: "/EngagementServiceProposal"
  },
  Chat: {
    component: Chat,
    path: "/Chat"
  },
  ModifyBooking: {
    component: ModifyBooking,
    path: "/ModifyBooking"
  },
  FinancialPlanner: {
    component: FinancialPlanner,
    path: "/FinancialPlanner"
  },
  Appointment: {
    component: Appointment,
    path: "/Appointment"
  },
  AppointmentPayment: {
    component: AppointmentPayment,
    path: "/AppointmentPayment"
  },
  Calender: {
    component: Calender,
    path: "/Calendar"
  },
  RescheduleCalender: {
    component: RescheduleCalender,
    path: "/RescheduleCalender"
  },
  ConfirmNewPin: {
    component: ConfirmNewPin,
    path: "/ConfirmNewPin"
  },
  EstatePlanning: {
    component: EstatePlanning,
    path: "/EstatePlanning"
  },
  SetupNewPin: {
    component: SetupNewPin,
    path: "/SetupNewPin"
  },
  ForgotPinOtp: {
    component: ForgotPinOtp,
    path: "/ForgotPinOtp"
  },
  ForgotPin: {
    component: ForgotPin,
    path: "/ForgotPin"
  },
  EnterPin: {
    component: EnterPin,
    path: "/EnterPin"
  },
  SetupPin: {
    component: SetupPin,
    path: "/SetupPin"
  },
  ConfirmPin: {
    component: ConfirmPin,
    path: "/ConfirmPin"
  },
  PasscodeLock: {
    component: PasscodeLock,
    path: "/PasscodeLock"
  },
  Documentation: {
    component: Documentation,
    path: '/Documentation'
  },
  ServicePayment: {
    component: ServicePayment,
    path: '/ServicePayment'
  },
  Document: {
    component: Document,
    path: `/Document/:id`
  },
  StockSelection: {
    component: StockSelection,
    path: "/StockSelection"
  },
  CryptoSelection: {
    component: CryptoSelection,
    path: "/CryptoSelection"
  },
  StockDetails: {
    component: StockDetails,
    path: "/StockDetails"
  },
  Quetionnaire: {
    component: Quetionnaire,
    path: "/Quetionnaire"
  },
  FinancialQuestion: {
    component: FinancialQuestion,
    path: "/FinancialQuestion"
  },
  Progress: {
    component: Progress,
    path: "/Progress"
  },
  CryptoDetails: {
    component: CryptoDetails,
    path: "/CryptoDetails"
  },
  Wallet: {
    component: Wallet,
    path: "/Wallet"
  },
  AddStockTicker: {
    component: AddStockTicker,
    path: "/AddStockTicker"
  },
  AddCryptoTicker: {
    component: AddCryptoTicker,
    path: "/AddCryptoTicker"
  },
  PaymentsFreeTrial: {
    component: PaymentsFreeTrial,
    path: "/PaymentsFreeTrial/:id"
  },
  SuccessScreenForPayment: {
    component: SuccessScreenForPayment,
    path: "/SuccessScreenForPayment/:plan"
  },
  SuccessPayment: {
    component: SuccessPayment,
    path: "/SuccessPayment"
  },
  ServicePaymentSuccess: {
    component: ServicePaymentSuccess,
    path: "/ServicePaymentSuccess"
  },
  RescheduledSuccess: {
    component: RescheduledSuccess,
    path: "/RescheduledSuccess"
  },
  StockSuccess: {
    component: StockSuccess,
    path: "/StockSuccess"
  },

  Freetrial: {
    component: Freetrial,
    path: "/Freetrial"
  },
  ChangePlan: {
    component: ChangePlan,
    path: "/ChangePlan"
  },
  ManageSubscriptionController: {
    component: ManageSubscriptionController,
    path: "/ManageSubscriptionController"
  },
  Goalmanagement: {
    component: Goalmanagement,
    path: "/Goalmanagement"
  },
  AddMonthlyGoal: {
    component: AddMonthlyGoal,
    path: "/AddMonthlyGoal"
  },
  AddOtherGoal: {
    component: AddOtherGoal,
    path: "/AddOtherGoal"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Transactions: {
    component: Transactions,
    path: "/Transaction/:navigationBarTitleText?"
  },
  Transactions1: {
    component: Transactions,
    path: "/Transactions"
  },
  spendingCategory: {
    component: spendingCategory,
    path: "/spendingCategory"
  },
  Filters: {
    component: Filters,
    path: "/Filter/:navigationBarTitleText?"
  },
  Filters1: {
    component: Filters,
    path: "/Filters"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  MyneSettingsProfile: {
    component: MyneSettingsProfile,
    path: "/Account-Detail"
  },
  EditPayment: {
    component: EditPayment,
    path: "/EditPayment/:id"
  },
  MyneLanguage: {
    component: MyneLanguage,
    path: "/Language"
  },
  MyneCurrency: {
    component: MyneCurrency,
    path: "/Currency"
  },
  EstatePlanningPreferences: {
    component: EstatePlanningPreferences,
    path: "/EstatePlanningPreferences"
  },
  MyneSettingsResetPassword: {
    component: MyneResetPasswordScreen,
    path: "/ResetPassword"
  },
  MyneSettingsDelete: {
    component: MyneDeleteScreen,
    path: "/DeleteAccount"
  },
  MyneBankWalletLoginScreen: {
    component: MyneBankWalletLoginScreen,
    path: "/BankWallet"
  },
  CategoriessubcategoriesWeb: {
    component: CategoriessubcategoriesWeb,
    path: '/CategoriessubcategoriesWeb'
  },
  PortableAssetweb: {
    component: PortableAssetweb,
    path: '/PortableAssetweb'
  },
  AddSharesWeb: {
    component: AddSharesWeb,
    path: '/AddSharesWeb'
  },
  AddCashWeb: {
    component: AddCashWeb,
    path: '/AddCashWeb'
  },
  AddPensionweb: {
    component: AddPensionweb,
    path: '/AddPensionweb'
  },
  AddPropertyweb: {
    component: AddPropertyweb,
    path: '/AddPropertyweb'
  },
  PortableAssetsOverview: {
    component: PortableAssetsOverview,
    path: "/PortableAssetsDetail"
  },
  PropertyAssetsOverview: {
    component: PropertyAssetsOverview,
    path: "/PropertyAssetsDetail"
  },
  CashAssetsOverview: {
    component: CashAssetsOverview,
    path: "/CashAssetsDetail"
  },
  ShareAssetsOverview: {
    component: ShareAssetsOverview,
    path: "/ShareAssetsDetail"
  },
  PensionAssetsOverview: {
    component: PensionAssetsOverview,
    path: "/PensionAssetsDetail"
  },
  LiabilityAssets: {
    component: LiabilityAssets,
    path: "/LiabilityDetail"
  },

  MainDashboard: {
    component: NewDashboard,
    path: "/MainDashboard"
  },
  ForecastingandbudgetingTrends: {
    component: ForecastingandbudgetingTrends,
    path: "/ForecastingandbudgetingTrends"
  },
  TransactionAndSpendingDetailsPage: {
    component: TransactionAndSpendingDetailsPage,
    path: "/TransactionAndSpendingDetailsPage"
  },
  MainCatalogueWeb: {
    component: MainCatalogueWeb,
    path: "/MainCatalogueWeb"
  },
  ViewInvestments: {
    component: ViewInvestments,
    path: "/ViewInvestments"
  },
  AddBankAccounts: {
    component: Apiintegrationbankaccounts,
    path: "/AddBankAccounts"
  },
  EstatePlannerDashboard: {
    component: EstatePlannerDashboard,
    path: "/EstatePlannerDashboard"
  },
  EstatePlannerReview: {
    component: EstatePlannerReview,
    path: "/EstatePlannerReview"
  },
  Portfolio: {
    component: Flanksapi1,
    path: "/Portfolio"
  },
  Investment: {
    component: ComingSoon,
    path: "/investment"
  },
  Planning: {
    component: ComingSoon,
    path: "/planning"
  },
  Cradditionalformsformschangerequests: {
    component: Cradditionalformsformschangerequests,
    path: "/Cradditionalformsformschangerequests"
  },
  ManualForm: {
    component: ManualForm,
    path: "/ManualForm"
  },
  AddLiabilityWeb: {
    component: AddLiabilityWeb,
    path: "/AddLiability"
  },
  MortgageForm: {
    component: LiabilityForm,
    path: "/MortgageForm"
  },
  PersonalLoanForm: {
    component: LiabilityForm,
    path: "/PersonalLoanForm"
  },
  OtherLoanForm: {
    component: LiabilityForm,
    path: "/OtherLoanForm"
  },
  CommodityOverview: {
    component: CommodityOverview,
    path: "/CommodityOverview"
  },
  BankOverview:{
    component:BankOverview,
    path: "/BankOverview"
  },

  VideoCall:{
    component:VideoCall,
    path: "/VideoCall"
  },
  PlannerProfile:{
    component: WealthPlannerProfile,
    path: "/PlannerProfile"
  },
  RatingButtonComponent: {
    component: RatingButtonComponent,
    path: "/RatingButtonComponent"
  },
  
}
const excludedPaths = [
  '/ForgotPassword',
  '/TermsConditions',
  '/ForgotPasswordOTP',
  '/NewPassword',
  '/PasswordSuccess',
  '/OTPInputAuth',
  '/VideoCall',
  '/ChangePlan',
  '/Freetrial',
  '/PaymentsFreeTrial/:id'
  ];

const wealthPlannerExcludedPaths =[
  '/ForgotPassword',
  '/VideoCall',
  '/ChangePlan',
  '/Freetrial',
  '/PaymentsFreeTrial/:id'
];

const redirectedRoutes = Object.keys(authRoutes)
.filter(key => !excludedPaths.includes(authRoutes[key].path))
.map(key=>({
  name: key,
  path:authRoutes[key].path
}))

const wealthPlannerRedirectedRoutes = Object.keys(routeMap)
.filter(key => !wealthPlannerExcludedPaths.includes(routeMap[key].path))
.map(key=>({
  name: key,
  path:routeMap[key].path
}))


const formatCurrency = (number, symbol) => {
  if (number >= 1000000000) {
    const formattedNumber = (number / 1000000000).toFixed(2) + 'B';
    return `${symbol}${formattedNumber}`;
  } else if (number >= 1000000) {
    const formattedNumber = (number / 1000000).toFixed(2) + 'M';
    return `${symbol}${formattedNumber}`;
  } else if (number >= 1000) {
    const formattedNumber = (number / 1000).toFixed(2) + 'k';
    return `${symbol}${formattedNumber}`;
  } else {
    return `${symbol}${number.toFixed(2)}`;
  }
}
const AuthComponent = (routeMap, assets, debts, networth) => {
  let networthString = networth;
  let assetString = assets;
  let debtString = debts;



  let networth2 = networthString ? formatCurrency(parseFloat(networthString.match(/\d.*$/)[0].replace(/,/g, '')), networth.match(/^\D+/)[0]) : 0;
  let asset2 = assetString ? formatCurrency(parseFloat(assetString.match(/\d.*$/)[0].replace(/,/g, '')), assets.match(/^\D+/)[0]) : 0;
  let debts2 = debtString ? formatCurrency(parseFloat(debtString.match(/\d.*$/)[0].replace(/,/g, '')), debts.match(/^\D+/)[0]) : 0;
  
  return (
    <Switch>
      {(window.location.pathname != "/ConfirmNewPin" && window.location.pathname != "/UploadThing" && window.location.pathname != "/EnterPin" && window.location.pathname != "/SetupNewPin" && window.location.pathname != "/ForgotPinOtp" && window.location.pathname != "/ForgotPin" && window.location.pathname != '/ConfirmPin' && window.location.pathname != '/SetupPin' && !window.location.pathname.includes('/SuccessScreenForPayment') && !window.location.pathname.includes('/SuccessPayment') && !window.location.pathname.includes('/ServicePaymentSuccess') && !window.location.pathname.includes("/PaymentsFreeTrial") && window.location.pathname != "/Freetrial" && window.location.pathname != "/freetrial" && window.location.pathname != "/ChangePlan" && window.location.pathname != "/Progress" && window.location.pathname != "/AddBankAccounts" && window.location.pathname != "/TermsConditions" && window.location.pathname != "/ForgotPassword" && window.location.pathname != "/ForgotPasswordOTP" && window.location.pathname != "/NewPassword") ?
        <div className="sidebar-main-wrapper">

          <Sidebar assets={asset2} debts={debts2} networth={networth2} />

          {WebRoutesGenerator({ routeMap })}
          <div className="main-content-data2">
            <Route exact path="/" render={() => <Redirect to="/Dashboard" />} />
            <Route exact path="/EmailAccountLoginBlock" render={() => <Redirect to="/Dashboard" />} />
            <Route exact path="/EmailAccountLogin" render={() => <Redirect to="/Dashboard" />} />
            <Route exact path="/EmailAccountRegistrationBlockweb" render={() => <Redirect to="/Dashboard" />} />
            <Route exact path="/Apiintegrationbankaccounts" render={() => <Redirect to="/Dashboard" />} />
            {wealthPlannerRedirectedRoutes.map(({path}, index) => (
                <Route key={index} exact path={path} render={() => <Redirect to="/Dashboard" />} />
              ))}
            <Route exact path="/AppleLoginSucess" render={() => <Redirect to="/Dashboard" />} />


          </div>
        </div>
        :
        <>
          {WebRoutesGenerator({ routeMap })}
        </>
      }

    </Switch>
  );
};

const AppLayoutComponents = routeMap => {

  return (
    <Switch>
      {( window.location.pathname == "/landingpage" || window.location.pathname == "/NetworthFeature" || window.location.pathname == "/BudgetWeb" || window.location.pathname == "/InvestmentMarketplaceWeb" || window.location.pathname == "/EstatePlanningWeb")
        ?

        <div className="lending-page-main-container" style={{ overflowX: 'hidden', height: '100%', background: '#3C3C51' }}>
          {[ '/NetworthFeature', '/BudgetWeb', '/InvestmentMarketplaceWeb', '/EstatePlanningWeb'].includes(window.location.pathname) && <AppHeader />}

          {WebRoutesGenerator({ routeMap })}
          <div className="landing-page-content">
            <Route exact path="/dashboard" render={() => <Redirect to="/EmailAccountLogin" />} />
          </div>
        </div>
        :
        <>
          {(window.location.pathname === "/Homepage" || window.location.pathname === "/Schedule" || window.location.pathname === "/EditScheduler" || window.location.pathname === "/Messages" || window.location.pathname === "/UploadThing" || window.location.pathname === "/Clients" || window.location.pathname === "/Services" || window.location.pathname === "/Payment" || window.location.pathname === "/EditPaymentDetails"||  window.location.pathname ==="/EditAccountInfo" || window.location.pathname.toLowerCase().includes("clientservice")||  window.location.pathname.toLowerCase().includes("clientdetails") || window.location.pathname.toLowerCase().includes("clientassets") || window.location.pathname.toLowerCase().includes("plannerprofile") ||window.location.pathname === "/VideoCall")
            ?
            <>
              <div style={{ overflowX: 'hidden', height: '100%', background: '#2a2a39', display: "flex", width: "100%" }} >
                <PlannerSidebar />
                {WebRoutesGenerator({ routeMap })}
                {wealthPlannerRedirectedRoutes.map(({path}, index) => (
                <Route key={index} exact path={path} render={() => <Redirect to="/Loginplanner" />} />
              ))}
              </div>
            </>
            :
            <div>
              {WebRoutesGenerator({ routeMap })}
              {redirectedRoutes.map(({path}, index) => (
                <Route key={index} exact path={path} render={() => <Redirect to="/EmailAccountLogin" />} />
              ))}
            </div>
          }
        </>
      }
    </Switch>
  )
};

const AppLayoutComponentsWealthPlanner = routeMap => {
  return (
    <Switch>
      {(window.location.pathname === "/Homepage" || window.location.pathname === "/Schedule" || window.location.pathname === "/CallEndForWealthPlanner" || window.location.pathname === "/EditScheduler" || window.location.pathname === "/Messages" || window.location.pathname === "/UploadThing" || window.location.pathname === "/Clients" || window.location.pathname === "/Services" || window.location.pathname === "/Payment" || window.location.pathname === "/EditPaymentDetails" || window.location.pathname === "/EditAccountInfo" || window.location.pathname.toLowerCase().includes("clientservice") || window.location.pathname.toLowerCase().includes("clientdetails") || window.location.pathname.toLowerCase().includes("clientassets") || window.location.pathname.toLowerCase().includes("plannerprofile") || window.location.pathname === "/VideoCall")
        ?
        <>
          <div style={{ overflowX: 'hidden', height: '100%', background: '#2a2a39', display: "flex", width: "100%" }} >
            <PlannerSidebar />
            {WebRoutesGenerator({ routeMap })}
            <Route exact path="/Homepage" render={() => <Redirect to="/Homepage" />} />

          </div>
        </>
        :
        <>
            {WebRoutesGenerator({ routeMap })}
            <Route exact path="/" render={() => <Redirect to="/Homepage" />} />
            <Route exact path="/Loginplanner" render={() => <Redirect to="/Homepage" />} />
            <Route exact path="/SignupAccount" render={() => <Redirect to="/Homepage" />} />
            {redirectedRoutes.map(({path}, index) => (
                <Route key={index} exact path={path} render={() => <Redirect to="/Homepage" />} />
              ))}
        </>
      }
    </Switch>
  )
}


class App extends Component {
  state = {
    loggedin: false,
    sidebarVisible: true,
    assets: "$0",
    debts: "$0",
    networth: "$0",
  }

  async componentDidMount() {
    document.addEventListener('isLoggedIn', this.handleLoginEvent);
    document.addEventListener('sidebarPropsUpdated', this.handleSidebarUpdate);


    const assets = localStorage.getItem("assets") || "$0";
    const debts = localStorage.getItem("debts") || "$0";
    const networth = localStorage.getItem("networth") || "$0";

    this.setState({ assets, debts, networth });

}

componentWillUnmount() {
    document.removeEventListener('isLoggedIn', this.handleLoginEvent);
    document.removeEventListener('sidebarPropsUpdated', this.handleSidebarUpdate);
}

handleLoginEvent = ({ detail }) => {
    this.setState({
        loggedin: detail,
        sidebarVisible: true,
    });
};

handleSidebarUpdate = ({ detail }) => {

    const assets = localStorage.getItem("assets") || "$0";
    const debts = localStorage.getItem("debts") || "$0";
    const networth = localStorage.getItem("networth") || "$0";
    this.setState({
        loggedin: detail,
        sidebarVisible: true,
        assets,
        debts,
        networth,
    });

};



  render() {
    const loggedin = window.localStorage.getItem('authToken');
    const loggedinWP = window.localStorage.getItem('auhtToken');

    return (
      <View>
        { !loggedinWP ? ((loggedin || this.state.loggedin) ? AuthComponent(authRoutes, this.state.assets, this.state.debts, this.state.networth)
          : AppLayoutComponents(routeMap)) : AppLayoutComponentsWealthPlanner(routeMap)
        }
        <ModalContainer />
        <Toaster   containerStyle={{zIndex: 99999}} />
      </View>
    );
  }
}

export default App;
