import React from "react";
// Customizable Area Start
import "./style.css";
import {
  refreshicon,
  settingicon,
  subarrowicon,
} from "./assets";
import { LineChart, Line, Tooltip, ResponsiveContainer, YAxis} from 'recharts';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { NavLink } from "react-router-dom";
import {Tooltip as MuiTooltip
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { Box, CircularProgress, styled } from "@material-ui/core";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
import { formatDate, formatDateWithTime } from "../../../components/src/commonFn";
import ToggleSwitch from "../../../components/src/Button/ToggleSwitch";
import SortableItem from "../../../components/src/Dashboard/SortableItem";
export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  CustomTooltip = ({ active, payload, label }:any) => {
    if (active && payload && payload.length) {
      const dataPoint = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p className="custom-tooltip-text">{formatDate(dataPoint.updation_date)}</p>
          <p className="custom-tooltip-text">{dataPoint.symbol}{parseFloat(dataPoint.networth).toLocaleString()}</p>
        </div>
      );
    }
    return null;
  };
  renderCurrencyList:any=()=>{
    return(
    this.state.currencyList && this.state.currencyList.list.map((value:any, index:number)=>{
      return <li style={{textAlign:this.state.direction === "rtl"?"right" : "left"}} key={index} data-test-id={"currency-"+index} onClick={()=>this.selectCurrency(value)}>{value.symbol+value.currency_type}</li>
    }))
}

  renderDir =()=>{
    return this.state.direction === 'rtl' ? 'rtl' : 'ltr'
  }


  getRTLname = (defaultName: string, arabicName: string) => this.state.direction === 'rtl' ? arabicName : defaultName;

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyleWrapper className="pagedashboard-wrapper" onClick={this.handleGlobalDropdown} dir={this.renderDir()}>
        <div className="header-wrapper" style={{marginTop:'30px'}}>
          <div className="page-title">
            <h4>{this.getStringDashboard("Networth")}</h4>
          </div>
          <div className="page-info">
            <ul className="drop-menu">
              <li>
                <div
                  data-test-id="currencyDropdoen"
                  onClick={() => this.handleDropdown()}
                  className={this.state.dropdownVisiblity}
                >
                  <p className="currency2">
                    {this.state.selectedCurrency} <img src={subarrowicon} alt="subarrow" />
                  </p>
                  <ul className="currency2">
                  {this.renderCurrencyList()}
                  </ul>
                </div>
              </li>
              <li>
                <NavLink to="/Account-Detail">
                  <img src={settingicon} alt="setting" />
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="dashboard-graph">
            {
              this.state.isNetWorthGraphLoade ? <div style={{position:'relative', height:'400px',width:'100%'}}>
              <NetworthLoaderContainer>
              <CircularProgress style={{ color: "#fff" }} size={40} color="inherit"/>
              </NetworthLoaderContainer>
            </div>
            : <>
            <div className="networth-text">
              <span className="networth-heading-text">{this.getStringDashboard("TotalNetworth")}</span><br />
              <span className="networth-count-text">{this.state.graphNetworth?.Networth}</span>
            </div>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={this.state.graphNetworth?.networth_graph} margin={{ top: 50, right: 30, left: 20, bottom: 5 }}>
                <YAxis type="number" domain={["auto", "auto" ]} hide />
                <Tooltip content={this.CustomTooltip} />
                <Line type="monotone" dataKey="networth" stroke="#ffffff" strokeWidth={4} dot={false} />
              </LineChart>
            </ResponsiveContainer>
            </>
            }
          </div>
          <div className="month-btns">
            <span
              data-test-id="duration1"
              onClick={() => this.manageDuration("1")}
              className={this.state.duration ==''? "duration" :this.state.duration1}
            >
              1M
            </span>
            <span
              data-test-id="duration2"
              onClick={() => this.manageDuration("3")}
              className={this.state.duration2}
            >
              3M
            </span>
            <span
              data-test-id="duration3"
              onClick={() => this.manageDuration("6")}
              className={this.state.duration3}
            >
              6M
            </span>
            <span
              data-test-id="duration4"
              onClick={() => this.manageDuration("12")}
              className={this.state.duration4}
            >
              1Y
            </span>
          </div>
          <div className="assets-block">
            <div className="assets-block-heading">
              <div className="assets-content">
                <h4>{this.getStringDashboard("AssetsOverview")}</h4>
                <div style={{
               marginLeft: this.state.direction === 'ltr' ? '-20px' : '0px', 
               marginRight: this.state.direction === 'rtl' ? '-20px' : '0px',
           
                }}>
                <MuiTooltip
                  title={this.getStringDashboard('toolTip')}
                  arrow
                  interactive 
                  placement="top"
                  >
                  <ErrorOutlineOutlinedIcon style={{
                    fontSize: '26px',
                    cursor: 'pointer',
                    color: '#2DE2D5',
                  }}
                  data-test-id="btnIconClick" />
                </MuiTooltip>
                  </div>
                {
                  !this.state.isNetWorthGraphLoade && <span>
                  <img
                    src={refreshicon}
                    data-test-id="refreshIconBtn"
                    onClick={()=>this.handleRefresh()}
                    alt="refresh"
                    className="refresh-icon-btn"
                  />{" "}
                  { formatDateWithTime(this.state.lastSyncTime)}
                </span>
                }
              </div>
              <NavLink to="/CategoriessubcategoriesWeb">
                <button
                  className="add-asset-button"
                >
                  <AddIcon className="add-asset-icon" />
                  <p> {this.getStringDashboard("addAssets")}</p>
                </button>
              </NavLink>

            </div>
            {this.state.isLoader ? (
              <LoaderContainer>
              <CircularProgress style={{ color: "#fff" }} size={40} color="inherit"/>
              </LoaderContainer>
            ) : (
                 <div className="assets-block-content">
                  <div style={{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    padding:'12px 0',
                    margin:'12px 0'
                  }}>
                    <p style={{color:'white'}}> {this.getStringDashboard("ViewMyAssets")}</p>
                    <ToggleSwitch checked={this.state.isToggledForViewAssets} handleFunction={this.handleToggle} />
                  </div>

                  {/* istanbul ignore next */}
                  <DndContext
                    collisionDetection={closestCenter}
                    onDragEnd={this.handleDragEnd}
                    modifiers={[restrictToVerticalAxis]}
                  >
                    <SortableContext
                    //  istanbul ignore next
                      items={this.state.assetDataItem.map((item: { id: any; }) => item.id)}
                      strategy={verticalListSortingStrategy}
                    >

                      <div>
                        {this.state.assetDataItem
                          //  istanbul ignore next
                          ?.filter((item: any) => {
                            // istanbul ignore next
                            return this.state.isToggledForViewAssets ? item.count > 0 : true;
                          })
                          .map((item) => (
                            // istanbul ignore next
                            <SortableItem
                              key={item.id}
                              item={item}
                              languageDirection={this.state.direction}
                              getLanguageString={this.getStringDashboard}
                              isExpanded={this.state.expandedItemId === item.id}
                              // istanbul ignore next
                              onToggleExpand={() => {
                                // istanbul ignore next
                                this.handleToggleExpand(item.id)
                              }}
                              timelineListData={this.state.timelineListData}
                              timeLineListLoading={this.state.timeLineListLoading}
                            />
                          ))}
                      </div> 
                    </SortableContext>
                  </DndContext>
            </div>
             )}
          </div>
        </div>
      </StyleWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyleWrapper = styled(Box)({
  "& .faq-heading": {
    flexWrap: "wrap",
  },
  "& .assets-block-heading": {
    flexWrap: "wrap",
    gap: "20px",
  },
  "& .assets-content": {
    flexWrap: "wrap",
  }
})
const LoaderContainer = styled(Box)({
  position: "absolute",
 top: "100%",
 left: "50%",
 transform: "translate(-50%, -50%)"
})
const NetworthLoaderContainer = styled(Box)({
  position: "absolute",
 top: "50%",
 left: "50%",
 transform: "translate(-50%, -50%)"
})
// Customizable Area End
