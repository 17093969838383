import React, { useEffect, useState } from "react";
import "./TimelineList.css";
import TimelineItem from "./TimelineItem";
import { NavLink } from "react-router-dom";
import { cash, CreditCardBlack, logoNew, MortgageImgBlack, OtherBlack, pension, PersonalLoanBlack, shares, commodity1, commodity2, bankIcon, } from "../../../blocks/dashboard/src/assets";
import { formatNumberWithCommasAndDecimals, formatString } from "../commonFn";

interface TimeLineListData {
  name: string;
  items: Array<{ [key: string]: unknown }>;
  href: string
}

interface TimelineListProps {
  isRTL: boolean;
  timelineListData: TimeLineListData;
}

const TimelineList: React.FC<TimelineListProps> = ({ isRTL, timelineListData }) => {
  const renderComponentByName = () => {
    switch (timelineListData.name) {
      case 'Bank':
        return <BankComponent listData={timelineListData} />;
      case 'Cash':
        return <CashComponent listData={timelineListData} />;
      case 'Shares':
        return <SharesComponent listData={timelineListData} />;
      case 'Stocks':
        return <StocksComponent listData={timelineListData} />;
      case 'Crypto':
        return <CryptoComponent listData={timelineListData} />;
      case 'Property':
        return <PropertyComponent listData={timelineListData} />;
      case 'Luxury':
        return <LuxuryComponent listData={timelineListData} />;
      case 'Pension':
        return <PensionComponent listData={timelineListData} />;
      case 'Liability':
        return <LiabilityComponent listData={timelineListData} />;
      case 'Commodity':
        return <CommodityComponent listData={timelineListData} />;
      default:
        return <div>Unknown Component</div>;
    }
  }
  return (
    <div className={`timeline-list-container ${isRTL ? "rtl" : "ltr"}`} dir={isRTL ? "rtl" : "ltr"}>
      {renderComponentByName()}
    </div>
  );
};

export default TimelineList;




interface ComponentProps {
  listData: TimeLineListData;
}
interface ViewDetailsProps {
  href: string;
}



const ViewDetailsButton: React.FC<ViewDetailsProps> = ({ href }) => {
  const [language, setLanguage] = useState<'Arabic' | 'English'>('Arabic');
  useEffect(() => {
    const selectedLanguage = localStorage.getItem('language');
    if (selectedLanguage === 'English' || selectedLanguage === 'Arabic') {
      setLanguage(selectedLanguage as 'Arabic' | 'English');
    } else {
      setLanguage('English');
      localStorage.setItem('language', 'English');
    }
  }, []);
  
  return (
    <NavLink className="view-more" to={href}>{language === 'English' ? "View details" : "عرض التفاصيل" }</NavLink>
  )
};
const BankComponent: React.FC<ComponentProps> = ({ listData }) => {
  return (
    <>
      <div className="timeline">
        {listData?.items.map((item: any, index: number) => (
          <TimelineItem key={index}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
              <img src={item.connection_type === "manual" ? bankIcon : item.image} alt={item.name} width={45} height={42} />
              <div>
                <p className="item-title">{formatString(item.name)} {item.account_number}</p>
                <p className="item-value">{item.converted_symbol} {formatNumberWithCommasAndDecimals(item.converted_balance.toString())}</p>
              </div>
            </div>
          </TimelineItem>
        ))}
      </div>
      <ViewDetailsButton href={listData.href} />
    </>
  )
};

const CashComponent: React.FC<ComponentProps> = ({ listData }) => {
  return (
    <>
      <div className="timeline">
        {listData?.items.map((item: any, index: number) => (
          <TimelineItem key={index}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
              <img src={cash} alt={item?.attributes?.name} width={45} height={42} />
              <div>
                <p className="item-title">{formatString(item?.attributes?.name)}</p>
                <p className="item-value">{item?.attributes?.converted_currency_symbol} {formatNumberWithCommasAndDecimals(item?.attributes?.converted_amount)}</p>
              </div>
            </div>
          </TimelineItem>
        ))}
      </div>
      <ViewDetailsButton href={listData.href} />
    </>
  )
};

const SharesComponent: React.FC<ComponentProps> = ({ listData }) => {
  const [language, setLanguage] = useState<'Arabic' | 'English'>('Arabic');
  useEffect(() => {
    const selectedLanguage = localStorage.getItem('language');
    if (selectedLanguage === 'English' || selectedLanguage === 'Arabic') {
      setLanguage(selectedLanguage as 'Arabic' | 'English');
    } else {
      setLanguage('English');
      localStorage.setItem('language', 'English');
    }
  }, []);
  return (
    <>
      <div className="timeline">
        {listData?.items.map((item: any, index: number) => (
          <TimelineItem key={index}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
              <img src={shares} alt={item?.attributes?.name} width={45} height={42} />
              <div>
                <p className="item-title">{formatString(item?.attributes?.name)}</p>
                <p className="item-value">{item?.attributes?.converted_currency_symbol} {formatNumberWithCommasAndDecimals((Number(item.attributes.converted_new_price) * item.attributes.number_of_shares).toString())}</p>
                {parseFloat(item.attributes.converted_new_price) && item.attributes.profit_percent ? (
                  <p style={{lineHeight:1}} className={item?.attributes?.operand === '+' ? 'plusValue' : 'minusValue'}>{item?.attributes?.operand} {item?.attributes?.profit} ({item?.attributes?.profit_percent}%)</p>
                ) : (
                  <></>
                )}
                <p style={{ color: 'rgb(48, 139, 238)', fontSize: '12px' }}>{item?.attributes?.number_of_shares} {language === 'English' ? "Shares" : "الاسهم" }</p>
              </div>
            </div>
          </TimelineItem>
        ))}
      </div>
      <ViewDetailsButton href={listData.href} />
    </>
  )
};


const StocksComponent: React.FC<ComponentProps> = ({ listData }) => {
  return (
    <>
      <div className="timeline">
        {listData?.items.map((item: any, index: number) => (
          <TimelineItem key={index}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
              <img src={item.icon_url ? item.icon_url : logoNew} alt={item?.full_name} width={45} height={42} />
              <div>
                <p className="item-title">{formatString(item?.full_name)}</p>
                <p className="item-value">{item?.symbol} {formatNumberWithCommasAndDecimals(item?.price)}</p>
                <p className={item?.operand === '+' ? 'plusValue' : 'minusValue'}>{item?.operand} {formatNumberWithCommasAndDecimals(item?.difference)} ({item?.operand}{parseFloat(item.percentage).toFixed(2)}%)</p>
              </div>
            </div>
          </TimelineItem>
        ))}
      </div>
      <ViewDetailsButton href={listData.href} />
    </>
  )
};
const CryptoComponent: React.FC<ComponentProps> = ({ listData }) => {
  return (
    <>
      <div className="timeline">
        {listData?.items.map((item: any, index: number) => (
          <TimelineItem key={index}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
              <img src={item.icon_url ? item.icon_url : logoNew} alt={item?.full_name} width={45} height={42} />
              <div>
                <p className="item-title">{formatString(item?.full_name)}</p>
                <p className="item-value">{item?.symbol} {formatNumberWithCommasAndDecimals(item?.price)}</p>
                <p className={item?.operand === '+' ? 'plusValue' : 'minusValue'}>{item?.operand} {parseFloat(item.difference).toFixed(2)} ({item?.operand}{parseFloat(item.percentage).toFixed(2)}%)</p>
              </div>
            </div>
          </TimelineItem>
        ))}
      </div>
      <ViewDetailsButton href={listData.href} />
    </>
  )
};

const getImage = (data: any) => {
  const { attributes } = data;
  if (attributes) {
    const subcatImg = attributes.sub_category && attributes.sub_category.image && attributes.sub_category.image.url;
    const imgUploadedUrl = attributes.images && attributes.images.length > 0 && attributes.images[0].url;
    return !attributes.images ? subcatImg : imgUploadedUrl;
  }
  return '';
}

const PropertyComponent: React.FC<ComponentProps> = ({ listData }) => {
  return (
    <>
      <div className="timeline">
        {listData?.items.map((item: any, index: number) => (
          <TimelineItem key={index}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
              <img src={getImage(item)} alt={item?.attributes?.name} width={45} height={42} />
              <div>
                <p className="item-title">{formatString(item?.attributes?.name)}</p>
                <p className="item-value">{item?.attributes?.converted_currency_symbol} {formatNumberWithCommasAndDecimals(item?.attributes?.market_value)}</p>
                <p className={item?.attributes?.operand === '+' ? 'plusValue' : 'minusValue'}>{item?.attributes?.operand} {formatNumberWithCommasAndDecimals(item?.attributes?.profit)} ({item?.attributes?.operand}{parseFloat(item.attributes.profit_percentage).toFixed(2)}%)</p>
              </div>
            </div>
          </TimelineItem>
        ))}
      </div>
      <ViewDetailsButton href={listData.href} />
    </>
  )
};

const LuxuryComponent: React.FC<ComponentProps> = ({ listData }) => {
  return (
    <>
      <div className="timeline">
        {listData?.items.map((item: any, index: number) => (
          <TimelineItem key={index}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
              <img src={getImage(item)} alt={item?.attributes?.name} width={45} height={42} />
              <div>
                <p className="item-title">{formatString(item?.attributes?.name)}</p>
                <p className="item-value">{item?.attributes?.converted_currency_symbol} {formatNumberWithCommasAndDecimals(item?.attributes?.converted_market_value)}</p>
                <p className={item?.attributes?.operand === '+' ? 'plusValue' : 'minusValue'}>{item.attributes.operand + 
     item.attributes.converted_currency_symbol + 
     formatNumberWithCommasAndDecimals(Math.abs(parseFloat(item.attributes.converted_market_value) - (parseFloat(item.attributes.converted_price))).toFixed(2)) + 
     ` (${item.attributes.operand}${parseFloat(item.attributes.profit_percentage).toFixed(2)}%)`}</p>
              </div>
            </div>
          </TimelineItem>
        ))}
      </div>
      <ViewDetailsButton href={listData.href} />
    </>
  )
};
const PensionComponent: React.FC<ComponentProps> = ({ listData }) => {
  return (
    <>
      <div className="timeline">
        {listData?.items.map((item: any, index: number) => (
          <TimelineItem key={index}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
              <img src={pension} alt={item?.attributes?.name} width={45} height={42} />
              <div>
                <p className="item-title">{formatString(item?.attributes?.name)}</p>
                <p className="item-value">{item?.attributes?.converted_currency_symbol} {formatNumberWithCommasAndDecimals(item?.attributes?.converted_amount)}</p>
              </div>
            </div>
          </TimelineItem>
        ))}
      </div>
      <ViewDetailsButton href={listData.href} />
    </>
  )
};

const getLiabilityImg = (type: string) => {
  if (type === "mortgage") {
    return MortgageImgBlack
  }
  if (type === "personal_loans") {
    return PersonalLoanBlack
  }
  if (type === "credit_card") {
    return CreditCardBlack
  }
  return OtherBlack
}
const LiabilityComponent: React.FC<ComponentProps> = ({ listData }) => {
  return (
    <>
      <div className="timeline">
        {listData?.items.map((item: any, index: number) => (
          <TimelineItem key={index}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
              <div style={{
                height: "45px",
                width: "42px",
                borderRadius: "8px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                background: "#fff"
              }}>
                <img
                  src={getLiabilityImg(item.form_type)}
                  alt="pension"
                  style={{ width: '100%', height: 'auto', maxWidth: '28px', maxHeight: '28px' }}
                />
              </div>
              <div>
                <p className="item-title">{formatString(item?.name)}</p>
                <p className="item-value" style={{ color: "rgb(226,81,83)" }}>- {item?.converted_currency_symbol}{formatNumberWithCommasAndDecimals(Math.round(item.remaining_payment))}</p>
              </div>
            </div>
          </TimelineItem>
        ))}
      </div>
      <ViewDetailsButton href={listData.href} />
    </>
  )
};

const CommodityComponent: React.FC<ComponentProps> = ({ listData }) => {
  return (
    <>
      <div className="timeline">
        {listData?.items.map((item: any, index: number) => (
          <TimelineItem key={index}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
              <img src={item.attributes.commodity_type === "other" ? commodity1 : commodity2} alt={item?.attributes?.name} width={45} height={42} />
              <div>
                <p className="item-title">{formatString(item?.attributes?.name)}</p>
                <p className="item-title">{formatNumberWithCommasAndDecimals(item?.attributes?.quantity)}</p>
                <p className="item-value">{item?.attributes?.converted_currency_symbol} {formatNumberWithCommasAndDecimals(item?.attributes?.converted_amount)} </p>
              </div>
            </div>
          </TimelineItem>
        ))}
      </div>
      <ViewDetailsButton href={listData.href} />
    </>
  )
};
