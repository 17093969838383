import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
} from "@material-ui/core";
import { styled } from '@material-ui/styles';
import { openIcon, PlusIcon } from "./assets"
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Rating from '@material-ui/lab/Rating';
import StarIcon from '@material-ui/icons/Star';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Grid from '@material-ui/core/Grid';
import EngagementRequestwebController, {
    Props
} from "./EngagementRequestController.web";
import ReminderSnackbar from "../../../components/src/Snackbar.web"
import { convertISOTo12HourFormat, getFirstCharacterFromEachWord } from "../../../components/src/commonFn";
import './planner.css';
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export default class EngagementRequestweb extends EngagementRequestwebController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    showDataNone = () => this.state.show ? '' : 'none';
    showMoreData = (value: string) => this.state.show === value ? this.getStringEngagementRequest("Less") : this.getStringEngagementRequest("More");
    showNameData = () => {
        return (
            <>
                {this.state.showName ? this.state.EngagementRequest.attributes.email : this.maskEmail()}&nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.showName ? (
                    < VisibilityOutlinedIcon data-test-id="handleIconClick1New" style={webStyle.handleClickMargin} onClick={(event) => this.handleIconClick(event)} />
                ) : (
                    <VisibilityOffOutlinedIcon style={webStyle.handleClickMargin} onClick={(event) => this.handleIconClick(event)} data-test-id="handleIconClick2New" />
                )}
            </>
        )
    }


    isUpIcon = () => {
        return this.state.EngagementRequest.attributes?.total_reviews ?
            <IconButton data-test-id="handleClickNewData" onClick={(event) => { this.handleClick(event) }}>
                {this.state.isUp ? <ExpandLessRoundedIcon style={webStyle.expandMoreRounded} /> : <ExpandMoreRoundedIcon style={webStyle.expandMoreRounded} />}
            </IconButton> :
            null
    }
    isUpData = () => this.state.isUp === false ? "" : "none";

    average_rating_Data_Value = () => {
        return this.state.EngagementRequest.attributes?.average_rating ?
            <>
                <Typography style={webStyle.average_rating_fontSize} className="">{this.state.EngagementRequest.attributes?.average_rating}</Typography>&nbsp;&nbsp;
                <Box style={webStyle.fontSizeText} className="">
                    <Rating name="size-small" emptyIcon={<StarIcon fontSize="inherit" style={webStyle.stareColor} />} value={this.state.EngagementRequest.attributes?.average_rating} readOnly precision={0.5} size="small" />
                </Box>
            </> :
            null
    }

    total_reviews_reviews = () => {
        return this.state.EngagementRequest.attributes?.total_reviews ? `${this.getStringEngagementRequest("basedoutof")} ${this.state.EngagementRequest.attributes?.total_reviews} ${this.getStringEngagementRequest("reviews")}` : this.getStringEngagementRequest("noReviews")
    }
    acceptedRequest = () => {
        return this.state.EngagmentReject.status === "accepted" && this.state.permission === null ? (
            <Box style={webStyle.succsecrequest}>
                {this.getStringEngagementRequest("allowAccessDesc")}
            </Box>
        ) : null;
    };
    rejectedRequest = () => {
        return this.state.EngagmentReject.status === "rejected" ? (
            <Box style={webStyle.rejectedrequest}>
                {this.getStringEngagementRequest("rejectRequestDesc")}
            </Box>
        ) : null;
    };

    renderheader = (serviceSatus: boolean, engagmentReject: string) => {
        return serviceSatus === true ?
            <>
                <Box style={webStyle.newService}>
                    <Typography style={{ fontFamily: 'Poppins' }}>
                        {this.getStringEngagementRequest("addServiceSuccess")}
                    </Typography>
                    {/* <a >View Services</a> */}
                    <Typography
                        style={{ textDecoration: 'underline', color: '#3D87A5', fontWeight: 600, cursor: 'pointer', fontFamily: "Poppins" }}
                        onClick={() => this.handleClickNav()}>{this.getStringEngagementRequest("ViewServices")}</Typography>
                </Box>

            </>
            :
            <>
                {
                    engagmentReject === "pending" && (
                        <>
                            <Box style={webStyle.listLine}>{this.getStringEngagementRequest("requestSentDesc")}</Box>
                            <Box style={webStyle.newServiceBtn}>
                                <img style={webStyle.BtnImg} src={PlusIcon} alt="" />
                                <Typography style={webStyle.btnLabel} data-test-id="start-service" onClick={() => {
                                    this.startNewService()
                                }}> {this.getStringTransactions('StartNewService')}</Typography>
                            </Box>
                        </>
                    )
                }
                {
                    engagmentReject === "accepted" ? this.acceptedRequest() : this.rejectedRequest()
                }
            </>
    }

    rtlCondition = (isRtl: string, notRtl: string) => {
        return this.state.direction === 'rtl' ? isRtl : notRtl;
    }

    render() {
        const { serviceSatus } = this.state;

        return (
            // Customizable Area Start
            <>
                <BackgroundBoxSatyle dir={this.state.direction}>
                    <CnvestmentBox>
                        <Box>
                            <Box style={webStyle.profileNameNewIcon}>
                                <Box style={webStyle.profileNameNew}>
                                    <Box style={webStyle.planning}>{this.getStringEngagementRequest("EstatePlanning")}</Box>
                                </Box>
                            </Box>
                            {this.renderheader(this.state.EngagmentReject.new_service, this.state.EngagmentReject.status)}
                            <Box>
                                <Box style={webStyle.webStyleMenBox} className="webStyleMenBox">
                                    <Box style={webStyle.planningBackground}>
                                        <Box className="planner-content">
                                            <Box style={{
                                                display: 'flex',
                                                gap: '10px'
                                            }}>
                                               {this.state.EngagementRequest.attributes?.image ?
                                                    <img src={this.state.EngagementRequest.attributes.image} width="80px" height="80px" style={{borderRadius:'15px', objectFit:'cover'}}/>
                                                    :
                                                    <div style={webStyle.noImg as React.CSSProperties}>
                                                        {getFirstCharacterFromEachWord(this.state.EngagementRequest.attributes?.name)}
                                                    </div>
                                                }
                                                <Box>
                                                    <TextLable>{this.state.EngagementRequest.attributes?.name}</TextLable>
                                                    <Box className="" style={webStyle.textlabel_NameOfCorporation}>{this.getStringEngagementRequest("NameofCorporation")}: &nbsp;<Textlabel_LosAngeles>{this.state.EngagementRequest.attributes?.firm}</Textlabel_LosAngeles></Box>
                                                    <Box className="textlabel_NameOfCorporation" style={webStyle.textlabel_NameOfCorporation}>{this.getStringEngagementRequest("Location")}:&nbsp;<Textlabel_LosAngeles>{this.state.EngagementRequest.attributes?.location}</Textlabel_LosAngeles></Box>
                                                    <Box style={webStyle.textlabel_NameOfCorporation}>{this.getStringEngagementRequest("JurisdictionAdvise")}:&nbsp;<Textlabel_LosAngeles>{this.state.EngagementRequest.attributes?.jurisdiction_advice}</Textlabel_LosAngeles></Box>
                                                </Box>

                                            </Box>

                                            <Box style={{ transform: 'translateY(1.5rem)' }}>
                                                <Box style={webStyle.textlabel_NameOfCorporation}>{this.getStringEngagementRequest("Experience")}:&nbsp;<Textlabel_LosAngeles>{this.state.EngagementRequest.attributes?.experience} {this.getExperienceLabel()}</Textlabel_LosAngeles></Box>
                                                <Box className="" style={webStyle.profileNameNew}>
                                                    <Box style={{ color: 'white' }} className="">{this.state.EngagementRequest.attributes?.average_rating}</Box>&nbsp;&nbsp;
                                                    <Box style={{ color: 'white' }} className="">
                                                        {this.state.EngagementRequest.attributes?.average_rating ? <Rating name="size-small" style={{ fontSize: '1.3rem' }} value={this.state.EngagementRequest.attributes?.average_rating} readOnly precision={0.5} size="small" emptyIcon={<StarIcon fontSize="inherit" style={webStyle.stareColor} />} /> : null}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        {
                                            this.state.EngagementRequest.attributes?.slot && !this.state.istimeexceeded ?
                                                <Box style={{ marginTop: '20px' }} id="ongoing-btns">

                                                    <Box style={{ display: "flex", flexWrap: "wrap", gap: "10px"}}>
                                                        <span style={webStyle.chatBtn} id="ongoing-btns-chat" data-testId="chatTestId" onClick={this.getConversationChat}>{this.getStringEngagementRequest("Chat")}</span>
                                                        <span style={{...webStyle.ScheduleCallBtn, [this.rtlCondition("marginRight", "marginLeft")]: "15px"}} id="ongoing-btns-call" data-test-id="videobutton" onClick={() => { this.gotoVideo2() }}> {this.state.buttonTextt} </span>
                                                    </Box>

                                                    <Box style={{ ...webStyle.marginTopBox, width: "77%" }} id="ongoing-btns-notification">
                                                        <Typography style={webStyle.timeMsg} id="ongoing-btns-msg">{this.getStringEngagementRequest("callScheduleAt")} {this.state.EngagementRequest.attributes?.slot}, {convertISOTo12HourFormat(this.state.EngagementRequest.attributes?.start_time)}</Typography>
                                                        <Box style={{ ...webStyle.modifyyBtn, textAlign: 'center' }} id="ongoing-btns-booking" onClick={() => this.goToModifyBooking()}>{this.getStringEngagementRequest("ModifyBooking")}</Box>

                                                    </Box>

                                                </Box> : <>
                                                    {
                                                        this.state.EngagmentReject.status === "accepted" && this.state.permission !== null &&
                                                        <Box style={{ marginTop: '20px' }} id="ongoing-btns">
                                                            <Box style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                                                                <span style={webStyle.chatBtn} id="ongoing-btns-chat" data-testId="chatTestId" onClick={() => this.getConversationChat()}>{this.getStringEngagementRequest("Chat")}</span>
                                                                <span style={{...webStyle.ScheduleCallBtn, [this.rtlCondition("marginRight", "marginLeft")]: "15px"}} id="ongoing-btns-call" onClick={() =>this.goToAppointmentScreen()} data-test-id="scheduleCall">{this.getStringEngagementRequest("ScheduleCall")}</span>
                                                            </Box>
                                                        </Box>


                                                    }
                                                </>
                                        }



                                        <Box>
                                            <Box className="focusLable" style={webStyle.focusLable}>{this.getStringEngagementRequest("Focus")}:</Box>
                                            <MinBoxDisplayNew style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="minBoxDisplayNew">
                                                <div>
                                                    {this.state.EngagementRequest.attributes?.focus.map((valueButton: any, index: number) => { return <ButtonStyle style={this.getButtonStyle2(index)} key={index} className="nameButton">{valueButton.name}</ButtonStyle> })}
                                                </div>
                                                <Box style={{ ...webStyle.textlabel_LosAngeles, marginRight: '25px' }} data-test-id="showMoreNew" onClick={(event) => { this.showMore(this.state.show === this.state.EngagementRequest?.id ? '' : this.state.EngagementRequest?.id, event) }}>{this.showMoreData(this.state.EngagementRequest?.id)}</Box>
                                            </MinBoxDisplayNew>
                                        </Box>
                                    </Box>
                                    <Box style={{ display: this.showDataNone() }}>
                                        <div className="table-container">
                                            <table className="responsive-table">
                                                <tbody>
                                                    <tr>
                                                        <th>{this.getStringEngagementRequest("Email")}:</th>
                                                        <td style={webStyle.tdAnswers}>
                                                            <Box style={webStyle.minBoxDisplay}>
                                                                {this.showNameData()}
                                                            </Box>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>{this.getStringEngagementRequest("Certifications")}:</th>
                                                        <td>{this.state.EngagementRequest.attributes?.certificate}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{this.getStringEngagementRequest("Qualifications")}:</th>
                                                        <td>{this.state.EngagementRequest.attributes?.qualification}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{this.getStringEngagementRequest("Firms")}:</th>
                                                        <td>
                                                            <div style={webStyle.emailLable}><span style={{ textTransform: 'lowercase',wordBreak:'break-all',overflowWrap:'break-word',maxWidth:'600px' }} >{this.state.EngagementRequest?.attributes?.firm_website} &nbsp;</span><span style={{ marginTop: "4px" }}>
                                                                <a href={this.state.EngagementRequest?.attributes?.firm_website} target="_blank" data-test-id="stopPropagationNew" onClick={(event) => event.stopPropagation()} > <img src={openIcon} alt="" style={{ width: '17px', borderRadius: '0px' }} /></a></span></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>{this.getStringEngagementRequest("EmployementHistory")}:</th>
                                                        <td>{this.state.EngagementRequest.attributes?.employment_history}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{this.getStringEngagementRequest("Disclosure")}:</th>
                                                        <td>{this.state.EngagementRequest.attributes?.disclosure}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        
                                        <Divider style={webStyle.dividerLine} />
                                        <Box style={webStyle.personalDetails}>
                                            <Box style={webStyle.profileNameNew}>
                                                <Box style={webStyle.focusLableNew}>{this.getStringEngagementRequest("RatingsandReviews")}:</Box>
                                                <Box className="isUpIcon">
                                                    {this.isUpIcon()}
                                                </Box>
                                            </Box>
                                            <Box className="">
                                                <Box className="profileNameNew" style={webStyle.profileNameNew}>
                                                    {this.average_rating_Data_Value()}
                                                </Box>
                                            </Box>

                                            <Box style={webStyle.textlabel_NameOfCorporation}>{this.total_reviews_reviews()}</Box>
                                            <Box style={{ display: this.isUpData() }}>
                                                <Box className="reviews-container" style={webStyle.marginTopBox}>
                                                    {this.state.EngagementRequest.attributes?.rating_and_reviews?.slice(0, 2).map((ratingData: any, index: number) => {
                                                        return (
                                                            <>
                                                                <Box data-test-id="details" style={webStyle.displayBox} id="details">
                                                                    <Box data-test-id="profile-name" style={webStyle.profileName} id="profileName">
                                                                        {ratingData.user_photo ?
                                                                            <img src={ratingData.user_photo} alt="" style={webStyle.reviewerDP} className="reviewerDP" />
                                                                            :
                                                                            <div style={{ ...webStyle.noImg as React.CSSProperties, height: "50px", width: '50px', fontSize: '24px' }}>
                                                                                {getFirstCharacterFromEachWord(ratingData.user_name)}
                                                                            </div>
                                                                        }
                                                                        <Typography data-test-id="reviewerName" style={webStyle.reviewerName}>{ratingData.user_name}</Typography>
                                                                    </Box>
                                                                    <Box id="dateRating" style={webStyle.profileName} >
                                                                        <Typography style={webStyle.reviewerNameText} >{ratingData.date}</Typography>
                                                                        <Box style={webStyle.fontSizeText} id="reviewStarFordetailsReviews">
                                                                            <Rating value={ratingData.rating} name="size-small" emptyIcon={<StarIcon style={webStyle.stareColor} fontSize="inherit" />} readOnly precision={0.5} size="small" />
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box>
                                                                    <Typography className="consecteturText" style={webStyle.consecteturText} data-test-id="main-review-content">{ratingData.description}</Typography>
                                                                </Box>
                                                            </>
                                                        )
                                                    })}
                                                    {
                                                        this.state.EngagementRequest?.attributes?.total_reviews > 2 &&
                                                        <Box className="all-review-btn" data-test-id="dialogCashModelNewData" onClick={(event) => { this.dialogCashModelNew(event) }} style={webStyle.marginTopBox}>
                                                            <span style={webStyle.allReviewBtn}>{this.getStringEngagementRequest("AllReviews")}</span>
                                                        </Box>
                                                    }

                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            {
                                this.state.EngagmentReject.status === "pending" && <Box style={webStyle.minBoxDisplay1}>
                                    <NextButton disableRipple data-test-id="sendEngagementButton" >{this.getStringEngagementRequest("RequestSent")}</NextButton>
                                </Box>
                            }
                            {
                            }
                            {
                                this.state.EngagmentReject.status === "rejected" && <Box style={webStyle.minBoxDisplay1} >
                                    <ChooseANewFinancialPlannerButton data-test-id="choose-new-planner" onClick={() => this.goToFinancialPlanner()} >{this.getStringEngagementRequest("chooseDifferentPlanner")}</ChooseANewFinancialPlannerButton>

                                </Box>
                            }



                            <Box>
                                <Dialog
                                    open={this.state.isPopupOpenNew}
                                    keepMounted
                                    data-test-id="dialogModel"
                                    onClose={this.handleDialogClose}
                                    aria-labelledby="alert-dialog-slide-title"
                                    aria-describedby="alert-dialog-slide-description"
                                    PaperProps={{ style: this.dialogCashPaperStyleNew }}
                                >
                                    <DialogContent style={webStyle.openModel}>
                                        <Box className="">
                                            <Box className="" style={webStyle.profileNameNew}>
                                                <Box style={webStyle.average_rating_fontSize} className="">{this.state.EngagementRequest.attributes?.average_rating}</Box>&nbsp;&nbsp;
                                                <Box style={webStyle.fontSizeText} className="">
                                                    {this.state.EngagementRequest.attributes?.average_rating && <Rating name="size-medium" size="small" value={this.state.EngagementRequest.attributes?.average_rating} emptyIcon={<StarIcon fontSize="inherit" style={webStyle.stareColor} />} readOnly precision={0.5} />}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box style={webStyle.textlabel_NameOfCorporation}>{this.getStringEngagementRequest("basedoutof")} {this.state.EngagementRequest.attributes?.total_reviews} {this.getStringEngagementRequest("reviews")}</Box>
                                        <Box className="" style={webStyle.marginTopBox}>
                                            {this.state.rating_and_reviews_Model.map((ratingDataModel, index: number) => {
                                                return (
                                                    <>
                                                        <Box key={index} className="details" style={webStyle.displayBox} id="details">
                                                            <Box data-test-id="profile-name" style={webStyle.profileName} id="profileName">
                                                            {ratingDataModel.attributes.user_photo ?
                                                                           <img style={webStyle.reviewerDP} alt="" data-test-id="reviewerDP" src={ratingDataModel.attributes.user_photo} />
                                                                            :
                                                                            <div style={{ ...webStyle.noImg as React.CSSProperties, height: "50px", width: '50px', fontSize: '24px' }}>
                                                                                {getFirstCharacterFromEachWord(ratingDataModel.attributes.user_name)}
                                                                            </div>
                                                                        }
                                                                {/*  */}
                                                                <Typography data-test-id="reviewerName" style={webStyle.reviewerName} >{ratingDataModel.attributes.user_name}</Typography>
                                                            </Box>
                                                            <Box id="dateRating" style={webStyle.profileName} className="date-rating">
                                                                <Typography style={webStyle.reviewerNameText} data-test-id="date-of-review" >{ratingDataModel.attributes.date}</Typography>
                                                                <Box data-test-id="reviewStarFordetailsReviews" style={webStyle.fontSizeText}>
                                                                    <Rating name="size-small" emptyIcon={<StarIcon fontSize="inherit" style={webStyle.stareColor} />} value={ratingDataModel.attributes.rating} readOnly precision={0.5} size="small" />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box>
                                                            <Typography style={webStyle.consecteturText} data-test-id="main-review-content">{ratingDataModel.attributes.description}</Typography>
                                                        </Box>
                                                    </>
                                                )
                                            })}
                                        </Box>
                                    </DialogContent>
                                </Dialog>
                            </Box>
                            {this.state.EngagmentReject.status === "accepted" ?
                                <Box>
                                    <Dialog
                                        open={this.state.isPopupOpenModel}
                                        keepMounted
                                        data-test-id="dialogModel"
                                        onClose={this.dialogModel}
                                        aria-labelledby="alert-dialog-slide-title"
                                        aria-describedby="alert-dialog-slide-description"
                                        PaperProps={{ style: this.dialogModelPaperStyleNew }}
                                    >
                                        <DialogContent style={webStyle.openModel} dir={this.state.direction}>
                                            <ModelFontSize >{this.getStringEngagementRequest("myneSuccessDesc1")}</ModelFontSize>
                                            <ModelFontSizePregraf>{this.getStringEngagementRequest("myneSuccessDesc2")}</ModelFontSizePregraf>
                                        </DialogContent>
                                        <Divider />
                                        <Grid container  >
                                            <DontNo data-test-id="showMoreNewAllow" onClick={() => this.handleDontAllow()} item xs={6}>{this.getStringEngagementRequest("dontAllow")}</DontNo>
                                            <Allowe data-test-id="showMoreNewDontAllow" onClick={() => this.handleAllow()} item xs={6}>{this.getStringEngagementRequest("Allow")}</Allowe>

                                        </Grid>
                                    </Dialog>
                                </Box> : null}
                        </Box>
                    </CnvestmentBox>
                </BackgroundBoxSatyle >
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
type IStyle = { [key: string]: React.CSSProperties }
const webStyle: IStyle = {
    handleClickMargin: { margin: '0px 15px' },
    openModel: { padding: '20px 25px' },
    dividerLine: { color: '#607d8b' },
    expandMoreRounded: { color: '#3cd7c6' },
    average_rating_fontSize: { color: '#fff', fontSize: '12px' },
    displayBox: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
    fontSizeText: { fontSize: '12px', color: 'white' },
    emailLable: { display: 'flex' },
    clicked: { boxShadow: "#1bf5e3 0px 0px 10px", border: "1px solid #1bf5e3", borderRadius: "12px", background: "#395d6b", marginBottom: '30px' },
    stareColor: { color: '#ceccc2' },
    modelRequest: { fontSize: "20px", color: "white" },
    minBoxDisplay1: { display: 'flex', justifyContent: 'center' },
    marginImg: { margin: '0px 10px', cursor: "pointer" },
    consecteturText: { color: '#fff', fontSize: '14px', fontWeight: 400, margin: '20px 0px' },
    marginTopBox: { marginTop: '20px' },
    textlabel_LosAngeles: { color: "#2DE2D5", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 500, cursor: "pointer" },
    textlabel_NameOfCorporation: { color: "#928F98", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 400, display: "flex" },
    focusLableNew: { color: "#FFF", fontFamily: "Poppins", fontSize: "16px", fontWeight: 600 },
    focusLable: { color: "#FFF", fontFamily: "Poppins", fontSize: "16px", fontWeight: 600, margin: "10px 0px" },
    tdAnswers: { color: '#2CE2D5', fontSize: '14px', fontWeight: 600, paddingLeft: '15px' },
    allReviewBtn: { borderRadius: '6px', border: '1px solid #C9F9F6', padding: '5px 20px', color: "#C9F9F6", cursor: 'pointer' },
    personalDetails: { padding: '10px' },
    timeMsg: { color: '#fff', marginBottom: '7px', fontSize: '18px', fontWeight: 400, fontFamily: "Poppins" },
    tdQuestions: { color: '#fff', fontSize: '14px', fontWeight: 400 },
    reviewerName: { color: '#fff', fontWeight: 400 },
    planningBackground: { borderRadius: "12px", border: "1px solid #57575D", background: "#3C3C51", boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)", padding: "10px 10px 25px 10px" },
    reviewerNameText: { color: '#fff', opacity: '0.5', fontWeight: 500 },
    profileName: { display: 'flex', alignItems: 'center', gap: '10px' },
    webStyleMenBox: { marginTop: "2rem", borderRadius: "12px", border: "1px solid #57575D", background: "#395d6b", boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)", marginBottom: '30px' },
    profileNameNew: { display: 'flex', alignItems: 'center' },
    profileNameNewIcon: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
    reviewerDP: { borderRadius: '50px', width: '50px', height: '50px' },
    minBoxDisplay: { display: 'flex', justifyContent: 'space-between', flexWrap: "wrap" },
    boxMarginEnd: { display: "flex", justifyContent: "end", width: "100%" },
    listLine: { color: "#94929f", margin: "25px 0px" },
    planning: { fontSize: "25px", fontWeight: 600, color: "white" },
    succsecrequest: { backgroundColor: "#c9e6b0", margin: "25px 0px", color: '#2fc163', borderRadius: "10px", padding: '10px' },
    rejectedrequest: { backgroundColor: "#f4beba", margin: "25px 0px", color: '#d9545f', borderRadius: "10px", padding: '10px' },
    newService: { backgroundColor: "#c9e6b0", margin: "25px 0px", color: '#2fc163', borderRadius: "10px", padding: '10px', display: 'flex', justifyContent: 'space-between' },
  newServiceBtn: { background: '#395D6B', borderRadius: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer' },
  BtnImg: { width: '85px' },
  btnLabel: { color: '#fff', fontWeight: 600 },


    ScheduleCallBtn: { marginLeft: '15px', color: '#3C3C51', border: '1px solid #C9F9F6', background: '#C9F9F6', padding: '9px 48px', fontWeight: 600, borderRadius: '5px', cursor: 'pointer', whiteSpace: "nowrap" },
    chatBtn: { color: '#C9F9F6', border: '1px solid #C9F9F6', padding: '9px 70px', fontWeight: 600, borderRadius: '5px', cursor: 'pointer' },
    modifyyBtn: { color: '#C9F9F6', width: "100%", maxWidth: '23.5rem', border: '1px solid #C9F9F6', padding: '9px 70px', fontWeight: 600, borderRadius: '5px', cursor: 'pointer' },
    noImg: {
        backgroundColor: "2CE2D5",
        justifyContent: 'center',
        display: "flex",
        width: "94px",
        color: "#fff",
        fontWeight: 600,
        fontSize: "32px",
        alignItems: "center",
        height: "94px",
        borderRadius: "50%",
        textTransform: "uppercase"
    },
};
const ButtonStyle = styled(Button)({
    fontFamily: "Poppins",
    color: "#FFF",
    fontSize: "16px",
    borderRadius: "15px",
    background: "#395D6B",
    textAlign: "center",
    fontStyle: "normal", fontWeight: 400, padding: "5px 20px", textTransform: "capitalize", margin: '10px 10px', '&:hover': {
        backgroundColor: "#395D6B",
    },
})
const DontNo = styled(Grid)({ textAlign: 'center', fontSize: '20px', color: '#006FFF', fontWeight: 600, borderRight: '1px solid #c3c3c3', padding: '8px 20px' })
const Allowe = styled(Grid)({ textAlign: 'center', fontSize: '20px', color: '#006FFF', fontWeight: 600, padding: '8px 20px' })

const ModelFontSizePregraf = styled(Box)({ fontSize: "20px", textAlign: "center", color: "#3b3b3b" })

const ModelFontSize = styled(Box)({ fontSize: "20px", fontWeight: 700, textAlign: "center", marginTop: '30px' })
const MinBoxDisplayNew = styled(Box)({
    display: 'flex', flexWrap: "wrap", alignItems: "flex-end"
})
const BackgroundBoxSatyle = styled(Box)(({ dir }) => ({ width: '100%', height: '100vh', overflowY: 'scroll', backgroundColor: '#2b2a37', 
    "& .responsive-table td": { textAlign: dir === 'rtl' ? "right" : "left" },
    "& .responsive-table th": { textAlign: dir === 'rtl' ? "right" : "left" },
    "& .MuiRating-root": {
        textAlign: dir === 'rtl' ? 'right' : 'left',
    },
}))
const CnvestmentBox = styled(Box)({
    paddingLeft: "80px !important", paddingRight: "80px !important", paddingTop: "40px !important",
    '@media (min-width: 320px)': { paddingLeft: "15px !important", paddingRight: "15px !important" },
    '@media (min-width: 1200px)': { paddingLeft: "80px !important", paddingRight: "80px !important" },
})
const NextButton = styled(Button)({
    background: "#7b9398", marginBottom: "20px", textTransform: "capitalize", fontWeight: 600, fontFamily: "Poppins", color: "#292931", cursor: "none",
    padding: "12px", fontSize: "16px", borderRadius: "10px",
    '&:hover': { background: "#7b9398", marginBottom: "10px", textTransform: "capitalize", fontWeight: 600 },
    '@media (min-width: 320px)': { width: "100%" },
    '@media (min-width: 1200px)': { width: "20%", },

})
const ChooseANewFinancialPlannerButton = styled(Button)({
    background: "#c8f8f5", color: "#2b2a37", marginBottom: "10px", textTransform: "unset", fontWeight: 600, fontFamily: "Poppins", borderRadius: "10px", padding: "12px",
    '&:hover': { background: "#fff", marginBottom: "10px", textTransform: "unset", fontWeight: 600 },
    '@media (min-width: 320px)': { width: "100%" },
    '@media (min-width: 1200px)': { width: "35%", },

})
const Textlabel_LosAngeles = styled(Box)({ color: "#2DE2D5", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 500, cursor: "pointer", textTransform: "capitalize" })
const StdQuestions1 = styled(Box)({
    color: '#fff', fontSize: '14px', fontWeight: 400, paddingLeft: '15px', textTransform: "capitalize"
})
const TextLable = styled(Box)({ color: "#FFF", fontFamily: "Poppins", fontSize: "20px", fontStyle: "normal", fontWeight: 600, textTransform: "capitalize" })

// Customizable Area End