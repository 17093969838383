import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    OutlinedInput,
    Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Select from 'react-select';
import { ErrorMessage, Formik } from "formik";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "../assets/PortableAsset.css"
import AddPropertyController, {
    Props
} from "./AddPropertyController.web";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CounterButton from "../../../components/src/CounterButton";
import { formatNumberWithCommas, sanitizeAndRemoveCommas } from "../../../components/src/commonFn";
// Customizable Area End

// Customizable Area Start
let themeProperty = createTheme({
    palette: {
        primary: {
            contrastText: "#fff",
            main: "#2CE2D5",
        },
    },
});

themeProperty = createTheme(themeProperty, {
    overrides: {
        MuiSvgIcon: {
            root: {
                color: '#94989c',
            },
        },
        MuiOutlinedInput: {
            root: {
                "& .MuiOutlinedInput-input": {
                    color: "white",
                    borderBottom: '0px',
                },
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "#fff",
                },
                "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fff",
                },
                "& $notchedOutline": {
                    borderColor: "#94989c",
                },
                "& .MuiAutocomplete-endAdornment": {
                    position: 'unset'
                }
            },
        },
        MuiDialog: {
            paper: {
                boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
                backgroundColor: '#395D6B',
                borderRadius: '8px',
                width: 'max-content',
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#3C3C51",
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: "rgb(57, 93, 107)",
                color: "rgb(181, 176, 176)"

            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                backgroundColor: "rgb(57, 93, 107)",
                color: "rgb(181, 176, 176)",
                "& button": {
                    backgroundColor: "rgb(57, 93, 107)",
                    color: "rgb(181, 176, 176)",
                }
            },
            dayheader: {
                backgroundColor: "rgb(57, 93, 107)",
                color: "rgb(181, 176, 176)"
            },
            dayLabel: {
                color: "rgb(181, 176, 176)"
            }
        },
        MuiPickersDay: {
            day: {
                color: "rgb(181, 176, 176)",
            },
            daySelected: {
                backgroundColor: "#2CE2D5",
            },
            dayDisabled: {
                color: "#ccc",
            },
            current: {
                color: "rgb(181, 176, 176)"
            },
            dayLabel: {
                color: "rgb(181, 176, 176)"
            }
        },
        MuiPickersModal: {
            dialogAction: {
                color: "#33abb6",
                backgroundColor: "#3C3C51",
            },
        },
    },
    palette: {
        primary: {
            contrastText: "#fff",
            main: "#2CE2D5",
        },
    },
});
const customStylesProperty = {
    control: (defaultStyles: any) => ({
        ...defaultStyles,
        backgroundColor: "#2a2a38",
        borderRadius: '8px',
        padding: "8px",
        color: 'rgba(255, 255, 255, 0.60)',
        fontSize: '16px',
        fontFamily: 'Poppins',
        textAlign: "left",
        fontWeight: '500',
    }),
    singleValue: (defaultStyles: any) => ({
        ...defaultStyles,
        color: 'white'
    })
};
// Customizable Area End

export default class AddPropertyweb extends AddPropertyController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    logoImg1AddPropertyCss = () => {
        return this.state.logoImg1AddProperty === "rtl" ? "assetsPriceNew" : "assetsPrice"
    }
    initValFN = (
        condition: boolean,
        truthyVal: string | null,
        falsyVal: string | null,
        shouldFormat: boolean = false
      ) => {
        if (!condition && shouldFormat && falsyVal != null) {
          return formatNumberWithCommas(falsyVal);
        }
      
        return condition ? truthyVal : falsyVal;
      }
    mortgageSelectInitVal = () => {
        if (!this.state.editPropertyData || !this.state.editPropertyData?.martagage_loan) {
            return ({ label: "No", value: "no" })
        } else {
            return ({ label: "Yes", value: "yes" })
        }
    }
    calendarImg = () => {
        return <img src={this.state.image_Calendar} alt="" style={{
            marginRight: "12px",
            borderRadius: "0",
            height: "18px",
            marginTop: "-4px",
            width: "18px",
        }} />
    }
    render() {
        const totalSteps = 3;
        const currentStep = this.state.activeStep;
        return (
            // Customizable Area Start
            <Box style={{ height: '100vh', width: '100%', overflowY: 'auto' }} className='cash_sideBorder box_bg_color'>

                <ThemeProvider theme={themeProperty}>
                    <CssBaseline />
                    <Box style={{ display: 'flex', justifyContent: 'space-between', }} className="headerMain">
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={this.state.imagenav_BackIcon} style={{ width: '24px', height: '13px', cursor: "pointer" }} onClick={this.portableAsset} />
                            <a style={{ color: 'white', padding: '0px 20px', fontSize: '24px', fontFamily: 'Poppins', fontWeight: 600 }}>
                                {this.editPropertyTitle()} {this.getStringAddProperty('Property')} </a>
                        </Box>
                        <Box data-test-id="addPropertyheaderDrop">
                            <Box style={{ display: 'flex', alignItems: 'center', }}>
                                {(this.potableSelect())}
                                <Box className="addPropertynewmineone">
                                    <img src={this.state.image_Vuesax} style={{ width: '41px', height: '41px', cursor: "pointer" }} onClick={this.settingRoute} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Box>
                                <Box className="stepperPro">{this.ProgressBar(totalSteps, currentStep)}</Box>
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        propertyName: this.initValFN(!this.state.editPropertyData, "", this.state.editPropertyData?.name),
                                        location: this.initValFN(!this.state.editPropertyData, "", this.state.editPropertyData?.location),
                                        country: this.initValFN(!this.state.editPropertyData, null, this.state.initialCountryValue),
                                        apartment: this.initValFN(!this.state.editPropertyData, "", this.state.editPropertyData?.apartment_number),
                                        floor: this.initValFN(!this.state.editPropertyData, "", this.state.editPropertyData?.floor_number),
                                        area: this.initValFN(!this.state.editPropertyData, "", this.state.editPropertyData?.area_size),
                                        price: this.initValFN(!this.state.editPropertyData, null, this.state.editPropertyData?.price,true),
                                        marketPrice: this.initValFN(!this.state.editPropertyData, null, this.state.editPropertyData?.market_value,true),
                                        currency: this.formikField('currency', this.state.editPropertyData),
                                        status: this.formikField('status', this.state.editPropertyData),
                                        mortgageSelect: this.mortgageSelectInitVal(),
                                        mortgageAmount: this.initValFN(!this.state.editPropertyData?.property_mortgage, null, this.state.editPropertyData?.property_mortgage?.amount,true),
                                        mortgageTerm: this.initValFN(!this.state.editPropertyData?.property_mortgage, null, this.state.editPropertyData?.property_mortgage?.term_years),
                                        mortgageMonths: this.initValFN(!this.state.editPropertyData?.property_mortgage, null, this.state.editPropertyData?.property_mortgage?.term_months),
                                        mortgageInterest: this.initValFN(!this.state.editPropertyData?.property_mortgage, null, this.state.editPropertyData?.property_mortgage?.annual_interest_rate),
                                        mortgagePayment: this.initValFN(!this.state.editPropertyData?.property_mortgage, "", this.state.editPropertyData?.property_mortgage?.mortgage_monthly_payments,true),
                                        mortgageStartDate: this.initValFN(!this.state.editPropertyData?.property_mortgage, null, this.state.editPropertyData?.property_mortgage?.start_date),
                                        mortgageFirstPayment: this.initValFN(!this.state.editPropertyData?.property_mortgage, null, this.state.editPropertyData?.liabilities_forms?.first_payments),
                                        marketPriceDate: this.initValFN(!this.state.editPropertyData, null, this.state.editPropertyData?.market_date),
                                        propertyPriceDate: this.initValFN(!this.state.editPropertyData, null, this.state.editPropertyData?.purchase_date),
                                    }}
                                    onSubmit={(values: any) => {
                                        const cleanValues = {
                                            ...values,
                                            price: sanitizeAndRemoveCommas(values.price),
                                            marketPrice: sanitizeAndRemoveCommas(values.marketPrice),
                                            mortgageAmount: sanitizeAndRemoveCommas(values.mortgageAmount),
                                            mortgagePayment: sanitizeAndRemoveCommas(values.mortgagePayment),
                                        };
                                        this.HandleSubmit(cleanValues);
                                    }}
                                    validationSchema={this.propertySchema[this.state.activeStep]}
                                    data-test-id="accountLogin"
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                >
                                    {({ values, handleSubmit, handleChange, setFieldValue }) => (

                                        <form onKeyDown={this.onKeyDownAddProerty} onSubmit={handleSubmit} data-test-id='submitForm'>
                                            <Box>
                                                <Box>
                                                    {this.state.activeStep === 0 && <Box>
                                                        <Box>
                                                            <Typography className="title">{this.getStringAddProperty('PropertyType')}</Typography>
                                                            <Box>
                                                                {this.multiBox()}

                                                                <Box>
                                                                    <Box>
                                                                        <Typography className="title">{this.getStringAddProperty('PropertyName')}</Typography>
                                                                        <Box>
                                                                            {this.OutlinedInputAddProperty("propertyName", addPropertywebStyle, values.propertyName, handleChange, 'placeholderPropertyName', "text", this.state.propertyName)}
                                                                        </Box>
                                                                        <Box sx={addPropertywebStyle.fieldError} data-test-id="error">
                                                                            <ErrorMessage name="propertyName" />
                                                                        </Box>
                                                                    </Box>
                                                                    <Box>
                                                                        <Typography className="title">{this.getStringAddProperty('propertyLocation')}</Typography>
                                                                        <Box>
                                                                            {this.OutlinedInputAddProperty("location", addPropertywebStyle, values.location, handleChange, 'placeholderPropertyLocation', "text", this.state.location)}
                                                                        </Box>
                                                                    </Box>
                                                                    <Box>
                                                                        <Typography className="title">{this.getStringAddProperty('propertyCountry')}</Typography>
                                                                        <Box>
                                                                            {(this.propertyLocation(addPropertywebStyle, values, setFieldValue))}
                                                                        </Box>
                                                                        <Box sx={addPropertywebStyle.fieldError} data-test-id="error">
                                                                            <ErrorMessage name="country" />
                                                                        </Box>
                                                                    </Box>
                                                                </Box>

                                                            </Box>
                                                        </Box>
                                                    </Box>}


                                                    {this.state.activeStep === 1 && <Box data-test-id="step-one">
                                                        <Box>
                                                            <Box>
                                                                <Typography className="title">{this.getStringAddProperty('propertyCurrency')}</Typography>
                                                                <Box >
                                                                    {(this.propertySelectMenu("currency", setFieldValue, customStylesProperty, values, values.currency))}
                                                                </Box>
                                                            </Box>
                                                            <Box className="assetsInputSecond">
                                                                <Box className={this.logoImg1AddPropertyCss()}>
                                                                    <Box >
                                                                        <Typography className="title">{this.getStringAddProperty('propertyPrice')}</Typography>
                                                                        {this.OutlinedInputAddProperty("price", addPropertywebStyle, values.price, handleChange, 'placeholderPropertyPrice', "text", this.state.price, this.state.editPropertyData ? true : false,setFieldValue)}
                                                                    </Box>
                                                                    <Box sx={addPropertywebStyle.fieldError}>
                                                                        <ErrorMessage name="price" />
                                                                    </Box>
                                                                </Box>
                                                                <Box className="assetsCurrency">
                                                                    <Typography className="title">{this.getStringAddProperty('date')}</Typography>
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                        <KeyboardDatePicker
                                                                            autoOk
                                                                            variant="inline"
                                                                            keyboardIcon={this.calendarImg()}
                                                                            inputVariant="outlined"
                                                                            format="dd/MM/yy"
                                                                            placeholder={"DD/MM/YY"}
                                                                            disabled={this.state.editPropertyData}
                                                                            data-test-id="price-date-picker"
                                                                            name="propertyPriceDate"
                                                                            value={values.propertyPriceDate}
                                                                            onChange={(val) => { setFieldValue("propertyPriceDate", val) }}
                                                                            InputProps={{
                                                                                style: {
                                                                                    padding: '10px 0px',
                                                                                    borderRadius: '8px',
                                                                                    color: 'white',
                                                                                }
                                                                            }}
                                                                            KeyboardButtonProps={{
                                                                                style: {
                                                                                    color: 'white'
                                                                                }
                                                                            }}
                                                                            style={addPropertywebStyle.fullWidth}
                                                                            className="newBoxContant12Pension"
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                    <Box sx={addPropertywebStyle.fieldError} data-test-id="error">
                                                                        <ErrorMessage name="propertyPriceDate" />
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            <Box className="assetsInputSecond">
                                                                <Box className={this.logoImg1AddPropertyCss()}>
                                                                    <Box >
                                                                        <Typography className="title">{this.getStringAddProperty('marketPrice')}</Typography>
                                                                        {this.OutlinedInputAddProperty("marketPrice", addPropertywebStyle, values.marketPrice, handleChange, 'placeholderMarketPrice', "text", this.state.marketPrice,false,setFieldValue)}
                                                                    </Box>
                                                                    <Box sx={addPropertywebStyle.fieldError}>
                                                                        <ErrorMessage name="marketPrice" />
                                                                    </Box>
                                                                </Box>
                                                                <Box className="assetsCurrency">
                                                                    <Typography className="title">{this.getStringAddProperty('date')}</Typography>
                                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                        <KeyboardDatePicker
                                                                            variant="inline"
                                                                            autoOk
                                                                            keyboardIcon={this.calendarImg()}
                                                                            inputVariant="outlined"
                                                                            format="dd/MM/yy"
                                                                            placeholder={"DD/MM/YY"}
                                                                            name="marketPriceDate"
                                                                            value={values.marketPriceDate}
                                                                            onChange={(val) => { setFieldValue("marketPriceDate", val) }}
                                                                            InputProps={{
                                                                                style: {
                                                                                    padding: '10px 0px',
                                                                                    borderRadius: '8px',
                                                                                    color: 'white',
                                                                                }
                                                                            }}
                                                                            KeyboardButtonProps={{
                                                                                style: {
                                                                                    color: 'white'
                                                                                }
                                                                            }}
                                                                            style={addPropertywebStyle.fullWidth}
                                                                            className=" newBoxContant12Pension"
                                                                        />
                                                                    </MuiPickersUtilsProvider>
                                                                    <Box sx={addPropertywebStyle.fieldError} data-test-id="error">
                                                                        <ErrorMessage name="marketPriceDate" />
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            <Box>
                                                                <Typography className="title">{this.getStringAddProperty('propertyMortgage')}</Typography>
                                                                <Box >
                                                                    {(this.mortgageSelectMenu("mortgageSelect", setFieldValue, customStylesProperty, values, values.mortgageSelect))}
                                                                </Box>
                                                            </Box>
                                                            {values.mortgageSelect.value === 'yes' && <Box>
                                                                <Typography className="title">{this.getStringAddProperty('propertyMortgageDetails')}</Typography>
                                                                <Box >
                                                                    <Box>
                                                                        {this.OutlinedInputAddProperty("mortgageAmount", addPropertywebStyle, values.mortgageAmount, handleChange, 'placeholderMortgageAmount', "text", this.state.mortgageAmount,false,setFieldValue)}
                                                                    </Box>
                                                                    <Box sx={addPropertywebStyle.fieldError} data-test-id="error">
                                                                        <ErrorMessage name="mortgageAmount" />
                                                                    </Box>
                                                                </Box>
                                                                <Box className=" custom-mortgage-details-field">
                                                                    <Box>
                                                                        {this.OutlinedInputAddProperty("mortgageTerm", addPropertywebStyle, values.mortgageTerm, handleChange, 'placeholderMortgageTerm', "number", this.state.mortgageTerm)}
                                                                    </Box>
                                                                    <Box sx={addPropertywebStyle.fieldError} data-test-id="error">
                                                                        <ErrorMessage name="mortgageTerm" />
                                                                    </Box>
                                                                </Box>
                                                                <Box className=" custom-mortgage-details-field">
                                                                    <Box>
                                                                        {this.OutlinedInputAddProperty("mortgageMonths", addPropertywebStyle, values.mortgageMonths, handleChange, 'placeholderMortgageMonth', "number", this.state.mortgageMonths)}
                                                                    </Box>
                                                                    <Box sx={addPropertywebStyle.fieldError} data-test-id="error">
                                                                        <ErrorMessage name="mortgageMonths" />
                                                                    </Box>
                                                                </Box>

                                                                <Box className=" custom-mortgage-details-field">
                                                                    <Box>
                                                                        {this.OutlinedInputAddProperty("mortgageInterest", addPropertywebStyle, values.mortgageInterest, handleChange, 'placeholderMortgageInterest', "number", this.state.mortgageInterest)}
                                                                    </Box>
                                                                    <Box sx={addPropertywebStyle.fieldError} data-test-id="error">
                                                                        <ErrorMessage name="mortgageInterest" />
                                                                    </Box>
                                                                </Box>

                                                                <Box className=" custom-mortgage-details-field">
                                                                    <OutlinedInput
                                                                        style={addPropertywebStyle.inputRoot}
                                                                        data-test-id="txtInputAssetname"
                                                                        placeholder={this.getStringAddProperty("placeholderMortgageMonthlyPayment")}
                                                                        fullWidth={true}
                                                                        name="mortgagePayment"
                                                                        type="text"
                                                                        value={this.state.monthlyPayment}
                                                                        readOnly
                                                                    />
                                                                </Box>
                                                                <Box className=" custom-mortgage-details-field">
                                                                    <Box className="cash_assetsCurrency addNewAddCashMainCurrency inputWrapper">
                                                                        <Box>
                                                                            <Box style={addPropertywebStyle.fullWidth} data-test-id="addCashCurrenNewcyDropdown">
                                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                    <KeyboardDatePicker
                                                                                        autoOk
                                                                                        variant="inline"
                                                                                        keyboardIcon={this.calendarImg()}
                                                                                        inputVariant="outlined"
                                                                                        format="dd/MM/yy"
                                                                                        placeholder={this.getStringAddProperty("placeholderMortgageStartDate")}
                                                                                        name="mortgageStartDate"
                                                                                        value={values.mortgageStartDate}
                                                                                        onChange={(val) => { setFieldValue("mortgageStartDate", val) }}
                                                                                        InputProps={{
                                                                                            style: {
                                                                                                padding: '10px 0px',
                                                                                                borderRadius: '8px',
                                                                                                color: 'white',
                                                                                            }
                                                                                        }}
                                                                                        KeyboardButtonProps={{
                                                                                            style: {
                                                                                                color: 'white'
                                                                                            }
                                                                                        }}
                                                                                        style={addPropertywebStyle.fullWidth}
                                                                                        className=" newBoxContant12Pension"
                                                                                    />
                                                                                </MuiPickersUtilsProvider>
                                                                            </Box>
                                                                            <Box sx={addPropertywebStyle.fieldError} data-test-id="error">
                                                                                <ErrorMessage name="mortgageStartDate" />
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box className=" custom-mortgage-details-field">
                                                                    <Box className="cash_assetsCurrency addNewAddCashMainCurrency inputWrapper">
                                                                        <Box>
                                                                            <Box style={addPropertywebStyle.fullWidth} data-test-id="addCashCurrenNewcyDropdown">
                                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                    <KeyboardDatePicker
                                                                                        autoOk
                                                                                        variant="inline"
                                                                                        keyboardIcon={this.calendarImg()}
                                                                                        inputVariant="outlined"
                                                                                        format="dd/MM/yy"
                                                                                        placeholder={this.getStringAddProperty("placeholderMortgageFirstPaymentDate")}
                                                                                        name="mortgageFirstPayment"
                                                                                        value={values.mortgageFirstPayment}
                                                                                        onChange={(val) => { setFieldValue("mortgageFirstPayment", val) }}
                                                                                        InputProps={{
                                                                                            style: {
                                                                                                padding: '10px 0px',
                                                                                                borderRadius: '8px',
                                                                                                color: 'white',
                                                                                            }
                                                                                        }}
                                                                                        KeyboardButtonProps={{
                                                                                            style: {
                                                                                                color: 'white'
                                                                                            }
                                                                                        }}
                                                                                        style={addPropertywebStyle.fullWidth}
                                                                                        className=" newBoxContant12Pension"
                                                                                    />
                                                                                </MuiPickersUtilsProvider>
                                                                            </Box>
                                                                            <Box sx={addPropertywebStyle.fieldError} data-test-id="error">
                                                                                <ErrorMessage name="mortgageFirstPayment" />
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>}
                                                            <Box className="assetsInputSecond">
                                                                <Box className={this.logoImg1AddPropertyCss()}>
                                                                    <Box >
                                                                        <Typography className="title">{this.getStringAddProperty('ApartmentNumber')}</Typography>
                                                                        {this.OutlinedInputAddProperty("apartment", addPropertywebStyle, values.apartment, handleChange, 'placeholderApartmentNumber', "text", this.state.apartment)}
                                                                    </Box>
                                                                    <Box sx={addPropertywebStyle.fieldError} data-test-id="error">
                                                                        <ErrorMessage name="apartment" />
                                                                    </Box>
                                                                </Box>
                                                                <Box className="flatNumber" >
                                                                    <Box>
                                                                        <Typography className="title">{this.getStringAddProperty('FloorNumber')}</Typography>
                                                                        {this.OutlinedInputAddProperty("floor", addPropertywebStyle, values.floor, handleChange, 'placeholderFloorNumber', "number", this.state.floor)}
                                                                    </Box>
                                                                    <Box sx={addPropertywebStyle.fieldError} data-test-id="error">
                                                                        <ErrorMessage name="floor" />
                                                                    </Box>
                                                                </Box>
                                                            </Box>

                                                            <Box>
                                                                <Typography className="title">{this.getStringAddProperty('AreaSize')}</Typography>
                                                                <Box >
                                                                    {this.OutlinedInputAddProperty("area", addPropertywebStyle, values.area, handleChange, 'placeholderAreaSize', "number", this.state.area)}
                                                                </Box>
                                                                <Box sx={addPropertywebStyle.fieldError} data-test-id="error">
                                                                    <ErrorMessage name="location" />
                                                                </Box>
                                                            </Box>

                                                            <Box className="assetsInputSecond">
                                                                <Box className="assetsPriceNew">
                                                                    <Typography className="title">{this.getStringAddProperty('Bedrooms')}</Typography>
                                                                    <CounterButton value={this.state.bedroomds} buttonHandler={this.handleBedroomsValue} />
                                                                </Box>
                                                                <Box className="flatNumber" >
                                                                    <Typography className="title">{this.getStringAddProperty('Bathrooms')}</Typography>
                                                                    <CounterButton value={this.state.bathrooms} buttonHandler={this.handleBathroomsValue} />
                                                                </Box>
                                                            </Box>

                                                        </Box>
                                                    </Box>}


                                                    {this.state.activeStep === 2 && <Box>
                                                        <Box>
                                                            <Box>
                                                                <Box className="">
                                                                    {(this.uploadPicture())}
                                                                    {(this.uploadOriginalReceipt())}
                                                                </Box>
                                                                <Box className="cash_assetsCurrency">
                                                                    <Box>
                                                                        <Typography className="cash_title">{this.getStringAddProperty('Status')}</Typography>
                                                                        <Box>
                                                                            <Select
                                                                                defaultValue={values.status}
                                                                                options={this?.state?.allStatus}
                                                                                name={"status"} value={values.status}
                                                                                placeholder={this.getStringAddProperty('placeholderStatus')}
                                                                                onChange={(value) => { setFieldValue("status", value) }} classNamePrefix="an-simple-select" className="selectMenu" data-test-id="selectMenu" styles={customStylesProperty} />
                                                                        </Box>
                                                                        <Box sx={addPropertywebStyle.fieldError}>
                                                                            <ErrorMessage name="status" />
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>}
                                                </Box>
                                                <Box style={{ textAlign: "end", paddingTop: '48px' }} data-test-id="addPropertySubmitFirstLast">
                                                    <Button
                                                        style={{
                                                            color: '#3C3C50',
                                                            textAlign: 'center',
                                                            fontSize: '17px',
                                                            fontWeight: 700,
                                                            fontFamily: 'Poppins',
                                                            lineHeight: '24px',
                                                            textTransform: 'none',
                                                            width: "auto",
                                                        }}
                                                        variant="contained"
                                                        data-test-id="submitButton"
                                                        type="submit"
                                                        className="nextButton">
                                                        {(this.staperButton())}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </form>
                                    )}

                                </Formik>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}></Grid>
                    </Grid>
                </ThemeProvider>


                <Box>
                    <Dialog
                        open={this.state.openPropertyModel}
                        keepMounted
                        data-test-id="dialogModel"
                        onClose={this.dialogPropertyModel}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{ style: this.dialogPropertyPaperStyle }}
                    >
                        <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={this.state.modelSuccess} /></DialogTitle>
                        <DialogContent style={{ padding: '8px 20px' }}>
                            <DialogContentText id="alert-dialog-slide-description" className='dialogContent'>
                                {this.getStringAddProperty("successfullyAddData")}
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </Box>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const addPropertywebStyle = {
    inputStyle: {
        border: "1px solid white",
        padding: "15px",
    },
    fieldError: {
        color: "#EE5353",
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
    },
    inputRoot: {
        borderRadius: '8px',
        height: '56px',
        outline: 'none',
        fontFamily: 'Poppins',
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336 !important',
        },
        '& .MuiOutlinedInput-notchedOutline .MuiOutlinedInput-input': {
            padding: '14.5px 14px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#94989c !important',
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white !important',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white !important',
        },
        '& input::placeholder': {
            fontWeight: 500,
            fontSize: '14px',
            color: '#f44336 ',
            opacity: 0.5,

        },
        '& .MuiInputBase-input': {
            borderColor: 'white !important'
        },
    },
    buttons: {
        backgroundColor: "#c7f9f6",
        borderRadius: '10px',
        margin: '0px',
        Color: '#3C3C50',
        height: "50px",
        fontWeight: 700,
        fontSize: '20px',
        textTransform: 'none',
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: "red !important",
        }
    },
    datePicker: {
        border: '1px solid black',
        borderRadius: '4px',
        padding: '5px',
    },
    customDatePicker: {
        borderRadius: '8px',
        padding: '5px 15px',
        fontWeight: 500,
        fontSize: '14px',
        opacity: 0.5,
        height: '56px',
        width: "100%",
        background: "transparent",
        border: '1px solid',
        outline: 'none',
        fontFamily: 'Poppins',
        "&input[type='date']::before": {
            content: "attr(placeholder)",
            width: "100%"
        }
    },
    fullWidth: {
        width: "100%"
    },
    logoandimg: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        top: '162px',
        paddingTop: '20px',
        paddingRight: '49px'
    },
    selectCountry: {
        width: "100%",
        "& .MuiAutocomplete-inputRoot": {
            padding: '10px',
        },
        "& .MuiAutocomplete-listbox": {
            backgroundColor: 'red',
            color: 'black!important',
        },
        "& .MuiAutocomplete-option": {
            padding: '10px',
        },
    },
};
// Customizable Area End
