import React from "react";


// Customizable Area Start

import { imgMobileVisible, bankimg, imgAsset, imgAccess, imgInvestment, imgMyneLogo, imgBackIcon } from "./assets";
import "../assets/border.css";
import './MyneSubscription.css';
import { Grid } from "@material-ui/core";

// Customizable Area End

import FreetrialController, {
  Props,
} from "./FreetrialController";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

export default class Changeplan extends FreetrialController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start


    // Customizable Area End
  }

  // Customizable Area Start


  monthlyBackground = () => {
    return this.state.selectedPlan === 'monthly' ? '2px solid #40e0d0' : '2px solid gray';
  };

  yearlyBackground = () => {
    return this.state.selectedPlan === 'yearly' ? '2px solid #40e0d0' : '2px solid gray';
  };
  handleButtonClick = (id: any) => {
    this.setState({
      isButtonClicked: true,
      monthlyid: null,
      yearlyid: null
    });
  }
  handleButtonMonthlyClick = (id: any) => {
    this.setState({
      isButtonClicked: true,
      selectedPlan: 'monthly',
      yearlyid: null,
      monthlyid: id
    });
  }


  handleButtonYearlyClick = (id: any) => {
    this.setState({
      isButtonClicked: true,
      selectedPlan: 'yearly',
      yearlyid: id,
      monthlyid: null
    });
  }

  getPlanDetails = (planName: string) => {
    if (this.state.deatailsData) {
      return this.state.deatailsData.find(plans => plans.attributes.name === planName)
    }
  }
  // Customizable Area End

  render() {
    const subsData = this.state.subs?.subscription?.plan_id;
    const currentPlan = this.state.deatailsData.length > 0 ? this.state.deatailsData.find((plan) => plan.id === String(subsData)) : null;
    return (
      // Customizable Area Start
      <div style={{ ...styles.mainBankWrapper2, overflowY: 'auto' }} dir={this.state.languagesLogin}>
        <div className="borderCss2 bankIntegrationWrapper2">
          <div style={{ position: 'absolute', zIndex: 12, right: '20px', top: '20px', }}>
            <img src={imgMyneLogo} style={{ width: 100 }} />
          </div>
          <div>
            <Grid container>
              <Grid item xs={12} md={5} className="subscription-left">
                <div style={{ textAlign: 'center', marginTop: '36px', width: '100%' }}>
                  <h4 style={styles.textContentH41}><b>{this.getStringLogin('createAccountMyne')}<br />
                  {this.getStringLogin('extraFeature')}</b></h4>
                  <h2> <span style={{ color: '#40e0d0' }}>{this.getStringLogin('premium')}</span> {this.getStringLogin('subscription')}</h2>

                  <div style={{ display: "flex", gap: '20px', justifyContent: "space-evenly", }}>
                    <div> <img src={bankimg} style={{ width: 50 }} />
                      <p style={styles.textContentP1}>{this.getStringLogin('unlimitedBank')} <br />
                      {this.getStringLogin('walletSync')}</p>
                    </div>
                    <div><img src={imgAsset} style={{ width: 50 }} />
                      <p style={styles.textContentP1}>{this.getStringLogin('accessToAll')}<br />
                      {this.getStringLogin('typesOfAssets')}</p>
                    </div>
                  </div>
                  <div style={{ display: "flex", gap: '20px', marginTop: 22, justifyContent: "space-evenly" }}>
                    <div> <img src={imgAccess} style={{ width: 50 }} />
                      <p style={styles.textContentP1}>{this.getStringLogin('fullAccessTo')}<br />
                      {this.getStringLogin('estatePlanning')}</p></div>
                    <div> <img src={imgInvestment} style={{ width: 50 }} />
                      <p style={styles.textContentP1}>{this.getStringLogin('fullAccess')}<br />
                      {this.getStringLogin('toInvestment')}</p></div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={7} className="subscription-right">
                <div style={{ marginTop: '36px', width: '100%', textAlign: 'center' }}>
                  <div
                    style={{ display: "flex", alignItems: "center", gap: "10px", cursor: "pointer" }}
                    data-test-id="handleManageSubscriptionControllers"
                    onClick={() => {
                      this.handleManageSubscriptionControllers()
                    }}

                  >
                    <img src={imgBackIcon} alt="back-icon" style={{transform: this.state.languagesLogin ==='rtl'? 'scaleX(-1)':''}} />
                    <h2>{this.getStringLogin('changePlan')}</h2>
                  </div>
                  <div style={{ width: '100%' }} >

                    <div style={{
                      textAlign: 'center', background: ' #3c3c51', border: this.monthlyBackground(),
                      padding: '10px 5px', margin: "10px 10px 10px 10px", borderRadius: '10px 10px 10px 10px', cursor: 'pointer', position: 'relative'
                    }}

                    >
                      {(currentPlan && currentPlan.attributes.name === "Monthly") &&
                        <div style={{ position: "absolute", display: "inline", right: this.state.languagesLogin ==='rtl'?'':'8px' , left: this.state.languagesLogin ==='rtl'? '8px':''  , color: "#40e0d0" }}>{this.getStringLogin('currentPlan')}</div>
                      }
                      <FormControlLabel
                        checked={this.state.selectedPlan === 'monthly'}
                        data-test-id="testMonthlyId"
                        onChange={() => this.handleButtonMonthlyClick(this.getPlanDetails("Monthly")?.id)}
                        value="female"
                        style={{ display: "flex", justifyContent: "start", paddingLeft: "10px", textAlign: "start" }}
                        control={<Radio style={{ color: "#40e0d0" }} />}
                        label={
                          <div className="monthly">
                            {this.getPlanDetails("Monthly")?.attributes?.name} <br />
                            <b>
                              {this.getPlanDetails("Monthly")?.attributes?.amount}{" "}
                              {this.getPlanDetails("Monthly")?.attributes?.currency}
                            </b>
                          </div>
                        }
                      />
                    </div>
                    <div style={{
                      textAlign: 'center', background: ' #3c3c51', border: this.yearlyBackground(),
                      padding: '10px 5px', margin: "10px 10px 10px 10px", borderRadius: '10px 10px 10px 10px', cursor: 'pointer', position: 'relative'
                    }}
                      data-test-id="testYearlyId"

                    >
                      {(currentPlan && currentPlan.attributes.name === "Yearly") &&
                        <div style={{ position: "absolute", display: "inline", right: "8px", color: "#40e0d0" }}>Current plan</div>
                      }
                      <FormControlLabel
                        checked={this.state.selectedPlan === 'yearly'}
                        onChange={() => this.handleButtonYearlyClick(this.getPlanDetails("Yearly")?.id)}
                        data-test-id="yearlytestid"
                        value="female"
                        control={<Radio style={{ color: "#40e0d0" }} />}
                        style={{ display: "flex", justifyContent: "start", paddingLeft: "10px", textAlign: "start" }}
                        label={
                          <div className="yearly">
                            {this.getPlanDetails("Yearly")?.attributes?.name} <br />
                            <b>
                              {this.getPlanDetails("Yearly")?.attributes?.amount}{" "}
                              {this.getPlanDetails("Yearly")?.attributes?.currency}
                            </b>
                          </div>
                        }
                      />
                    </div>
                  </div>
                  <p style={{ fontSize: "10px", color: "#B2BEB5" }}>{this.getStringLogin('afterCurrentPlanEnds')}</p>
                  <div style={{ maxWidth: 220, width: '100%', textAlign: 'center', background: this.state.isButtonClicked ? "#C9F9F6" : '#728781', color: 'black', padding: '5px 10px', margin: 'auto', borderRadius: 10, marginTop: 20, cursor: "pointer" }}
                    data-test-id="handleNavigateChangePlan"
                    onClick={() => { this.handleNavigateChangePlan() }}>
                    {this.getStringLogin('continue')}
                  </div>
                  <p style={{ fontSize: "10px", marginTop: "60px" }}>{this.getStringLogin('proceedingToPurchase')} <a href="/TermsConditions" onClick={this.handleTermsAndConditionsClick} style={{ textDecoration: "underline white", cursor: "pointer", color: "white" }}>
                    {this.getStringLogin('termsAndConditions')}
                  </a> </p>
                </div>
              </Grid>
            </Grid>

          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  root: {
    width: "100%",
  },
  connectBlock: {
    minWidth: "920px",
  },
  bankIntegrationBox1: {
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-between",
    backdropFilter: "blur(13.5914px)",
    width: "100%",
    height: "540px",
    borderRadius: "32px",
    overflow: 'hidden',
    position: "relative",
  },
  textContent1: {
    position: 'absolute',
    textAlign: "center",
    width: 320,
    top: 100,
    zIndex: 11,
  },
  mainBankWrapper2: {
    fontFamily: "Poppins, sans-serif",
    color: "#fff",
    margin: 0,
    lineHeight: 1.6,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    height: "100vh",
    background: "#3C3C51",

  },



  textContentH41: {
    fontWeight: 400,
    display: "inline-block",
    margin: 0,
    fontSize: "14px",
  },
  textContentP1: {
    margin: "15px 0",
  },
  imgContentStyle1: {
    backgroundPosition: "center center",
    backgroundSize: "cover",
    width: "40%",
    position: 'relative',
    height: "100%",
    borderRadius: "32px 0 0 32px",
  },
  bgImage1: {
    objectFit: 'cover',
    position: 'absolute',
    zIndex: -1,
    height: '100%',
  },
};
// Customizable Area End
