import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import toast from "react-hot-toast";
import { getStorageData } from 'framework/src/Utilities';
import * as Yup from "yup";

interface IGoalDetail{
  goal_name: string,
  amount: number | null,
  currency: {
    value:string,
    label:string
  }|null,
  start_date: Date | null,
  target_date: Date | null,
  category: {
    value:string,
    label:string
  }|null,
  selectedBanks: number[];
}

interface IMonthlyGoal{
  amount: number | null,
  currency: {
    value:string,
    label:string
  }|null,
  start_date: Date | null,
  selectedBanks: number[];
}

interface IBankAccount {
  id: number;
  bank_account_id: number;
  account_number: string;
  image: string;
  type: string;
}

interface IBankOption {
  name: string;
  bank_id: number;
  accounts: IBankAccount[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  deletePopupId: any;
  categoriesList: any;
  dropdownVisiblity: any;
  selectedCurrency: any;
  currencyList: any;
  selectCurrency: any;
  selectDropdownCurrency: { value: string, label: string },
  otherGoalData: IGoalDetail,
  monthlyGoalData: IMonthlyGoal,
  authToken: any;
  startDate: any;
  selectDropdownVisibility: boolean;
  selectDropdownVisibilityForOtherGoalCategory: boolean;
  currencyValue: any;
  openSuccessModel: boolean;
  dashboardInitialStage: boolean;
  transactionMonth: any;
  selectedTransactionCurrentMonth: any;
  isMonthlyGoalAvailable: boolean;
  isOtherGoalAvailable: boolean;
  logoImg1AddCash: string
  dataValue: Object
  dataValueNew: Object
  otherGoals: any;
  monthlyGoal: any;
  goalRemainingAndDone: any;
  openModel: boolean,
  currencyListDropDownForm: { value: string, label: string }[];
  categoryListDropDownForm: { value: string, label: string }[];
  deleteSpendingGoalId: number;
  bankOptions : IBankOption[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GoalmanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCurrencyListForGoalCallId: any;
  setNewCurrencyCallId: any;
  setMonthlyGoalCallId: any;
  setOtherGoalCallId: any;
  categoriesCallId: any;
  transactionMonthDataCallId: any;
  getMonthlyGoalDataCallId: any;
  getOtherGoalsDataCallId: any;
  deleteOtherGoalCallId: any;
  getBankOptionApiCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      openModel: false,
      txtInputValue: "",
      categoriesList: '',
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      deletePopupId: 0,
      isMonthlyGoalAvailable: false,
      isOtherGoalAvailable: false,
      transactionMonth: '',
      selectedTransactionCurrentMonth: 0,
      dropdownVisiblity: 'state-dropdown',
      selectedCurrency: '$USD',
      currencyList: '',
      selectDropdownVisibility: false,
      selectDropdownVisibilityForOtherGoalCategory: false,
      selectCurrency: [],
      selectDropdownCurrency: { value: '', label: '' },
      otherGoalData: {
        goal_name: "",
        amount: null,
        currency: null,
        start_date: null,
        target_date:null,
        category: null,
        selectedBanks: [],
      },
      monthlyGoalData: {
        amount: null,
        currency: null,
        start_date: null,
        selectedBanks: [],
      },
      authToken: localStorage.getItem('authToken'),
      startDate: "",
      currencyValue: "",
      logoImg1AddCash: "",
      dataValue: {
        display: "flex", flexDirection: 'row-reverse'
      },
      dataValueNew: {
        display: "flex", flexDirection: 'row'
      },
      openSuccessModel: false,
      dashboardInitialStage: true,
      otherGoals: '',
      monthlyGoal: '',
      goalRemainingAndDone: 0,
      currencyListDropDownForm:[],
      categoryListDropDownForm: [],
      deleteSpendingGoalId: 0,
      bankOptions: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson2 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.categoriesResponse(apiRequestCallId, responseJson2)
    this.manageCurrencyListResponse(responseJson2, apiRequestCallId)
    this.manageSetMonthlyGoalResponse(responseJson2, apiRequestCallId)
    this.manageTransactionDetailsResponse(apiRequestCallId, responseJson2)
    this.manageGetOtherGoals(apiRequestCallId, responseJson2)
    this.manageBankOptionApiResponse(apiRequestCallId, responseJson2)
    this.manageGetMonthlyGoals(apiRequestCallId, responseJson2)
    this.manageCurrencyResponse(apiRequestCallId)

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  dialogPaperStyle = {
    width: '265px',
    backgroundColor: '#3c3c51',
    borderRadius: '12px',
    border: '1px solid #57575d',
    padding: '19px 0px',
  };
  dialogPaperStyleNew = {
    backgroundColor: '#3c3c51',
    borderRadius: '12px',
    border: '1px solid #57575d',
    padding: '19px 0px',
  };
  dialogModel = () => {
    this.setState({ openModel: !this.state.openModel })
  }

  onCancel = (event:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    this.setState({ openModel: !this.state.openModel })
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getCurrencyListForGoal();
    this.categories();
    this.getTransactionMonthForData();
    this.getOthersGoalData();
    this.getBankOptions()
    const language = await getStorageData('language');
    const logoImg1AddCash = language === 'Arabic' ? 'rtl' : 'ltr';
    this.setState({ logoImg1AddCash: logoImg1AddCash });
  }

  manageGetMonthlyGoals = (apiRequestCallId: any, responseJson2: any) => {
    if (responseJson2 && apiRequestCallId == this.getMonthlyGoalDataCallId) {
      if (responseJson2.on_month) {
        let doneInPercentage;
        if(responseJson2.operand === "-"){
          doneInPercentage = 100 - ((100 * responseJson2.goal_amount) / responseJson2.spending_balance);
        }else{
          doneInPercentage = (100 * responseJson2.spending_balance) / responseJson2.goal_amount
        }
        this.setState({ goalRemainingAndDone: doneInPercentage, monthlyGoal: responseJson2, isMonthlyGoalAvailable: true, dashboardInitialStage: false })

      }
      else {
        this.setState({ isMonthlyGoalAvailable: false })
        this.checkDashboardInitialStage();
      }
    }
  }
  getStringGoalmanagement = (key: string) => {
    let languageLogin = localStorage.getItem("language") || "English"
    return configJSON.languageListForecasting[languageLogin][key]
  }
  handleDropdown = () => {
    if (this.state.dropdownVisiblity == 'state-dropdown') {
      this.setState({ dropdownVisiblity: 'state-dropdown active' })
    } else {
      this.setState({ dropdownVisiblity: 'state-dropdown' })

    }
  }
  categoriesResponse = async (apiRequestCallId: string, responseJson: {
    data:{
      attributes: {
        id: string,
        name:string
      }
    }[]
  }) => {
    if (responseJson && apiRequestCallId == this.categoriesCallId) {
      this.setState({ categoriesList: responseJson.data ,categoryListDropDownForm: responseJson.data.map((category)=>{
        return{
          value: category.attributes.id,
          label: category.attributes.name
        }
      })})
    }
  }
  getCurrencyListForGoal = async () => {
    const requestMessagegetCurrency = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCurrencyListForGoalCallId = requestMessagegetCurrency.messageId;
    const headerData = {
      token: this.state.authToken
    };
    requestMessagegetCurrency.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessagegetCurrency.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/currency_list"
    );

    requestMessagegetCurrency.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessagegetCurrency.id, requestMessagegetCurrency);

  }

  manageCurrencyListResponse = async (responseJson2: any, apiRequestCallId: any) => {
    if (responseJson2 &&
      apiRequestCallId === this.getCurrencyListForGoalCallId
    ) {

      for (let x of responseJson2.list) {

        if (x.id == responseJson2.selection) {
          this.setState({ selectedCurrency: x.symbol + x.currency_type })
        }
      }
      const currency = responseJson2.list?.map((value: any) => (
        { value: value.currency_type, label: value.currency_type }
      ));
      this.setState({ currencyList: responseJson2, selectCurrency: currency , currencyListDropDownForm: responseJson2.list.map((value:{
        currency_type: string,
      }) => (
        { value: value.currency_type, label: `${value.currency_type}` }
      )),})
    }
  }
  dialogCashPaperStyle = {
    width: 'max-content',
    backgroundColor: '#395D6B',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
};
dialogCashModel = () => {
  this.setState({ openSuccessModel: !this.state.openSuccessModel })
  this.props.navigation.navigate('Goalmanagement')
}
  selectCurrencyGoal = async (value:any) => {
    this.setState({selectedCurrency:value.symbol+value.currency_type})
    const formDataGoal = new FormData();
    formDataGoal.append("currency_id", value.id);
    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setNewCurrencyCallId = requestMessage1.messageId;
    const header = {
      token: this.state.authToken
    };
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/add_currency_to_account"
    );
    requestMessage1.addData
      (getName(MessageEnum.RestAPIRequestBodyMessage),
        formDataGoal
      );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage1.id, requestMessage1);
  }

  setOtherGoals = async (values:IGoalDetail) => {
    let body = {
      data: {
        attributes: {
          start_date: values.start_date,
          end_date: values.target_date,
          amount: values.amount,
          currency: values.currency?.value,
          name: values.goal_name,
          sub_category_id: values.category?.value,
          bank_account_ids: values.selectedBanks
        }
      }
    }    
    const setOtherGoal = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setOtherGoalCallId = setOtherGoal.messageId;
    const header = {
      token: this.state.authToken,
      "Content-Type": 'application/json',
    };
    setOtherGoal.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    setOtherGoal.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_budgetingforecasting/other_goals"
    );
    setOtherGoal.addData
      (getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
      );
    setOtherGoal.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(setOtherGoal.id, setOtherGoal);
  }

  setMonthlyGoal = async (values: IMonthlyGoal) => {
    let body = {
      data: {
        attributes: {
          amount: values.amount,
          start_date: values.start_date,
          currency: values.currency?.value,
          bank_account_ids:values.selectedBanks
        }
      }
    }
    const setMonthlyGoal = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setMonthlyGoalCallId = setMonthlyGoal.messageId;
    const header = {
      token: this.state.authToken,
      "Content-Type": 'application/json',
    };
    setMonthlyGoal.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    setMonthlyGoal.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_budgetingforecasting/monthly_goals"
    );
    setMonthlyGoal.addData
      (getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
      );
    setMonthlyGoal.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(setMonthlyGoal.id, setMonthlyGoal);
  }
  redirectToMonthlyGoal = async () => {
    this.props.navigation.navigate("AddMonthlyGoal");
  }

  redirectToOtherGoals = async () => {
    this.props.navigation.navigate("AddOtherGoal");
  }
  manageSetMonthlyGoalResponse = async (responseJson2: any, apiRequestCallId: any) => {
    if (responseJson2 &&
      apiRequestCallId === this.setMonthlyGoalCallId
    ) {
      this.setState({ openSuccessModel: !this.state.openSuccessModel })
    }

    if (responseJson2 &&
      apiRequestCallId === this.setOtherGoalCallId
    ) {
      this.setState({ openSuccessModel: !this.state.openSuccessModel })
    }
  }
  categories = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.categoriesCallId = requestMessage.messageId;
    const headers = {
      token: this.state.authToken
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories/sub_categories/?name=spendingcategories"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTransactionMonthForData = () => {
    const getDataofTransactionMonth = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: this.state.authToken
    };
    this.transactionMonthDataCallId = getDataofTransactionMonth.messageId;

    getDataofTransactionMonth.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_budgetingforecasting/monthly_goals"
    );

    getDataofTransactionMonth.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getDataofTransactionMonth.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getDataofTransactionMonth.id, getDataofTransactionMonth);
  }
  getBankOptions = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      token: this.state.authToken
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getBankOptionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bankOptionApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  manageTransactionDetailsResponse = async (apiRequestCallId: any, responseJson2: any) => {
    if (apiRequestCallId == this.transactionMonthDataCallId) {
      if (responseJson2.months) {
        this.setState({ transactionMonth: responseJson2.months })
        this.getMonthlyGoalsData()
      }
    }
  }
  deleteOtherGoal = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string | number) => {
    event.stopPropagation();
    const requestMessageForDeleteGoal = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: this.state.authToken
    };
    this.deleteOtherGoalCallId = requestMessageForDeleteGoal.messageId;
    requestMessageForDeleteGoal.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_budgetingforecasting/other_goals/${id}`
    );

    requestMessageForDeleteGoal.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessageForDeleteGoal.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessageForDeleteGoal.id, requestMessageForDeleteGoal);
  }

  manageBankOptionApiResponse = async (apiRequestCallId: any, responseJson2: any) => {
    if (apiRequestCallId == this.getBankOptionApiCallId) {
      this.setState({bankOptions:responseJson2.Banks}) 
    }
  }

  manageGetOtherGoals = async (apiRequestCallId: any, responseJson2: any) => {
    if (apiRequestCallId == this.getOtherGoalsDataCallId) {
      this.setState({ otherGoals: Object.entries(responseJson2) })
    }
    if (this.state.otherGoals.length && this.state.otherGoals.length > 0) {
      this.setState({ isOtherGoalAvailable: true, dashboardInitialStage: false })
    } else {
      this.setState({ isOtherGoalAvailable: false })
      this.checkDashboardInitialStage();
    }

    if (apiRequestCallId == this.deleteOtherGoalCallId) {
      this.setState({openModel: !this.state.openModel})
      toast.success(responseJson2.message)
      this.getOthersGoalData();
    }
  }

  checkDashboardInitialStage = async () => {
    if (this.state.isMonthlyGoalAvailable || this.state.isOtherGoalAvailable) {
      this.setState({ dashboardInitialStage: false })
    } else {
      this.setState({ dashboardInitialStage: true })
    }
  }

  updateCurrentMonth = async (directionValue: any) => {
    if (directionValue == 'minus') {
      if (this.state.selectedTransactionCurrentMonth < this.state.transactionMonth.length - 1) {
        this.setState({ selectedTransactionCurrentMonth: this.state.selectedTransactionCurrentMonth + 1 })
      } else {
        this.setState({ selectedTransactionCurrentMonth: 0 })
      }
    }
    else {
      if (this.state.selectedTransactionCurrentMonth > 0) {
        this.setState({ selectedTransactionCurrentMonth: this.state.selectedTransactionCurrentMonth - 1 })
      } else {
        this.setState({ selectedTransactionCurrentMonth: this.state.transactionMonth.length - 1 })
      }
    }
    setTimeout(() => {
      this.getMonthlyGoalsData()
    }, 100)
  }

  getMonthlyGoalsData = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: this.state.authToken,
      'Content-Type': 'application/json'
    };
    this.getMonthlyGoalDataCallId = requestMessage.messageId;
    if (this.state.transactionMonth) {
      let newData = this.state.transactionMonth[this.state.selectedTransactionCurrentMonth]
      let [month, year] = newData.split(' ');
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_budgetingforecasting/monthly_goals/find_spendings?month=${month}&year=${year}`
      )
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_budgetingforecasting/monthly_goals/find_spendings`
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getOthersGoalData = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: this.state.authToken
    };
    this.getOtherGoalsDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_budgetingforecasting/other_goals/find_other_spendings`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateDeletePopupId = async (event:React.MouseEvent<HTMLDivElement, MouseEvent>, id: number) => {
    event.stopPropagation();
    this.setState({deleteSpendingGoalId: id})
    if (this.state.deletePopupId == id) {
      this.setState({ deletePopupId: 0 })
    } else {
      this.setState({ deletePopupId: id })
    }
  }

  selectBankError = Yup.array()
  .min(1, this.getStringGoalmanagement('selectAtLeastOneBank')) // At least one bank must be selected
  .of(Yup.number().typeError(this.getStringGoalmanagement('invalidBankID'))) // Ensures each selected bank ID is a number
  .required(this.getStringGoalmanagement('bankSelectionRequired'))
  
 validationSchemaOtherGoal = Yup.object().shape({
    goal_name: Yup.string()
      .required(this.getStringGoalmanagement('goalNameRequired')), 
    amount: Yup.number().nullable().typeError(this.getStringGoalmanagement('amountTypeError')) 
      .test('is-decimal', this.getStringGoalmanagement('amountDecimal'), (value) => {
        if (!value) return true;
        return /^\d+(\.\d{1,2})?$/.test(value);
      })
      .test('is-large', this.getStringGoalmanagement('amountValidation'), (value) => {
        if (!value) return true;
        return value <= 9999999999
      })
      .required(this.getStringGoalmanagement('amountRequired')), 
    currency: Yup.object().nullable().shape({
      value: Yup.string(),
    }).required(this.getStringGoalmanagement('currencyRequired')),
    category: Yup.object().nullable().shape({
      value: Yup.string(),
    }).required(this.getStringGoalmanagement('categoryRequired')),
    selectedBanks: this.selectBankError,
    start_date: Yup.date().nullable().typeError(this.getStringGoalmanagement('startDateTypeError')) 
      .required(this.getStringGoalmanagement('startDateRequired')), 
    target_date: Yup.date().nullable().typeError(this.getStringGoalmanagement('targetDateTypeError')) 
      .required(this.getStringGoalmanagement('targetDateRequired'))
      .test('is-after-startDate', this.getStringGoalmanagement('targetDateAfterStart'), function(value) {
        const { start_date } = this.parent;
        return  value >= start_date || !start_date || !value;
      }),
  });

  validationSchemaGoal = Yup.object().shape({
    amount: Yup.number()
      .nullable()
      .typeError(this.getStringGoalmanagement('amountTypeError')) 
      .test('is-decimal', this.getStringGoalmanagement('amountDecimal'), (value) => {
        if (!value) return true;
        return /^\d+(\.\d{1,2})?$/.test(value);
      })
      .test('is-verylarge', this.getStringGoalmanagement('amountValidation'), (inpValue) => {
        if (!inpValue) return true;
        return inpValue <= 9999999999;
      })
      .test('is-not-zero', 'Amount must be greater than zero', (value) => {
        return value > 0;
      })
      .required(this.getStringGoalmanagement('amountRequired')), 
    currency: Yup.object().nullable().shape({
      value: Yup.string(),
    }).required(this.getStringGoalmanagement('currencyRequired')),
    selectedBanks: this.selectBankError,
    start_date: Yup.date().nullable().typeError(this.getStringGoalmanagement('startDateTypeError')) 
      .required(this.getStringGoalmanagement('startDateRequired')),
  });

  truncateName = (text:string ) =>{
    return text.length> 20 ?  text.substring(0, 19) + "..." : text
  } ;


  manageCurrencyResponse = async (apiId:string) => {
    if (apiId === this.setNewCurrencyCallId) {
      this.categories();
      this.getTransactionMonthForData();
      this.getOthersGoalData();
    }
  }
  handleGlobaldropdown=()=>{
    if(this.state.dropdownVisiblity== 'state-dropdown active'){
      this.setState({ dropdownVisiblity: 'state-dropdown' }) }
  }

  gotoTransactions = (value: any) => {
    if (!this.state.deletePopupId) {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Transactions");
      message.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { ...value, fromGoal: true })
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), `${value.start_date}&&${value.end_date}&&${value.sub_category.sub_category}`);
      this.send(message)
    }
  }
  // Customizable Area End
}
